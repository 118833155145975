import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { updateReportBody } from "../reportUtils";

const FacultySize = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION6_FACULTY",
      "FacultySize",
      "description",
      content
    );
  };

  return (
    <>
      <h3>FACULTY: Faculty Size</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of the adequacy of the size of the
        faculty and the extent and quality of faculty involvement in:
      </p>
      <ul>
        <li>Interactions with students,</li>
        <li>Student advising and counseling,</li>
        <li>University service activities,</li>
        <li>Professional development,</li>
        <li>
          Interactions with industrial and professional practitioners including
          employers of students.
        </li>
      </ul>

      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
    </>
  );
};

export default FacultySize;
