import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import EditableAntDDataGrid from "components/DataGrid/EditableAntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { updateReportBody } from "../reportUtils";

const FacultyWorkload = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION6_FACULTY",
      "FacultyWorkload",
      "description",
      content
    );
  };

  const columns = [
    {
      title: "Faculty Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "FT or PT",
      dataIndex: "employmentStatus",
      key: "employmentStatus",
      width: 150,
    },
    {
      title: "Classes Taught (Course No./Credit Hrs.) Term and Year",
      dataIndex: "classes",
      key: "classes",
      width: 200,
    },
    {
      title: "Program Activity Distribution",
      children: [
        {
          title: "Teaching",
          dataIndex: "teachingActivitiesPercentage",
          key: "teachingActivitiesPercentage",
          width: 100,
          editable: true,

        },
        {
          title: "Research or Scholarship",
          dataIndex: "researchOrScholarshipActivitiesPercentage",
          key: "researchOrScholarshipActivitiesPercentage",
          width: 100,
          editable: true,

        },
        {
          title: "Other",
          dataIndex: "otherActivitiesPercentage",
          key: "otherActivitiesPercentage",
          width: 100,
          editable: true,

        },
      ],
    },
    {
      title: "% of Time Devoted to the Program",
      dataIndex: "timePercentageDevotedToTheProgram",
      key: "timePercentageDevotedToTheProgram",
      width: 150,
      editable: true,

    },
  ];

  return (
    <>
      <h3>FACULTY: Faculty Workload</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of Faculty Workload in terms of workload
        expectations or requirements:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
      <br />

      <p className={styles.reportParagraphTitle}>
        The following is a Faculty Workload Summary in the form of a table
        including Classes Taught and Program Activity Distribution:
      </p>
      <div className={styles.reportCart}>
        <EditableAntDDataGrid
          defaultColumns={columns}
          defaultData={data?.FacultyWorkloadTable}
          scroll
          bordered
          size="middle"
        />
      </div>
    </>
  );
};

export default FacultyWorkload;
