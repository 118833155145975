import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import { updateReportBody } from "../reportUtils";

const EvaluatingStudentPerformance = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "BACKGROUNDINFORMATION",
      "EvaluatingStudentPerformance",
      "description",
      content
    );
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
  ];

  return (
    <>
      <h3>STUDENTS: Evaluating Student Performance</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a summary of the process by which student performance
        is evaluated and student progress is monitored including information on
        how the program ensures and documents that students are meeting
        prerequisites and how it handles the situation when a prerequisite has
        not been met:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
      <br />

      <p className={styles.reportParagraphTitle}>
        The following is the list of the courses prerequisites of the program:
      </p>

      <AntDDataGrid columns={columns} data={data?.prerequisites} />

      {/* <ul>
        {data?.prerequisites?.map((prerequisite, index) => (
          <li key={index}>{prerequisite}</li>
        ))}
      </ul> */}
      <br />

      {/* <GridContainer alignItems="center">
        <GridItem xs={11} sm={11} md={11}>
          <p>The warning systems:</p>
        </GridItem>
        <GridItem xs={1} sm={1} md={1}>
          <Button
            justIcon
            round
            color="transparent"
            onClick={() => {
              console.log("clicked");
            }}
          >
            <EditIcon />
          </Button>
        </GridItem>
      </GridContainer>
      <ul>
        {data?.warningSystems?.map((warningSystem, index) => (
          <li key={index}>{warningSystem}</li>
        ))}
      </ul> */}
    </>
  );
};

export default EvaluatingStudentPerformance;
