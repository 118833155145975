import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb, notification } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import {
  fetchDifficultyTypes,
  deleteDifficultyType,
} from "../../../../reduxToolkit/features/difficultyType/DifficultyTypeSlice";
import CustomDropdown from "../../../../components/CustomDropdown/CustomDropdown";
import ErrorModal from "../../../Components/ErrorModal";
import SuperGeneralDeleteModal from "views/Components/SuperGeneralDeleteModal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const useBackdropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "rgb(0,0,0,0.7)",
    display: "flex",
    flexDirection: "column",
  },
}));

export default function DifficultyTypesList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();

  const backdropClasses = useBackdropStyles();

  const [tobeDeleted, setTobeDeleted] = useState(null);
  const [handledError, setHandledError] = useState("");
  const [openErrorModal, setOpenErrorModal] = React.useState(false);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const handleOpenDeleteModal = (id) => {
    setDeleteModalIsOpen(true);
    setTobeDeleted(id);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const handleDeleteDifficultyType = async (id) => {
    try {
      await dispatch(deleteDifficultyType(id)).unwrap();
      handleCloseDeleteModal();
      openNotificationWithIcon("success", `Deleted`);
      handleFetchDifficultyTypes();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const handleFetchDifficultyTypes = async () => {
    try {
      await dispatch(fetchDifficultyTypes()).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    handleFetchDifficultyTypes();
  }, []);

  const { difficultyTypes, error, loading } = useSelector(
    (state) => state.difficultyTypes
  );
  let content;

  if (!error && loading == "idle") {
    if (difficultyTypes.length == 0) {
      content = (
        <div>
          {" "}
          There are no difficulty types in the database, You can add new
          difficulty types using the Add button above.{" "}
        </div>
      );
    } else {
      content = difficultyTypes?.map((difficultyType) => (
        <GridItem key={difficultyType.id} xs={12} sm={6} md={4} lg={3}>
          <Card style={{ height: 200 }}>
            <CardHeader>
              <GridContainer>
                <GridItem xs={10} sm={10} md={10}>
                  <h4>Difficulty type</h4>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomDropdown
                    isButtonIcon
                    dropdownList={[
                      {
                        text: "Edit",
                        onClickHandler() {
                          history.push(
                            `/citrine/configuration/others/difficultyTypes/${difficultyType.id}/update`
                          );
                        },
                      },
                      {
                        text: "Delete",
                        onClickHandler() {
                          handleOpenDeleteModal(difficultyType.id);
                        },
                      },
                    ]}
                  />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <p>{difficultyType.type}</p>
            </CardBody>
          </Card>
        </GridItem>
      ));
    }
  }

  return (
    <div>
      {/* <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem> */}
      <Breadcrumb separator=">">
        <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/configuration/others`}>
            <span> {t("Others")} </span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/configuration/others/difficultyTypes`}>
            {t("difficulty type")}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      {/* </GridItem>
      </GridContainer> */}
      <br />

      <Link to="/citrine/configuration/others/difficultyTypes/create">
        <Button color="info">{t("Add")}</Button>
      </Link>
      <br />
      <br />

      <Backdrop
        className={backdropClasses.backdrop}
        open={loading === "pending"}
      >
        <CircularProgress color="inherit" />
        <br />
        <br />

        <p>Loading difficulty types...</p>
      </Backdrop>

      <GridContainer>{content}</GridContainer>
      {error && (
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      )}
      {tobeDeleted && (
        <SuperGeneralDeleteModal
          delete_Element={handleDeleteDifficultyType}
          Element_ID={tobeDeleted}
          open={deleteModalIsOpen}
          handleClose={handleCloseDeleteModal}
        />
      )}
    </div>
  );
}
