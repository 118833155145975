import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";
import { updateReportBody } from "../reportUtils";

const StudentOutcomes = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION3_STUDENTOUTCOMES",
      "StudentOutcomes",
      "description",
      content
    );
  };

  return (
    <>
      <h3> STUDENT OUTCOMES: Student Outcomes</h3>

      <p className={styles.reportParagraphTitle}>
        The PLOs for the {data.programName} program are as follows:
      </p>
      {/* <GridItem xs={1} sm={1} md={1}>
          <Button
            justIcon
            round
            color="transparent"
            onClick={() => {
              console.log("clicked");
            }}
          >
            <EditIcon />
          </Button>
        </GridItem> */}
      <ul>
        {data?.PLOs?.map((plo, index) => (
          <li key={index}>{plo}</li>
        ))}
      </ul>

      <br />

      <p className={styles.reportParagraphTitle}>
        The following is information about the Program Student Outcomes and the
        places where they may be found by the general public:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
    </>
  );
};

export default StudentOutcomes;
