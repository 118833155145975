import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { updateReportBody } from "../reportUtils";

const Leadership = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION8_INSTITUTIONALSUPPORT",
      "Leadership",
      "description",
      content
    );
  };

  return (
    <>
      <h3>INSTITUTIONAL SUPPORT: Leadership</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of the leadership of the program and
        discuss its adequacy to ensure the quality and continuity of the program
        and how the leadership is involved in decisions that affect the program:
      </p>

      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
    </>
  );
};

export default Leadership;
