import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/icons
import { fetchAssessmentModes } from "../../../../../reduxToolkit/features/assessmentMode/AssessmentModeSpecSlice";
import {
  addNewCourseContent, CourseContentUpdate, fetchCourseContentById, fetchCourseContents
} from "../../../../../reduxToolkit/features/CourseContent/CourseContentSlice";

import { Link } from "react-router-dom";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { Select } from "antd";
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useHistory } from "react-router-dom";
import { Breadcrumb, Input } from "antd";
import {
  fetchCourseById
} from "reduxToolkit/Course/coursesSpecSlice";

import { useTranslation } from "react-i18next";

import { Form, InputNumber, notification } from "antd";
import ErrorModal from "../../../../Components/ErrorModal";

const { TextArea } = Input;
const useStyles = makeStyles(styles);
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};

export default function CreateCourseContent({ match }) {

  const { t } = useTranslation();
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);
  const [form] = Form.useForm();
  const assessmentModesStatus = useSelector((state) => state.assessmentModeSpec.assessmentModesStatus);
  const assessmentModes = useSelector((state) => state.assessmentModeSpec.assessmentModes);
  const res = useSelector((state) => state.CourseContent.CourseContent);
  const courseStatus = useSelector((state) => state.course.courseStatus);
  const course = useSelector((state) => state.CourseSpec.courseSpecification);

  const titleClasses = useTitleStyles();
  const { id } = match.params;
  const { idCC } = match.params;
  const isAddMode = isNaN(idCC);

  const [mode_state_id, setMode_state_id] = useState("");
  const [name, setName] = useState("");
  const [contact_hours, setContact_hours] = useState("");
  const [topic_hours, setTopic_hours] = useState("");
  const [courseSpec_id, setCourseSpec_id] = useState(id);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const onMode_state_idChanged = (e) => setMode_state_id(e.target.value);
  const onName = (e) => setName(e.target.value);
  const onContact_hours = (e) => setContact_hours(e.target.value);
  const onTopic_hours = (e) => setTopic_hours(e.target.value);
  const onCourseSpec_idChanged = (e) => setCourseSpec_id(e.target.value);

  const dispatch = useDispatch();
  const history = useHistory();

  const { Option } = Select;
  const canSave = [mode_state_id, name, contact_hours, topic_hours, courseSpec_id].every(Boolean) && addRequestStatus === "idle";

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchCourseContentById(idCC))
          .then(unwrapResult)
          .then((CC) => {
            form.setFieldsValue({
              mode_state_id: CC.mode_state_id,
              contact_hours: CC.contact_hours,
              topic_hours: CC.topic_hours,
              name: CC.name,
              courseSpec_id: CC.courseSpec_id,
            });
          });
    }
  }, [idCC]);

  useEffect(() => {
    if (assessmentModesStatus === "idle") {
      dispatch(fetchAssessmentModes());
    }
  }, []);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewCourseContent({ ...values, courseSpec_id })
    );
    if (addNewCourseContent.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchCourseContents(course && course.id && course.id));
      history.push(`/citrine/specification/course/${id}/CourseContentViews`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      CourseContentUpdate({
        id: idCC,
        modified: {
          ...values,
        },
      })
    );
    if (CourseContentUpdate.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchCourseContents(course && course.id && course.id));
      history.push(`/citrine/specification/course/${id}/CourseContentViews`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const onChangeMode = (value) => {setMode_state_id(value);}

  const onChangeAssessmentMethod_id = (value) => {}

  const onBlur = () => {}

  const onFocus = () => {}

  const onSearch = (val) => {}

  const handleCourse_id = (id) => {
    setCourseSpec_id(id);
    dispatch(fetchCourseById(id));
  };

  const handleChangeClos = (value) => {}

  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/CourseContentViews`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Course Content List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Create")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="mode_state_id"
                        label="Assessment Mode"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select mode" allowClear>
                          {assessmentModes &&
                            assessmentModes.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="contact_hours"
                        label="Contact hours"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} min="0" />
                      </Form.Item>
                      <Form.Item
                        name="topic_hours"
                        label="Credit hours"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} min="0" />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/CourseContentViews`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
      {!isAddMode && res && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <GridItem>
                  <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                      <Link to={`/citrine/programs`}>{t("Programs List")}</Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                      <Link to={`/citrine/CourseSpecificationList/`}>
                        {t("Courses List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/citrine/courseSpecDetails/${id}`}>
                        {course && course.title && course.title}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link
                        to={`/citrine/specification/course/${course && course.id && course.id
                          }/CourseContentViews`}
                        style={{ color: "orange" }}
                      >
                        {" "}
                        {t("Course Content List")}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{t("Edit")}</Breadcrumb.Item>
                  </Breadcrumb>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon></CardHeader>
                  <CardBody>
                    <Form
                      {...layout}
                      form={form}
                      name="control-hooks"
                      onFinish={onFinishUpdate}
                    >
                      <Form.Item
                        name="mode_state_id"
                        label="Assessment Mode"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select placeholder="Select mode" allowClear>
                          {assessmentModes &&
                            assessmentModes.map((r) => (
                              <Option key={r.id} value={r.id}>
                                {r.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name="contact_hours"
                        label="Contact hours"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <InputNumber style={{ width: "100%" }} min="0" />
                      </Form.Item>
                      <Form.Item
                          name="topic_hours"
                          label="Credit hours"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                      >
                        <InputNumber style={{ width: "100%" }} min="0" />
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          style={{ marginRight: "20px" }}
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/citrine/specification/course/${course && course.id && course.id
                              }/CourseContentViews`
                            )
                          }
                          htmlType="submit"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ErrorModal
              open={openErrorModal}
              handleClose={handleCloseErrorModal}
              handledError={handledError}
            />
          </div>
        </div>
      )}
    </div>
  );
}
