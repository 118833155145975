import { Tabs } from "antd";
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { greyColor } from "assets/jss/material-dashboard-pro-react";
import { Link } from "react-router-dom";
import React from "react";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import StudentOutcomes from "./StudentOutcomes";
import RelationshipOfStudentOutcomesToProgramEducationalObjectives from "./RelationshipOfStudentOutcomesToProgramEducationalObjectives";
import ProcessForTheEstablishmentAndRevisionOfTheStudentOutcomes from "./ProcessForTheEstablishmentAndRevisionOfTheStudentOutcomes";
import Criterion1Analysis from "../criterion1/Analysis";
const onChange = (key) => {
  console.log(key);
};

const Criterion3 = ({ data, report, setReportBody }) => (
  <>
    {/* <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>SELF-STUDY REPORT</p> */}

    <Tabs
      defaultActiveKey="1"
      onChange={onChange}
      items={[
        // {
        //     label: `Process for the Establishment and Revision of the Student Outcomes`,
        //     key: '1',
        //     children: <ProcessForTheEstablishmentAndRevisionOfTheStudentOutcomes />
        //     ,
        // },
        {
          label: `Student Outcomes`,
          key: "1",
          children: (
            <StudentOutcomes
              data={data.StudentOutcomes}
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
        {
          label: `Relationship of Student Outcomes to Program Educational Objectives`,
          key: "2",
          children: (
            <RelationshipOfStudentOutcomesToProgramEducationalObjectives
              data={
                data.RelationshipOfStudentOutcomesToProgramEducationalObjectives
              }
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
        {
          label: `Analysis`,
          key: "3",
          children: (
            <Criterion1Analysis
              data={data.Analysis}
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
      ]}
    />
  </>
);
export default Criterion3;
