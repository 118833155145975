import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from "@material-ui/core/styles";
// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import {unwrapResult} from '@reduxjs/toolkit';
import {notification} from 'antd';
import React, {useState} from "react";
import {useTranslation} from 'react-i18next';
import {useDispatch} from "react-redux";
import {Link} from 'react-router-dom';
import Button from '../../../../components/CustomButtons/Button';
import ErrorModal from "../../../Components/ErrorModal";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";
import {deleteRequisite, fetchRequisites} from "../../../../reduxToolkit/features/Requisite/RequisiteSlice";

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
));

export default function DropDownRequisiteButton(props) {

    const {t} = useTranslation();

    const [open, setOpen] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [handledError, setHandledError] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDeleteModal = () => {
        setOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setOpen(false);
    };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const handleDeleteItem = async (id) => {
        dispatch(deleteRequisite(id))
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                // handle result here
                openNotificationWithIcon('success', `Deleted`)
                dispatch(fetchRequisites());
            })
            .catch((rejectedValueOrSerializedError) => {
                // handle error here
                setHandledError(rejectedValueOrSerializedError)
                handleOpenErrorModal()
            })
    }

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2
        });
    };

    return (
        <div>
            <Button
                // aria-describedby={id}
                aria-controls="customized-menu"
                variant="contained"
                color="primary"
                onClick={handleClick}
                justIcon
                round
                aria-haspopup="true"
            >
                <MoreVert/>
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem>
                    <Link to={`/citrine/configuration/course/requisites/${props.id}/edit`}>
                        {t('Edit')}
                    </Link>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleOpenDeleteModal();
                        handleClose();
                    }}>
                    <ListItemText primary="Delete"/>
                </MenuItem>
            </StyledMenu>
            <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} handledError={handledError}/>
            <SuperGeneralDeleteModal
                delete_Element={handleDeleteItem}
                Element_ID={props.id}
                open={open}
                handleClose={handleCloseDeleteModal}
            />
        </div>
    );
}


