import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { updateReportBody } from "../reportUtils";

const MaintenanceAndUpgradingOfFacilities = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION7_FACILITIES",
      "MaintenanceAndUpgradingOfFacilities",
      "description",
      content
    );
  };

  return (
    <>
      <h3>FACILITIES: Maintenance and Upgrading of Facilities</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of the policies and procedures for
        maintaining and upgrading the tools, equipment, computing resources, and
        laboratories used by students and faculty in the program:
      </p>

      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
    </>
  );
};

export default MaintenanceAndUpgradingOfFacilities;
