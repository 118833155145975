import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import BarChart from "./BarChart";
import { updateReportBody } from "../reportUtils";

const StudentOutcomes = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION4_CONTINUOUSIMPROVEMENT",
      "StudentOutcomes",
      "description",
      content
    );
  };

  // const columns = [
  //   {
  //     title: "Title",
  //     dataIndex: "title",
  //     key: "title",
  //   },
  // ];
  const courseMappingColumns = data?.courses?.map((course) => ({
    title: course,
    dataIndex: course,
    key: course,
    render: (content) => <>{content ? <CloseIcon /> : null}</>,
  }));

  const columns = [
    {
      title: "performanceIndicator",
      dataIndex: "performanceIndicator",
      key: "performanceIndicator",
      // render: (content) => <>{content ? <CloseIcon /> : null}</>,
    },
    {
      title: "EducationalStrategies",
      dataIndex: "EducationalStrategies",
      key: "EducationalStrategies",
      render: (EducationalStrategies) => (
        <ul>
          {EducationalStrategies?.map((EducationalStrategy) => {
            return <li key={EducationalStrategy}>{EducationalStrategy}</li>;
          })}
        </ul>
      ),
    },
    {
      title: "MethodsOfAssessment",
      dataIndex: "MethodsOfAssessment",
      key: "MethodsOfAssessment",
      render: (MethodsOfAssessment) => (
        <ul>
          {MethodsOfAssessment?.map((MethodOfAssessment) => {
            return <li key={MethodOfAssessment}>{MethodOfAssessment}</li>;
          })}
        </ul>
      ),
    },
    {
      title: "WhereSummativeDataAreCollected",
      dataIndex: "WhereSummativeDataAreCollected",
      key: "WhereSummativeDataAreCollected",
      render: (EducationalStrategies) => (
        <ul>
          {EducationalStrategies.map((EducationalStrategy) => {
            return <li key={EducationalStrategy}>{EducationalStrategy}</li>;
          })}
        </ul>
      ),
    },
    {
      title: "LengthOfAssessmentCycle",
      dataIndex: "LengthOfAssessmentCycle",
      key: "LengthOfAssessmentCycle",
    },
    {
      title: "YearSemesterOfDataCollection",
      dataIndex: "YearSemesterOfDataCollection",
      key: "YearSemesterOfDataCollection",
    },
    {
      title: "TargetForPerformance",
      dataIndex: "TargetForPerformance",
      key: "TargetForPerformance",
    },
  ];

  return (
    <>
      <h3>CONTINUOUS IMPROVEMENT: Student Outcomes</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a listing and description of the assessment processes
        used to gather the data upon which the evaluation of each student
        outcome is based and the frequency with which these assessment processes
        are carried out:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
      <br />

      <p className={styles.reportParagraphTitle}>
        The following is the list of the PLOs and their associated courses. This
        map is used to decide where the summative assessment data would be
        collected:
      </p>
      <AntDDataGrid
        columns={[
          {
            title: "Student Outcome",
            dataIndex: "PLO",
            key: "PLO",
          },
          ...courseMappingColumns,
        ]}
        data={data?.PLOs_courses_mapping}
      />
      <br />

      <p className={styles.reportParagraphTitle}>
        The following is summaries of the results of the evaluation process and
        an analysis illustrating the extent to which each of the student
        outcomes is being attained. Results for each student outcome are
        reported separately in the following tables. Each table represents the
        activity for the current ABET accreditation cycle. Each outcome table
        includes performance indicators, courses and/or co-curricular activities
        (educational strategies) that provide students an opportunity to
        demonstrate the indicator, where summative data are collected,
        timetable, method of assessment and the performance target. Each table
        is followed by a graph showing the results with a three-cycle trend
        line:
      </p>

      {data?.PLOs?.map((plo, index) => (
        <div className={styles.reportCart}>
          <p>
            <span className={styles.reportParagraphTitle}>
              Student Outcome #{index + 1}:{" "}
            </span>
            {plo.name}
          </p>
          <AntDDataGrid columns={columns} data={plo?.evaluationTable} scroll />

          <GridContainer>
            <GridItem xs={11} sm={11} md={11}>
              <p className={styles.reportParagraphTitle}>
                Assessment Results, Evaluation and Actions:
              </p>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <Button
                justIcon
                round
                color="transparent"
                onClick={() => {
                  console.log("clicked");
                }}
              >
                <EditIcon />
              </Button>
            </GridItem>
          </GridContainer>
          <p>{plo.evaluationResults}</p>

          <div style={{ width: "auto", height: 300 }}>
            <BarChart data={plo?.barChart} />
          </div>
        </div>
      ))}
    </>
  );
};

export default StudentOutcomes;
