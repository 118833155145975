import { Avatar, Grid } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { Breadcrumb, Progress } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAssessmentId } from "../../../../reduxToolkit/features/assessment/AssessmentSlice";
import { fetchCourseById } from "../../../../reduxToolkit/features/course/CoursesSlice";
import { fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import DropDownButtonUserView from "../../../Components/DropDownButtonUserView";

const useStyles2 = makeStyles(styles);

export default function AssessmentsListFaculty({ match }) {

  const { t } = useTranslation();
  const classes2 = useStyles2();

  const dispatch = useDispatch();

  const { id, ProgramId } = match.params;

  useEffect(() => {
    dispatch(fetchCourseById(id));
    dispatch(fetchProgramById(ProgramId));
  }, []);

  const sendID = (id) => {
    dispatch(getAssessmentId(id));
  };

  const course = useSelector((state) => state.course.course);

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link
              to={`/citrine/faculty/assessments_list/course/${id}`}
              style={{ color: "orange" }}
            >
              {t("Assessments Methods")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <GridItem>
        <Card>
          <CardHeader
            color="info"
            icon
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <CardIcon color="primary" style={{ height: "60px" }}>
              <SchoolRounded />
            </CardIcon>
            <GridContainer xs={12} sm={12} md={12}>
              <GridItem xs={12} sm={12} md={2}>
                <div>
                    <h4
                      style={{
                        color: "#8F6682",
                        height: "13px",
                        fontSize: "14px",
                        marginRight: "20px",
                      }}
                    >
                      <strong>
                        {course &&
                          course.program_detail &&
                          course.program_detail.map((el) => el.code)}{" "}
                      </strong>
                    </h4>
                    <h4
                      style={{
                        color: "#6C7B9D",
                        height: "13px",
                        fontSize: "14px",
                        marginRight: "20px",
                      }}
                    >
                      <strong>{course && course.code && course.code}</strong>
                    </h4>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <div>
                    <h4
                      style={{
                        color: "#8F6682",
                        fontSize: "14px",
                        height: "13px",
                      }}
                    >
                      <strong>
                        {course &&
                          course.program_detail &&
                          course.program_detail.map(
                            (el) => el.program_degree_name
                          )}
                      </strong>
                    </h4>
                    <h4
                      style={{
                        color: "#6C7B9D",
                        fontSize: "14px",
                        height: "13px",
                      }}
                    >
                      <strong>{course && course.title}</strong>
                    </h4>
                </div>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={5}
                style={{
                  padding: "5px",
                  margin: "10px 0px",
                  textAlign: "right",
                }}
              >
              </GridItem>
            </GridContainer>
          </CardHeader>
        </Card>
      </GridItem>
      <GridContainer>
        {course &&
          course.assessmentMethod_details &&
          course.assessmentMethod_details.map((item, index) => (
            <GridItem xs={12} sm={6} md={4} onClick={() => sendID(item.id)}>
              <Card>
                <CardHeader style={{ textAlign: "start", height: "100%" }}>
                  <h4
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginLeft: "20px",
                    }}
                  >
                    <Link
                      to={`/citrine/faculty/assessmentsDetails/course/${course.id}/assessmentMethod/${item.id}/${item.name}`}
                    >
                      <strong>
                        <p className={classes2.cardCategory}>{item.name}</p>
                      </strong>
                    </Link>
                    <DropDownButtonUserView
                      AssessmentMethodID={item.id}
                      courseID={course.id}
                      course={course}
                    />
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "40px",
                      marginEnd: "10px",
                    }}
                  >
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: red[600],
                          height: 56,
                          width: 56,
                        }}
                        style={{ backgroundColor: "red" }}
                      >
                      </Avatar>
                      <p style={{ marginTop: "10px", fontSize: "14px" }}>
                        {t("Absent Students")}
                      </p>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: "success",
                          height: 56,
                          width: 56,
                        }}
                        style={{ backgroundColor: "green" }}
                      >
                        {item.total_marks}
                      </Avatar>
                      <p style={{ marginTop: "10px", fontSize: "14px" }}>
                        {" "}
                        {t("Total Marks")}
                      </p>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Progress type="circle" percent={75} width={40} />
                      <p style={{ marginTop: "10px", fontSize: "14px" }}>
                        {" "}
                        {t("Percent of Input")}
                      </p>
                    </Grid>
                  </div>
                </CardHeader>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
