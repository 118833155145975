
import { makeStyles } from "@material-ui/core/styles";
import {
  DataGrid, GridCellEditStopReasons, useGridApiContext
} from "@mui/x-data-grid"; // core components
import { Breadcrumb, notification } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  CourseOutlineUpdate, fetchCourseOutlines
} from "reduxToolkit/features/CourseOutline/CourseOutlineSlice";
import { fetchCourseById } from "../../../../../reduxToolkit/Course/coursesSpecSlice";
import ErrorModal from "../../../../Components/ErrorModal";
import Select from "@mui/material/Select";
import {fetchCourseContents} from "../../../../../reduxToolkit/features/CourseContent/CourseContentSlice";
import {
  fetchTeachingStrategiesSpec
} from "../../../../../reduxToolkit/features/TeachingStrategies/TeachingStrategiesSpecSlice";
import {fetchLearningResources} from "../../../../../reduxToolkit/features/LearningResource/LearningResourceSlice";

const useStyles = makeStyles(styles);

export default function CourseOutlineList({ match }) {
  const [disabledStatus, setDisabledStatus] = useState(false);
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = match.params;

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);

  const course = useSelector((state) => state.CourseSpec.courseSpecification);
  const courseOutlines = useSelector(
      (state) => state.CourseOutline.CourseOutlines
  );
  const courseContents = useSelector((state) => state.CourseContent.CourseContents)
  const teachingStrategiesSpec = useSelector((state) => state.TeachingStrategieSpec.TeachingStrategiesSpec)
  const learningResources = useSelector((state) => state.LearningResource.LearningResources)

  useEffect(() => {
    dispatch(fetchCourseById(id));
    fetchAllCourseOutlines();
    fetchAllCourseContents();
    fetchAllTeachingStrategiesSpec()
    fetchAllLearningResourcesSpec()
    if (localStorage.getItem("CourseState") !== "Draft") {
      setDisabledStatus(true);
    }
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchAllCourseOutlines = async () => {
    try {
      const resultAction = await dispatch(fetchCourseOutlines(id)).unwrap();
      dispatch(fetchCourseById(id));
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchAllCourseContents = async () => {
    try {
      const resultAction = await dispatch(fetchCourseContents(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchAllTeachingStrategiesSpec = async () => {
    try {
      const resultAction = await dispatch(fetchTeachingStrategiesSpec(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchAllLearningResourcesSpec = async () => {
    try {
      const resultAction = await dispatch(fetchLearningResources(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const onCellEditCommit = async (event) => {
    console.log("shit", event)
    const value = event.value;
    const field = event.field;
    const _id = event.id;
    const modified = { [field]: value !== "" ? value : null };
    if (event.cellMode) {
      console.log("here: " + value)
      console.log("here: " + field)
      console.log("here: " + _id)
      console.log("here: " + {...modified})
      const resultAction = await dispatch(CourseOutlineUpdate({ id, modified }))
      if (CourseOutlineUpdate.fulfilled.match(resultAction)) {
        fetchAllCourseOutlines();
        openNotificationWithIcon("success", `Updated successfully`);
      } else {
        if (resultAction.payload) {
          setHandledError(resultAction.payload);
          handleOpenErrorModal();
        } else {
          setHandledError(resultAction.error);
          handleOpenErrorModal();
        }
      }
    }
  };

  function SelectEditCourseContentCell(props) {
    const { id, value, field } = props;
    console.log("props", props)
    const apiRef = useGridApiContext();
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };
    return (
        <Select
            value={value}
            onChange={handleChange}
            size="small"
            sx={{ height: 1 }}
            native
            autoFocus
        >
          {courseContents?.map(courseContent => (
              <option>{courseContent?.name}</option>
          ))}
        </Select>
    );
  }

  function SelectEditTeachingStrategiesCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };
    return (
        <Select
            value={value}
            onChange={handleChange}
            size="small"
            sx={{ height: 1 }}
            native
            autoFocus
        >
          {teachingStrategiesSpec?.map(teachingStrategy => (
              <option>{teachingStrategy?.planned_teaching_strategy_detail?.name}</option>
          ))}
        </Select>
    );
  }

  function SelectEditLearningResourcesCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };
    return (
        <Select
            value={value}
            onChange={handleChange}
            size="small"
            sx={{ height: 1 }}
            native
            autoFocus
        >
          {learningResources?.map(learningResource => (
              <option>{learningResource?.name}</option>
          ))}
        </Select>
    );
  }

  const renderSelectEditCourseContentCell = (params) => {
    return <SelectEditCourseContentCell {...params} />;
  };

  const renderSelectEditTeachingStrategiesCell = (params) => {
    return <SelectEditTeachingStrategiesCell {...params} />;
  };

  const renderSelectEditLearningResourceCell = (params) => {
    return <SelectEditLearningResourcesCell {...params} />;
  };

  const columns = [
    {
      field: "week",
      headerName: "Week",
      flex: 1,
      editable: !disabledStatus,
      sortable: false,
    },
    {
      field: "courseSpecContent",
      headerName: "Topic",
      renderEditCell: renderSelectEditCourseContentCell,
      flex: 1,
      editable: !disabledStatus,
      sortable: false,
    },
    {
      field: "teachingStrategiesSpec",
      headerName: "Class plan",
      renderEditCell: renderSelectEditTeachingStrategiesCell,
      flex: 1,
      editable: !disabledStatus,
      sortable: false,
    },
    {
      field: "learningResourceSpec",
      headerName: "Reading materials",
      renderEditCell: renderSelectEditLearningResourceCell,
      flex: 1,
      editable: !disabledStatus,
      sortable: false,
    },
    {
      field: "assessmentMethodSpec",
      headerName: "Tasks and assignments",
      flex: 2,
      editable: !disabledStatus,
      sortable: false,
    },
  ];

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/CourseSpecificationList/`}>
              {t("Courses List")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/courseSpecDetails/${course && course.id && course.id
                }`}
            >
              {course && course.title && course.title}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              to={`/citrine/specification/course/${course && course.id && course.id
                }/CourseOutlines`}
              style={{ color: "orange" }}
            >
              {" "}
              {t("Course Outlines List")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <Card simple>
        <CardBody simple>
          <div style={{ height: 600, width: "100%"}}>
            <DataGrid
              rowHeight={50}
              rows={courseOutlines || []}
              columns={columns}
              autoPageSize={true}
              columnBuffer={0}
              disableSelectionOnClick
              onCellEditStop={(params, event) => {
                if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                  event.defaultMuiPrevented = true;
                  console.log("params: ", params)
                  console.log("event: ", event)
                }
              }}
              showCellRightBorder
            />
          </div>
        </CardBody>
      </Card>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
