import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";
import { updateReportBody } from "../reportUtils";

const Options = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "BACKGROUNDINFORMATION",
      "Options",
      "description",
      content
    );
  };

  return (
    <>
      <h3>Background Information: Options</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of the options available for the program:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
      <br />
      <p className={styles.reportParagraphTitle}>
        The following is the list of the options initially defined for the
        program:
      </p>
      {/* <GridItem xs={1} sm={1} md={1}>
          <Button
            justIcon
            round
            color="transparent"
            onClick={() => {
              console.log("clicked");
            }}
          >
            <EditIcon />
          </Button>
        </GridItem> */}
      <ul>
        {data?.options?.map((option, index) => (
          <li key={index}>{option}</li>
        ))}
      </ul>

      <p className={styles.reportParagraphTitle}>
        The following is the list of the possible and major tracks initially defined for the
        program:
      </p>
      {/* <GridItem xs={1} sm={1} md={1}>
          <Button
            justIcon
            round
            color="transparent"
            onClick={() => {
              console.log("clicked");
            }}
          >
            <EditIcon />
          </Button>
        </GridItem> */}
      <ul>
        {data?.tracks?.map((track, index) => (
          <li key={index}>{track}</li>
        ))}
      </ul>
    </>
  );
};

export default Options;
