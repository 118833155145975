import React, { useEffect, useState } from "react";
import { Form, Input, Select, Breadcrumb } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  createDifficultyType,
  fetchDifficultyType,
  updateDifficultyType,
} from "../../../../reduxToolkit/features/difficultyType/DifficultyTypeSlice";
import ErrorModal from "../../../Components/ErrorModal";
const { Option } = Select;
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 4,
    span: 16,
  },
};

export default function CreateUpdateDifficultyType({ match }) {
  const { t } = useTranslation();
  const { id } = match.params;
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const onFinish = (values) => {
    console.log(values);
    if (id) {
      handleUpdateDifficultyType(values);
    } else {
      handleCreateDifficultyType(values);
    }
  };
  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchDifficultyType(id))
        .unwrap()
        .then((difficultyType) => {
          form.setFieldsValue({
            type: difficultyType.type,
          });
        })
        .catch((error) => {
          // handle error here
          setHandledError(error);
          handleOpenErrorModal();
        });
    }
  }, []);

  const { difficultyType, error } = useSelector(
    (state) => state.difficultyTypes
  );

  const handleCreateDifficultyType = async (values) => {
    const resultAction = await dispatch(createDifficultyType({ ...values }));
    if (createDifficultyType.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const difficultyType = resultAction.payload;
      //   showToast('success', `Updated ${user.first_name} ${user.last_name}`)
      history.push(`/citrine/configuration/others/difficultyTypes`);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log(resultAction.payload.field_errors);
        setHandledError(resultAction.payload);
      } else {
        console.log("error", `Update failed: ${resultAction.error}`);
        setHandledError(resultAction.error);
      }
    }
  };

  const handleUpdateDifficultyType = async (values) => {
    const resultAction = await dispatch(
      updateDifficultyType({ id, ...values })
    );
    if (updateDifficultyType.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const difficultyType = resultAction.payload;
      //   showToast('success', `Updated ${user.first_name} ${user.last_name}`)
      history.push(`/citrine/configuration/others/difficultyTypes`);
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log(resultAction.payload.field_errors);
        setHandledError(resultAction.payload);
      } else {
        console.log("error", `Update failed: ${resultAction.error}`);
        setHandledError(resultAction.error);
      }
    }
  };

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/configuration/others`}>
            <span> {t("Others")} </span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/citrine/configuration/others/difficultyTypes`}>
            {t("Difficulty types")}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{id ? t("Update") : t("Create")}</Breadcrumb.Item>
      </Breadcrumb>
      <br />
      <Card style={{ padding: "0 30px 0 30px" }}>
        <CardBody>
          <h4>
            <span>
              {" "}
              {id
                ? t("Update difficulty type")
                : t("Create difficulty type")}{" "}
            </span>
          </h4>
          <br />
          <Form form={form} name="control-hooks" onFinish={onFinish}>
            <Form.Item
              name="type"
              label="Type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <GridContainer justify="flex-end">
                <GridItem>
                  <Button
                    simple
                    color="primary"
                    onClick={() => {
                      history.push(
                        "/citrine/configuration/others/difficultyTypes"
                      );
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                </GridItem>
                <GridItem>
                  <Button type="submit" color="primary">
                    {t("Save")}
                  </Button>
                </GridItem>
              </GridContainer>
            </Form.Item>
          </Form>
        </CardBody>
      </Card>
      {error && (
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      )}
    </>
  );
}
