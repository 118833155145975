import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";
import { updateReportBody } from "../reportUtils";

const TransferStudentsAndTransferCourses = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

const handleChangeDescription = (content) => {
  setValueContent(content);
  updateReportBody(
    setReportBody,
    "CRITERION1_STUDENTS",
    "TransferStudentsAndTransferCourses",
    "description",
    content
  );
};

  return (
    <>
      <h3>STUDENTS: Transfer Students and Transfer courses</h3>
      <p className={styles.reportParagraphTitle}>
        The following is a summary of the process for accepting transfer
        students and transfer credit into the program:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
      <br />
      <p className={styles.reportParagraphTitle}>
        The following is the list of the transfer students acceptance
        requirements for the program:
      </p>
      <ul>
        {data?.transferStudentsAcceptanceRequirements?.map(
          (requirement, index) => (
            <li key={index}>{requirement}</li>
          )
        )}
      </ul>

      <br />
      <p className={styles.reportParagraphTitle}>
        The following is the list of the transfer credit acceptance requirements
        for the program:
      </p>
      <ul>
        {data?.transferCreditAcceptanceRequirements?.map(
          (requirement, index) => (
            <li key={index}>{requirement}</li>
          )
        )}
      </ul>
    </>
  );
};

export default TransferStudentsAndTransferCourses;
