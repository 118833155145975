import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";
import { updateReportBody } from "../reportUtils";

const ProgramLocations = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "BACKGROUNDINFORMATION",
      "ProgramLocations",
      "description",
      content
    );
  };

  return (
    <>
      <h3>Background Information: Program Locations</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of the locations where the program or a
        portion of the program is regularly offered:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
      <br />
      {/* <p className={styles.reportParagraphTitle}>
        The following is the list of the locations initially defined for the
        program:
      </p>
      <ul>
        {data?.locations?.map((location, index) => (
          <li key={index}>{location}</li>
        ))}
      </ul> */}
      <p className={styles.reportParagraphTitle}>
        The following is the location initially defined for the program: {data?.locations?.location}
      </p>
    </>
  );
};

export default ProgramLocations;
