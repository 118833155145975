export function isDate2GreaterThanDate1(date1, date2) {
    // Check if either input is not a Date object or a string
    if (!(date1 instanceof Date || typeof date1 === 'string') ||
        !(date2 instanceof Date || typeof date2 === 'string')) {
        console.log("Invalid date input");
        return false;
    }

    const parseDate = (date) => {
        if (date instanceof Date) {
            return date;
        } else {
            const parsedDate = new Date(date);
            return isNaN(parsedDate.getTime()) ? null : parsedDate;
        }
    };

    const parsedDate1 = parseDate(date1);
    const parsedDate2 = parseDate(date2);

    // Check if either parsed date is null (invalid date string)
    if (!parsedDate1 || !parsedDate2) {
        console.log("Invalid date input");
        return false;
    }
    console.log("parsedDate2.getTime()", parsedDate2.getTime())
    console.log("parsedDate1.getTime()", parsedDate1.getTime())

    return parsedDate2.getTime() >= parsedDate1.getTime();
}
export default isDate2GreaterThanDate1

// TODO: remove current from function's params and get the current control outside of the funcion
export function calculateYearsBetweenExperienceDates(startDate, endDate, current) {
    // Parse the date strings into Date objects
    let start = new Date(startDate);
    let end = new Date(endDate);

    if (current && !endDate) {
        // Get current date in YYYY-MM-DD format
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const dd = String(today.getDate()).padStart(2, '0');
        const currentDate = `${yyyy}-${mm}-${dd}`;
        
        end = new Date(currentDate);
      }

    // Check if both dates are valid
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        console.log("Invalid date input");
        return 0;
    }

    // Check if end date is not before start date
    if (end < start) {
        console.log("End date is before start date");
        return 0;
    }

    // Calculate the difference in years
    let years = end.getFullYear() - start.getFullYear();

    // Check if we haven't reached the anniversary date yet
    if (
        end.getMonth() < start.getMonth() ||
        (end.getMonth() === start.getMonth() && end.getDate() < start.getDate())
    ) {
        years--;
    }

    return years;
}