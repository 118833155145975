export const updateReportBody = (setReportBody, criterionKey, sectionKey, fieldKey, newContent) => {
    setReportBody((prevReport) => ({
      ...prevReport,
      [criterionKey]: {
        ...prevReport[criterionKey],
        [sectionKey]: {
          ...prevReport[criterionKey][sectionKey],
          [fieldKey]: newContent,
        },
      },
    }));
  };
  export const Analysis = [
    {
      id: 1,
      subject: "Curriculum Breadth",
      descripton:
        "The curriculum provides strong coverage of core engineering concepts, but there is limited exposure to emerging technologies such as artificial intelligence and data analytics, which are increasingly important in modern engineering practice.",
      type: "concern",
      assignees: [
        { name: "John", id: "1" },
        { name: "Jane", id: "2" },
      ],
      startdate: "01/01/2025",
      enddate: "12/3/2025",
      actions: [
        {
          id: "1",
          feedbackId: "1",
          subject: "action 1",
          descripton: "descripton action 1",
          date: "01/01/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "2",
          feedbackId: "1",
          subject: "action 2",
          descripton: "descripton action 2",
          date: "01/02/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "3",
          feedbackId: "1",
          subject: "action 3",
          descripton: "descripton action 3",
          date: "01/05/2025",
          assignees: [{ name: "Jane", id: "2" }],
        },
      ],
    },
    {
      id: 2,
      subject: "Outcomes Assessment",
      descripton:
        "The program has not adequately demonstrated a structured and comprehensive approach to assessing student outcomes. There is no clear evidence that student performance is systematically evaluated against the stated program outcomes.",
      type: "deficiency",
      assignees: [
        { name: "John", id: "1" },
        { name: "Jane", id: "2" },
      ],
      startdate: "01/01/2025",
      enddate: "12/3/2025",
      actions: [
        {
          id: "4",
          feedbackId: "2",
          subject: "action 1",
          descripton: "descripton action 1",
          date: "01/01/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "5",
          feedbackId: "2",
          subject: "action 2",
          descripton: "descripton action 2",
          date: "01/02/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "6",
          feedbackId: "2",
          subject: "action 3",
          descripton: "descripton action 3",
          date: "01/05/2025",
          assignees: [{ name: "Jane", id: "2" }],
        },
      ],
    },
    {
      id: 3,
      subject: "Advising and Mentorship",
      descripton:
        "While academic advising is available, there is a weakness in the advising process, as students have expressed concerns about the lack of personalized guidance and mentorship to help them navigate their academic and career pathways.",
      type: "weakness",
      assignees: [
        { name: "John", id: "1" },
        { name: "Jane", id: "2" },
      ],
      startdate: "01/01/2025",
      enddate: "12/3/2025",
      actions: [
        {
          id: "7",
          feedbackId: "3",
          subject: "action 1",
          descripton: "descripton action 1",
          date: "01/01/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "8",
          feedbackId: "3",
          subject: "action 2",
          descripton: "descripton action 2",
          date: "01/02/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "9",
          feedbackId: "3",
          subject: "action 3",
          descripton: "descripton action 3",
          date: "01/05/2025",
          assignees: [{ name: "Jane", id: "2" }],
        },
      ],
    },
    {
      id: 4,
      subject: "Strong Industry Partnerships",
      descripton:
        "The program has established strong partnerships with local industries, offering students valuable internship opportunities and industry-sponsored projects that enhance their professional readiness.",
      type: "strength",
      assignees: [
        { name: "John", id: "1" },
        { name: "Jane", id: "2" },
      ],
      startdate: "01/01/2025",
      enddate: "12/3/2025",
      //feedback of type strength does not have actions associated with it
      actions: [],
    },
    {
      id: 5,
      subject: "Expand Emerging Technology Offerings",
      descripton:
        "To keep pace with industry trends, the program should consider introducing electives or modules focused on emerging areas such as artificial intelligence, robotics, and renewable energy.",
      type: "recommendation for improvement",
      assignees: [
        { name: "John", id: "1" },
        { name: "Jane", id: "2" },
      ],
      startdate: "01/01/2025",
      enddate: "12/3/2025",
      actions: [
        {
          id: "13",
          feedbackId: "5",
          subject: "action 1",
          descripton: "descripton action 1",
          date: "01/01/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "14",
          feedbackId: "5",
          subject: "action 2",
          descripton: "descripton action 2",
          date: "01/02/2025",
          assignees: [{ name: "John", id: "1" }],
        },
        {
          id: "15",
          feedbackId: "5",
          subject: "action 3",
          descripton: "descripton action 3",
          date: "01/05/2025",
          assignees: [{ name: "Jane", id: "2" }],
        },
      ],
    },
  ]