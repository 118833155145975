import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";
import { updateReportBody } from "../reportUtils";

const AdvisingAndCareerGuidance = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION1_STUDENTS",
      "AdvisingAndCareerGuidance",
      "description",
      content
    );
  };

  return (
    <>
      <h3>STUDENTS: Advising and Career Guidance</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a summary of the process for advising and providing
        career guidance to students. Including information on how often students
        are advised and who provides the advising:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
      <br />

      <p className={styles.reportParagraphTitle}>
        The following is a description of the Guidance and orientation programs
        for new students:
      </p>
      <p>{data.GuidanceAndOrientationProgramsForNewStudents}</p>

      <br />

      <p className={styles.reportParagraphTitle}>
        The following is a description of the offered student counseling
        services:
      </p>
      <p>{data.StudentCounselingServices}</p>

      <br />

      <p className={styles.reportParagraphTitle}>
        The following is a description of the offered special support:
      </p>
      <p>{data.SpecialSupport}</p>
    </>
  );
};

export default AdvisingAndCareerGuidance;
