import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

const initialState = {
  TeachingStrategiesSpec: [],
  TeachingStrategiesSpecStatus: "idle",
  error: null,
  TeachingStrategieSpec: null,
  TeachingStrategieByCourse: [],
  id: null,
};

export const fetchTeachingStrategiesSpec = createAsyncThunk(
  "TeachingStrategieSpec/fetchTeachingStrategiesSpec",
  async (courseSpec_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/teachingStrategiesSpec/?courseSpec_id=${courseSpec_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      // const response = jsonData
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const fetchTeachingStrategiesByCourse = createAsyncThunk(
  "TeachingStrategieSpec/fetchTeachingStrategiesByCourse",
  async (course_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl +
          `/teachingStrategiesSpec/areaSpec_filtered/?courseSpec_id=${course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const fetchTeachingStrategieById = createAsyncThunk(
  "TeachingStrategie/fetchTeachingStrategieById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/teachingStrategiesSpec/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const teachingStrategieUpdate = createAsyncThunk(
  "TeachingStrategie/teachingStrategieUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/teachingStrategiesSpec/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);
export const addNewTeachingStrategieSpec = createAsyncThunk(
  "TeachingStrategieSpec/addNewTeachingStrategieSpec",
  async (initialTeachingStrategieSpec, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/teachingStrategiesSpec/create/`,
        initialTeachingStrategieSpec,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const deleteTeachingStrategieSpec = createAsyncThunk(
  "TeachingStrategieSpec/deleteTeachingStrategieSpec",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/teachingStrategiesSpec/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

const TeachingStrategieSpecSlice = createSlice({
  name: "TeachingStrategieSpec",
  initialState,
  extraReducers: {
    [fetchTeachingStrategiesSpec.pending]: (state, action) => {
      state.TeachingStrategiesStatus = "loading";
    },
    [fetchTeachingStrategiesSpec.fulfilled]: (state, action) => {
      state.TeachingStrategiesStatus = "succeeded";
      state.TeachingStrategiesSpec = action.payload;
    },
    [fetchTeachingStrategiesSpec.rejected]: (state, action) => {
      state.TeachingStrategiesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [fetchTeachingStrategiesByCourse.pending]: (state, action) => {
      state.FacilitiesRequiredStatus = "loading";
    },
    [fetchTeachingStrategiesByCourse.fulfilled]: (state, action) => {
      state.FacilitiesRequiredStatus = "succeeded";
      state.TeachingStrategieByCourse = action.payload;
    },
    [fetchTeachingStrategiesByCourse.rejected]: (state, action) => {
      state.FacilitiesRequiredStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewTeachingStrategieSpec.fulfilled]: (state, action) => {
      state.TeachingStrategiesSpec.push(action.payload);
    },
    [deleteTeachingStrategieSpec.fulfilled]: (state, action) => {
    },
    [fetchTeachingStrategieById.pending]: (state, action) => {
      state.TeachingStrategiesSpecStatus = "loading";
    },
    [fetchTeachingStrategieById.fulfilled]: (state, action) => {
      state.TeachingStrategiesSpecStatus = "succeeded";
      state.TeachingStrategieSpec = action.payload;
    },
    [fetchTeachingStrategieById.rejected]: (state, action) => {
      state.TeachingStrategiesSpecStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [teachingStrategieUpdate.fulfilled]: (state, action) => {
    },
    [addNewTeachingStrategieSpec.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteTeachingStrategieSpec.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [teachingStrategieUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    reducers: {
      getId: (state, action) => {
        state.id = action.payload;
      },
    },
  },
});

export const { getId } = TeachingStrategieSpecSlice.actions;

export default TeachingStrategieSpecSlice.reducer;
