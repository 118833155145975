import { Tabs } from "antd";
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { greyColor } from "assets/jss/material-dashboard-pro-react";
import { Link } from "react-router-dom";
import React from "react";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import FacultySize from "./FacultySize";
import FacultyWorkload from "./FacultyWorkload";
import FacultyQualifications from "./FacultyQualifications";
import ProfessionalDevelopment from "./ProfessionalDevelopment";
import AuthorityAndResponsibilityOfFaculty from "./AuthorityAndResponsibilityOfFaculty";
import Criterion1Analysis from "../criterion1/Analysis";

const onChange = (key) => {
  console.log(key);
};

const Criterion6 = ({ data, report, setReportBody }) => (
  <>
    {/* <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>SELF-STUDY REPORT</p> */}

    <Tabs
      defaultActiveKey="1"
      onChange={onChange}
      items={[
        {
          label: `Faculty Qualifications`,
          key: "1",
          children: (
            <FacultyQualifications
              data={data.FacultyQualifications}
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
        {
          label: `Faculty Workload`,
          key: "2",
          children: (
            <FacultyWorkload
              data={data.FacultyWorkload}
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
        {
          label: `Faculty Size`,
          key: "3",
          children: (
            <FacultySize
              data={data.FacultySize}
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
        {
          label: `Professional Development`,
          key: "4",
          children: (
            <ProfessionalDevelopment
              data={data.ProfessionalDevelopment}
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
        {
          label: `Authority and Responsibility of Faculty`,
          key: "5",
          children: (
            <AuthorityAndResponsibilityOfFaculty
              data={data.AuthorityAndResponsibilityOfFaculty}
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
        {
          label: `Analysis`,
          key: "6",
          children: (
            <Criterion1Analysis
              data={data.Analysis}
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
      ]}
    />
  </>
);
export default Criterion6;
