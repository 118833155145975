import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";
import { updateReportBody } from "../reportUtils";

const ProgramConstituencies = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION2_PROGRAMEDUCATIONALOBJECTIVES",
      "ProgramConstituencies",
      "description",
      content
    );
  };

  return (
    <>
      <h3> PROGRAM EDUCATIONAL OBJECTIVES: Program Constituencies</h3>

      {/* <p className={styles.reportParagraphTitle}>
        The Program Constituencies for the {data.programName} program are:
      </p> */}

      {/* <ul>
        {data?.constituencies?.map((constituent , index) => (
          <li key={index}>{constituent }</li>
        ))}
      </ul> */}

      <br />

      <p className={styles.reportParagraphTitle}>
        The following is the list of the constituencies for the{" "}
        {data.programName} program and a description of how the program
        educational objectives meet the needs of these constituencies:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
    </>
  );
};

export default ProgramConstituencies;
