import { Tabs } from "antd";
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { greyColor } from "assets/jss/material-dashboard-pro-react";
import { Link } from "react-router-dom";
import React from "react";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AdditionalInformation from "./AdditionalInformation";
import StudentOutcomes from "./StudentOutcomes";
import ContinuousImprovement from "./ContinuousImprovement";
import Criterion1Analysis from "../criterion1/Analysis";

const onChange = (key) => {
  console.log(key);
};

const Criterion4 = ({ data, report, setReportBody }) => (
  <>
    {/* <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>SELF-STUDY REPORT</p> */}

    <Tabs
      defaultActiveKey="1"
      onChange={onChange}
      items={[
        {
          label: `Student Outcomes`,
          key: "1",
          children: (
            <StudentOutcomes
              data={data.StudentOutcomes}
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
        {
          label: `Continuous Improvement`,
          key: "2",
          children: (
            <ContinuousImprovement
              data={data.ContinuousImprovement}
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
        {
          label: `Additional Information`,
          key: "3",
          children: (
            <AdditionalInformation
              data={data.AdditionalInformation}
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
        {
          label: `Analysis`,
          key: "4",
          children: (
            <Criterion1Analysis
              data={data.Analysis}
              report={report}
              setReportBody={setReportBody}
            />
          ),
        },
      ]}
    />
  </>
);
export default Criterion4;
