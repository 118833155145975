import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";
import { updateReportBody } from "../reportUtils";

const ContinuousImprovement = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION4_CONTINUOUSIMPROVEMENT",
      "ContinuousImprovement",
      "description",
      content
    );
  };

  return (
    <>
      <h3>CONTINUOUS IMPROVEMENT: Continuous Improvement</h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of how the results of the evaluation
        processes for the student outcomes and any other available information
        have been systematically used as input in the continuous improvement of
        the program, the results of the changes (whether or not effective) in
        those cases where re-assessment of the results has been completed, and
        significant future program improvement plans based upon recent
        evaluations:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
    </>
  );
};

export default ContinuousImprovement;
