import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb, notification } from "antd";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  fetchReportDocuments,
  createReportDocument,
} from "../../../../../reduxToolkit/features/Report/ReportDocument/ReportDocumentSlice";
import CustomDropdown from "../../../../../components/CustomDropdown/CustomDropdown";
import ErrorModal from "../../../../Components/ErrorModal";
import SuperGeneralDeleteModal from "views/Components/SuperGeneralDeleteModal";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import GenerateReportDocumentModal from "../../GenerateReportDocumentModal";

const rotatedIconStyle = {
  transform: "rotate(180deg)",
};

export default function ReportDocumentsSection({
  reportId,
  expandDocumentsSection,
  setExpandDocumentsSection,
  currentUser,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();

  const [tobeDeleted, setTobeDeleted] = useState(null);
  const [handledError, setHandledError] = useState("");
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openGenerateReportDocumentModal, setOpenGenerateReportDocumentModal] =
    React.useState(false);
  const handleClickOpenGenerateReportDocumentModal = () => {
    setOpenGenerateReportDocumentModal(true);
  };
  const handleCloseGenerateReportDocumentModal = () => {
    setOpenGenerateReportDocumentModal(false);
  };
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const handleOpenDeleteModal = (id) => {
    setDeleteModalIsOpen(true);
    setTobeDeleted(id);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  // const handleDeleteLocation = async (id) => {
  //   try {
  //     await dispatch(deleteLocation(id)).unwrap();
  //     handleCloseDeleteModal();
  //     openNotificationWithIcon("success", `Deleted`);
  //     handleFetchLocations();
  //   } catch (err) {
  //     setHandledError(err);
  //     handleOpenErrorModal();
  //   }
  // };

  const handleFetchReportDocuments = async () => {
    try {
      await dispatch(fetchReportDocuments()).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    handleFetchReportDocuments();
  }, []);

  const { reportDocuments, error, loading } = useSelector(
    (state) => state.ReportDocument
  );
  let content;

  if (!error && loading == "idle") {
    if (reportDocuments.length == 0) {
      content = (
        <div>
          {" "}
          There are no report Documents in the database, You can add new report
          Documents using the Add button above.{" "}
        </div>
      );
    } else {
      content = reportDocuments?.map((reportDocument) => (
        <GridItem key={reportDocument.id} lg={12}>
          <Card style={{ height: 250 }}>
            <CardHeader>
              <GridContainer alignItems="center">
                <GridItem xs={10} sm={10} md={10}>
                  <Link
                    to={`/citrine/reports/ABET/SSR/${reportId}/documents/${reportDocument.id}`}
                  >
                    <h4>{reportDocument.name}</h4>
                  </Link>
                </GridItem>

                <GridItem
                  xs={2}
                  sm={2}
                  md={2}
                  style={{ color: primaryColor[0] }}
                >
                  {/* <CustomDropdown
                    isButtonIcon
                    buttonProps={{ size: "sm" }}
                    dropdownList={[
                      // {
                      //   text: "Edit",
                      //   onClickHandler() {
                      //     // history.push(
                      //     //   `/citrine/configuration/others/locations/${location.id}/update`
                      //     // );
                      //   },
                      // },
                      {
                        text: "Delete",
                        onClickHandler() {
                          // handleOpenDeleteModal(location.id);
                          console.log("clicked delete");
                        },
                      },
                    ]}
                  /> */}
                  <Button
                    justIcon
                    round
                    color="transparent"
                    size="sm"
                    onClick={() => {
                      console.log("clicked delete");
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <p>
                <strong>Name:</strong> {reportDocument.name}
              </p>
              <p>
                <strong>Version:</strong> {reportDocument.version}
              </p>
              <p>
                <strong>State:</strong> {reportDocument.state}
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {new Date(reportDocument.created_at).toLocaleDateString()}
              </p>
              <p>
                <strong>Updated At:</strong>{" "}
                {new Date(reportDocument.updated_at).toLocaleDateString()}
              </p>
            </CardBody>
          </Card>
        </GridItem>
      ));
    }
  }

  const handleGenerateHTMLReport = async (data) => {
    const resultAction = await dispatch(createReportDocument(data));
    if (createReportDocument.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      openNotificationWithIcon(
        "success",
        `Report document created successfully`
      );
      handleFetchReportDocuments();
      handleCloseGenerateReportDocumentModal()
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleCloseGenerateReportDocumentModal()
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleCloseGenerateReportDocumentModal()
        handleOpenErrorModal();
      }
    }
  };

  return (
    <>
      {expandDocumentsSection ? (
        <>
          <GridContainer justifyContent="center" alignItems="center">
            <GridItem
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              {window.innerWidth > 600 && (
                <Button
                  justIcon
                  round
                  color="primary"
                  size="sm"
                  onClick={() => {
                    setExpandDocumentsSection(false);
                  }}
                >
                  <MenuOpenIcon style={rotatedIconStyle} />
                </Button>
              )}
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#5B5B5B",
                  marginBottom: "0 !important",
                }}
              >
                Documents
              </span>
            </GridItem>
          </GridContainer>
          <br />
          <div>
            <Button simple size="lg" color="primary" onClick={handleClickOpenGenerateReportDocumentModal}>
              {t("GENERATE")}
            </Button>
            <br />
            <GridContainer>{content}</GridContainer>
          </div>
        </>
      ) : (
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem
            style={{
              color: "#5B5B5B",
            }}
          >
            <Button
              justIcon
              round
              color="transparent"
              size="sm"
              onClick={() => {
                setExpandDocumentsSection(true);
              }}
            >
              <MenuOpenIcon />
            </Button>
          </GridItem>
        </GridContainer>
      )}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <GenerateReportDocumentModal
        open={openGenerateReportDocumentModal}
        handleClickOpen={handleClickOpenGenerateReportDocumentModal}
        handleClose={handleCloseGenerateReportDocumentModal}
        handleGenerate={handleGenerateHTMLReport}
        reportId={reportId}
        template_name="E002_EAC-Self-Study-Questionnaire_2025-2026"
        currentUser={currentUser}
      />
    </>
  );
}
