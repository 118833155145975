import React, { useRef, useState } from "react";
import "./NewFile.css";
import { useDispatch, useSelector } from "react-redux";
//import { getDocs, PostDoc } from "../reduxToolkit/docslice/DocSlice";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";

import uploadImg from "./img/upload.jpg";
import { ImageConfig } from "./ImageConfig";
import MenuItem from "@material-ui/core/MenuItem";

import DeleteIcon from "@material-ui/icons/Delete";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ErrUploadModal from "./ErrUploadModal";
import { uploadQuestionNotes, uploadNotes } from "reduxToolkit/features/assessment/AssessmentSlice";
import { fetchProgramById } from "reduxToolkit/features/program/ProgramsSlice";
import { notification } from "antd";
import { fetchExamByQuestionAndNotes } from "reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const NewFile = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState("");
  // const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [document, setDocument] = useState("");
  const [loading, setLoading] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const fileRemove = () => {
    setFileList("");
    setDocument("");
  };
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  const onFinish = async (values) => {
    //console.log("Success:", values);
    let document = fileList;
    let name = "import_export";
    let model = "examByQuestion";
    let resource_id = props.examID;
    let resource = "import_export";
    let course_id = props.courseID;


    const uploadData = new FormData();
    uploadData.append("document", document);
    uploadData.append("name", name);
    uploadData.append("model", model);
    uploadData.append("resource_id", resource_id);
    uploadData.append("resource", resource);
    uploadData.append("course_id", course_id);

    const data = {
      fileList, name, model, resource_id, resource, course_id
    }
    console.log(data)
    const resultAction = await dispatch(uploadNotes(uploadData));

    if (uploadNotes.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", ` success Import `);
      dispatch(fetchProgramById(props.program_id));
      handleClose();
      setLoading(false);
      dispatch(fetchExamByQuestionAndNotes(props.obj_Assessment));

      console.log(resultAction, "res action")
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log(
          "resultAction.payload from component",
          resultAction.payload
        );
        console.log(props.examID, 'rssr')

        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        // handleClose();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        //handleClose();
      }
    }
    //handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    fileRemove();
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    //setLoading(false);
    setFileList(e.target.files[0]);
    // console.log(e.target.files)
  };

  const handleShow = () => setOpen(true);

  return (
    <div>
      <MenuItem
        onClick={(e) => {
          handleShow();
          props.handleClose();
        }}
        style={{ color: "#AC79B0" }}
      >
        {t("Import Grades")}
      </MenuItem>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="max-width-dialog-title"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Upload a new document
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          dividers
        >
          <>
            {fileList ? (
              <>
                {loading ? (
                  <div className="loading1">
                    <div></div>
                  </div>
                ) : (
                  <div className="drop-file-preview__item">
                    <img
                      src={
                        ImageConfig[fileList.type.split("/")[1]] ||
                        ImageConfig["xlsx"]
                      }
                      alt=""
                    />
                    <div className="drop-file-preview__item__info">
                      <p>{fileList.name}</p>
                      <p>
                        {(fileList.size / 1024).toString().substring(0, 4)}Ko
                      </p>
                    </div>
                    <span className="dropdel" onClick={fileRemove}>
                      <DeleteIcon style={{ color: red[600] }} />
                    </span>
                  </div>
                )}
              </>
            ) : (
              <>
                <div
                  ref={wrapperRef}
                  className="drop-file-input"
                  onDragEnter={onDragEnter}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                >
                  <div className="drop-file-input__label">
                    <img src={uploadImg} alt="" />
                    <p>Click or drag file to this area</p>
                  </div>
                  <input
                    type="file"
                    onChange={(e) => {
                      handleUpload(e);
                    }}
                    accept=".xlsx"
                  />
                </div>
              </>
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: red[600], backgroundColor: "#F8F8F" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={fileList.length === 0}
            onClick={onFinish}
            style={{ color: green[600], backgroundColor: "#F8F8F" }}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <ErrUploadModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
};

export default NewFile;
