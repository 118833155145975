import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

const initialState = {
  LearningResources: [],
  LearningResourcesStatus: "idle",
  LearningResourceStatus: "idle",
  error: null,
  LearningResource: null,
  LearningResourcesByCourse: [],
  id: null,
};

export const fetchLearningResources = createAsyncThunk(
  "LearningResource/fetchLearningResources",
  async (course_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/learningResourcesSpec/?courseSpec=${course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const fetchLearningResourcesByCourse = createAsyncThunk(
  "LearningResource/fetchLearningResourcesByCourse",
  async (course_id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl +
        `/learningResourcesSpec/areaSpec_filtered/?courseSpec_id=${course_id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const fetchLearningResourceById = createAsyncThunk(
  "LearningResource/fetchLearningResourceById",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        BaseUrl + `/learningResourcesSpec/${id}/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const addNewLearningResource = createAsyncThunk(
  "LearningResource/addNewLearningResource",
  async (initialLearningResource, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/learningResourcesSpec/create/`,
        initialLearningResource,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const LearningResourceUpdate = createAsyncThunk(
  "LearningResource/LearningResourceUpdate",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/learningResourcesSpec/${data.id}/edit/`,
        data.modified,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const deleteLearningResource = createAsyncThunk(
  "LearningResource/deleteLearningResource",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        BaseUrl + `/learningResourcesSpec/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

const LearningResourceSlice = createSlice({
  name: "LearningResource",
  initialState,
  extraReducers: {
    [fetchLearningResources.pending]: (state, action) => {
      state.LearningResourcesStatus = "loading";
    },
    [fetchLearningResources.fulfilled]: (state, action) => {
      state.LearningResourcesStatus = "succeeded";
      // Add any fetched posts to the array
      state.LearningResources = action.payload;
    },
    [fetchLearningResources.rejected]: (state, action) => {
      state.LearningResourcesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [fetchLearningResourceById.pending]: (state, action) => {
      state.LearningResourceStatus = "loading";
    },
    [fetchLearningResourceById.fulfilled]: (state, action) => {
      state.LearningResourceStatus = "succeeded";
      // Add any fetched posts to the array
      state.LearningResource = action.payload;
    },
    [fetchLearningResourceById.rejected]: (state, action) => {
      state.LearningResourceStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [fetchLearningResourcesByCourse.pending]: (state, action) => {
      state.LearningResourcesStatus = "loading";
    },
    [fetchLearningResourcesByCourse.fulfilled]: (state, action) => {
      state.LearningResourcesStatus = "succeeded";
      // Add any fetched posts to the array
      state.LearningResourcesByCourse = action.payload;
    },
    [fetchLearningResourcesByCourse.rejected]: (state, action) => {
      state.LearningResourcesStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewLearningResource.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewLearningResource.fulfilled]: (state, action) => {
      state.LearningResources.push(action.payload);
    },
    [deleteLearningResource.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteLearningResource.fulfilled]: (state, action) => {
    },
    [LearningResourceUpdate.fulfilled]: (state, action) => {
    },
    [LearningResourceUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    reducers: {
      getId: (state, action) => {
        state.id = action.payload;
      },
    },
  },
});

export const {
  getId,
} = LearningResourceSlice.actions;

export default LearningResourceSlice.reducer;

export const selectAllLearningResource = (state) =>
  state.LearningResource.LearningResources;
