import MenuItem from "@material-ui/core/MenuItem";
import React, { useState } from "react";
import Button from "../../components/CustomButtons/Button";

// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import { notification } from "antd";
import {
  deleteAssessmentMethodQuestions,
  fetchAssessmentQuestionsMethods,
  deleteMappingAssessmentMethod,
} from "reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice";
import AddQuestionAssessmentModal from "views/Components/AddQuestionAssessmentModal";
import EditQuestionAssessmentModal from "views/Components/EditQuestionAssessmentModal";
import ErrorModal from "./ErrorModal";
import SuperGeneralDeleteModal from "./SuperGeneralDeleteModal";
import { fetchAssessmentMethodsNotGenerated } from "reduxToolkit/features/assessmentMethod/SliceAssessmentMethodSliceSpec";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function DropDownQuestionAssessment(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openAssessmentQuestions, setOpenAssessmentQuestions] = useState(false);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const handleCloseDeleteAssessmentQuestionsModal = () => {
    setOpenAssessmentQuestions(false);
  };

  const handleOpenDeleteAssessmentQuestionsModal = () => {
    setOpenAssessmentQuestions(true);
  };

  const handleOpenDeleteAssessmentMethodModal = () => {
    setOpen(true);
  };

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleDeleteItem = async (id) => {
    try {
      const resultAction = await dispatch(
        deleteAssessmentMethodQuestions({
          questions_delete_ids: props.selectedRows,
        })
      );
      unwrapResult(resultAction);
      handleClose();
      openNotificationWithIcon("success", `Deleted`);
      dispatch(fetchAssessmentQuestionsMethods(props.course_id));
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const handleDeleteAssessmentMethod = async () => {
    let obj = {
      assessmentMethod_id: props.assessmentMethod_id,
      course_id: props.course_id,
      clo_id: props.clo_id,
    };
    try {
      const resultAction = await dispatch(deleteMappingAssessmentMethod(obj));
      unwrapResult(resultAction);
      handleClose();
      dispatch(fetchAssessmentMethodsNotGenerated(props.course_id));
      openNotificationWithIcon("success", `Deleted`);
      dispatch(fetchAssessmentQuestionsMethods(props.course_id));
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <AddQuestionAssessmentModal
          course={props.course}
          course_id={props.course_id}
          assessmentMethod_id={props.assessmentMethod_id}
          handleCloseAnchor={handleClose}
        />
        <EditQuestionAssessmentModal
          course={props.course}
          course_id={props.course_id}
          assessmentMethod_id={props.assessmentMethod_id}
          disabled={props.disabled}
          selectedRowData={props.selectedRowData}
          handleCloseAnchor={handleClose}
        />
        <MenuItem
          disabled={props.disabledDelete}
          onClick={() => {
            handleOpenDeleteAssessmentQuestionsModal();
            handleClose();
          }}
        >
          <ListItemText primary="Delete Selected Questions" />
        </MenuItem>
        <Link
          to={{
            pathname: `/citrine/assessmentsQuestionsDetails/program/${props.programId}/course/${props.course_id}/assessmentMethod/${props.assessmentMethod_id}/${props.assessmentQuestionsMethod_name}/Insturctors/`,
          }}
        >
          <MenuItem>{t("Edit Instructors")}</MenuItem>
        </Link>
        <MenuItem
          onClick={(id) => {
            handleOpenDeleteAssessmentMethodModal(id);
            handleClose();
          }}
        >
          <ListItemText primary="Delete Assessment Method" />
        </MenuItem>
        <MenuItem
          disabled={!props.loadMarksIsEnabled}
          onClick={() => {
            handleClose();
            props.loadMarksFromLMSHandler();
          }}
        >
          <ListItemText primary="Load Marks from LMS" />
        </MenuItem>
      </StyledMenu>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteItem}
        Element_ID={props.id}
        open={openAssessmentQuestions}
        handleClose={handleCloseDeleteAssessmentQuestionsModal}
      />
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteAssessmentMethod}
        Element_ID={props.assessmentMethod_id}
        open={open}
        handleClose={handleCloseDeleteModal}
      />
    </div>
  );
}
