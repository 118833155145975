import {
  fetchAnnualProgramReportDocuments,
  addAnnualProgramReportDocument,
  deleteAnnualProgramReportDocument,
} from "../../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportDocumentSlice";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Input, message, notification, InputNumber } from "antd";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ErrorModal from "../../Components/ErrorModal";

export default function GenerateReportDocumentModal(props) {
  const { t } = useTranslation();

  const onFinish = async (values) => {
    let data = {
      ...values,
      reports: props.reportId,
      template_name: props.template_name,
      creator: props.currentUser.id,
    };
    props.handleGenerate(data);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          {t("Generate Report Document")}
        </DialogTitle>
        <DialogContent>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("Name")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the program's code",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Form.Item
                  label={t("Version")}
                  name="version"
                  rules={[
                    {
                      required: true,
                      message: "Please input the program's code",
                    },
                  ]}
                >
                  <InputNumber
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </GridItem>
            </GridContainer>

            {/* </FormControl> */}
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item>
                  <GridContainer>
                    <GridItem>
                      <Button
                        color="primary"
                        onClick={() => {
                          props.handleClose();
                        }}
                      >
                        {t("Cancel")}
                      </Button>
                    </GridItem>
                    <GridItem>
                      <Button type="submit" color="primary">
                        {t("Save")}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
