import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

export const fetchDifficultyTypes = createAsyncThunk(
  "difficultyTypes/fetchDifficultyTypes",
  async (_, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().difficultyTypes;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const token = localStorage.getItem("token");
    // try {
    const response = await axios.get(BaseUrl + `/difficultyType/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
    // } catch (err) {
    //     console.log("err",err)
    //     let error = err; // cast the error for access
    //     console.log("error",error)

    //     if (!error.response) {
    //         throw err;
    //     }
    //     return rejectWithValue(error?.response?.data);
    // }
  }
);

export const fetchDifficultyType = createAsyncThunk(
  "difficultyTypes/fetchDifficultyType",
  async (id, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().difficultyTypes;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    const token = localStorage.getItem("token");
    // try {
    const response = await axios.get(BaseUrl + `/difficultyType/${id}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
    // } catch (err) {
    //     let error = err; // cast the error for access
    //     if (!error.response) {
    //         throw err;
    //     }
    //     return rejectWithValue(error?.response?.data);
    // }
  }
);

export const createDifficultyType = createAsyncThunk(
  "difficultyTypes/createDifficultyType",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        BaseUrl + `/difficultyType/create/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDifficultyType = createAsyncThunk(
  "difficultyTypes/updateDifficultyType",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    try {
      const { id, ...fields } = data;

      const response = await axios.put(
        BaseUrl + `/difficultyType/${id}/update/`,
        fields,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDifficultyType = createAsyncThunk(
  "difficultyTypes/deleteDifficultyType",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    const response = await axios.delete(
      BaseUrl + `/difficultyType/${id}/delete/`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return response.data;
  }
);
const DifficultyTypesSlice = createSlice({
  name: "difficultyTypes",
  initialState: {
    difficultyTypes: [],
    difficultyType: null,
    loading: "idle",
    currentRequestId: undefined,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDifficultyTypes.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchDifficultyTypes.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.difficultyTypes = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchDifficultyTypes.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
    builder
      .addCase(fetchDifficultyType.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchDifficultyType.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.difficultyType = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchDifficultyType.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
    builder.addCase(createDifficultyType.rejected, (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });
    builder.addCase(updateDifficultyType.rejected, (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });
    builder.addCase(deleteDifficultyType.rejected, (state, action) => {
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });
  },
});

export default DifficultyTypesSlice.reducer;
