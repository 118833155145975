import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourseById } from '../../../../../reduxToolkit/Course/coursesSpecSlice';

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Link } from 'react-router-dom';

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import { fetchCourseContents } from "reduxToolkit/features/CourseContent/CourseContentSlice";

import DropDownButtonCC from "../../../../Components/DropDownButtonCC";

import { useTranslation } from 'react-i18next';

import { notification } from "antd";
import ErrorModal from "../../../../Components/ErrorModal";

const useStyles = makeStyles(styles);

export default function CourseContentCard({ id }) {
    const { t } = useTranslation();

    const classes = useStyles();
    const dispatch = useDispatch()

    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [handledError, setHandledError] = useState("");
    const [disabledStatus, setDisabledStatus] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [open, setOpen] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const fetchAllCourseContents = async () => {
        try {
            const resultAction = await dispatch(fetchCourseContents(id)).unwrap();
            dispatch(fetchCourseById(id));
        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };

    useEffect(() => {
        fetchAllCourseContents()
        if (localStorage.getItem("CourseState") != "Draft") {
            setDisabledStatus(true);
        }
    }, [])

    const course = useSelector((state) => state.CourseSpec.courseSpecification)
    const CourseContents = useSelector((state) => state.CourseContent.CourseContents)

    return (
        <div>
            <GridContainer>
                <GridItem >
                    {disabledStatus ? <p></p> :
                        <Link to={`/citrine/specification/course/${course && course.id && course.id}/CourseContents/create`}>
                            <Button color="info"
                                className={classes.updateProfileButton}
                            >
                                {t('Add')}
                            </Button>
                        </Link>
                    }
                </GridItem>
            </GridContainer>
            <GridContainer>
                {CourseContents && CourseContents.map(Element => (
                    <GridItem key={Element.id} xs={12} sm={12} md={4} >
                        <Card style={{ height: 200, textAlign: 'start' }}>
                            <CardBody >
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridItem xs={10} sm={10} md={10}>
                                        <h4 className={classes.cardProductTitle}
                                            style={{textAlign: 'start', color: "orange"}}>
                                            <a href="#pablo" onClick={e => e.preventDefault()}
                                               style={{color: "orange"}}>
                                                {t('Name')} : {Element.name}
                                            </a>
                                        </h4>
                                        <h4 className={classes.cardProductTitle} style={{textAlign: 'start'}}>

                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                {t('Contact Hours ')} : {Element.contact_hours}
                                            </a>
                                        </h4>
                                        <h4 className={classes.cardProductTitle} style={{textAlign: 'start'}}>

                                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                                {t('Credit hours ')} : {Element.topic_hours}
                                            </a>
                                        </h4>
                                        {/*<h4 className={classes.cardProductTitle} style={{ textAlign: 'start' }} >
                                            <a href="#pablo" onClick={e => e.preventDefault()} >
                                                {t('Mode ')}  : {Element?.mode_spec_details}
                                            </a>
                                        </h4>*/}
                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2} style={{zIndex: 1}}>
                                        {disabledStatus ? <p></p> :
                                            <DropDownButtonCC CourseContent_ID={Element.id}
                                                              courseID={course && course.id}/>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                ))}
            </GridContainer>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div >
    );

}




