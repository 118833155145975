import { Tabs } from "antd";
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { greyColor } from "assets/jss/material-dashboard-pro-react";
import { Link } from "react-router-dom";
import React from "react";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import StudentAdmissions from "./StudentAdmissions";
import GraduationRequirements from "./GraduationRequirements";
import RecordsOfStudentWorkOrTranscripts from "./RecordsOfStudentWorkOrTranscripts";
import EvaluatingStudentPerformance from "./EvaluatingStudentPerformance";
import TransferStudentsAndTransferCourses from "./TransferStudentsAndTransferCourses";
import AdvisingAndCareerGuidance from "./AdvisingAndCareerGuidance";
import WorkInLieuOfCourses from "./WorkInLieuOfCourses";
import Criterion1Analysis from "./Analysis";

const onChange = (key) => {
  console.log(key);
};

const Criterion1 = ({ data, report, setReportBody }) => (
  console.log("data", data),
  (
    <>
      {/* <p style={{ fontFamily: "Georgia", fontSize: "24px", padding: 10, borderBottom: `1px solid ${greyColor[0]}` }}>SELF-STUDY REPORT</p> */}

      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        items={[
          {
            label: `Student Admissions`,
            key: "1",
            children: (
              <StudentAdmissions
                data={data.StudentAdmissions}
                report={report}
                setReportBody={setReportBody}
              />
            ),
          },
          {
            label: `Evaluating Student Performance`,
            key: "2",
            children: (
              <EvaluatingStudentPerformance
                data={data.EvaluatingStudentPerformance}
                report={report}
                setReportBody={setReportBody}
              />
            ),
          },
          {
            label: `Transfer Students and Transfer Courses`,
            key: "3",
            children: (
              <TransferStudentsAndTransferCourses
                data={data.TransferStudentsAndTransferCourses}
                report={report}
                setReportBody={setReportBody}
              />
            ),
          },
          {
            label: `Advising and Career Guidance`,
            key: "4",
            children: (
              <AdvisingAndCareerGuidance
                data={data.AdvisingAndCareerGuidance}
                report={report}
                setReportBody={setReportBody}
              />
            ),
          },
          {
            label: `Work in Lieu of Courses`,
            key: "5",
            children: (
              <WorkInLieuOfCourses
                data={data.WorkInLieuOfCourses}
                report={report}
                setReportBody={setReportBody}
              />
            ),
          },
          {
            label: `Graduation Requirements`,
            key: "6",
            children: (
              <GraduationRequirements
                data={data.GraduationRequirements}
                report={report}
                setReportBody={setReportBody}
              />
            ),
          },
          {
            label: `Records of Student Work/Transcripts`,
            key: "7",
            children: (
              <RecordsOfStudentWorkOrTranscripts
                data={data.RecordsOfStudentWorkOrTranscripts}
                report={report}
                setReportBody={setReportBody}
              />
            ),
          },
          {
            label: `Analysis`,
            key: "8",
            children: (
              <Criterion1Analysis
                data={data.Analysis}
                report={report}
                setReportBody={setReportBody}
              />
            ),
          },
        ]}
      />
    </>
  )
);
export default Criterion1;
