import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Button from "../../components/CustomButtons/Button";
// nodejs library to set properties for components

// material-ui icons
import MoreVert from "@material-ui/icons/MoreVert";

import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

export default function DropDownButtonUserView(props) {

  const { t } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onchangeAssessmentByExamNavigation = () => {
    if (props.course && props.course.assessment_calculation_method === "clo") {
      history.push(
        `/citrine/courseCoordinatorWorkspace/assessments_list/course/${props.courseID}`
      );
    }
    else if (props.course && props.course.assessment_calculation_method === "question") {
      history.push(
        `/citrine/courseCoordinatorWorkspace/assessmentsQuestions_list/course/${props.courseID}`
      );
    }
  };

  const onchangeAssessmentByCloNavigation = () => {
    if (props.course && props.course.assessment_calculation_method === "clo") {
      history.push(
        `/citrine/courseCoordinatorWorkspace/clos_list/course/${props.courseID}`
      );
    }
    else if (props.course && props.course.assessment_calculation_method === "question") {
      history.push(
        `/citrine/courseCoordinatorWorkspace/closQuestions_list/course/${props.courseID}`
      );
    }
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        variant="contained"
        color="primary"
        onClick={handleClick}
        justIcon
        round
        aria-haspopup="true"
      >
        <MoreVert />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => onchangeAssessmentByExamNavigation()}
          style={{ color: "#AC79B0" }}
        >
          {t("Assessment By Exam")}
        </MenuItem>
        <MenuItem
          onClick={() =>
            onchangeAssessmentByCloNavigation()
          }
          style={{ color: "#AC79B0" }}
        >
          {t("Assessment By Clo")}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
