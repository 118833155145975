// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Select,
} from 'antd'
import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import styles from '../../../../assets/jss//material-dashboard-pro-react/views/extendedTablesStyle'
import {
  grayColor,
  primaryColor,
} from '../../../../assets/jss/material-dashboard-pro-react'
import {
  fetchUniversities,
  fetchUniversityById,
} from '../../../../reduxToolkit/features/university/UniversitiesSlice'
// @material-ui/icons
import { fetchLevels } from 'reduxToolkit/features/level/LevelSlice'
import {
  courseUpdate,
  courseUpdateWeeks,
  fetchCourseById,
} from '../../../../reduxToolkit/Course/coursesSpecSlice'
import { fetchAreas } from '../../../../reduxToolkit/features/area/AreaSpecSlice'
import { fetchSubAreas } from '../../../../reduxToolkit/features/subarea/SubAreaSpecSlice'
import { fetchLocations } from "../../../../reduxToolkit/features/location/LocationSlice";
import ErrorModal from '../../../Components/ErrorModal'
import {fetchRequisites} from "../../../../reduxToolkit/features/Requisite/RequisiteSlice";

const useStyles = makeStyles(styles)

export default function UpdateCourseSpec({ match }) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = match.params
  const { TextArea } = Input
  const { Option } = Select

  //states
  const [areaSpec_ids, setAreaSpec_ids] = useState([])
  const [subAreaSpec_ids, setSubAreaSpec_ids] = useState([])
  const [coordinator_id, setCoordinator_id] = useState('')
  const [field, setField] = useState(false)
  const [mappedLevels, setMappedLevels] = useState([])
  const [
    prerequisites_idsAndCo_requisites_idsFilled,
    setPrerequisites_idsAndCo_requisites_idsFilled,
  ] = useState(true)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  //handlers
  const handleFetchLocations = async () => {
    try {
      await dispatch(fetchLocations()).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeSubArea = (ids) => {
    setSubAreaSpec_ids(ids)
  }

  function onChangeUniversity(value) {
    dispatch(fetchUniversityById(value))
  }

  function onChangeType(value) {
    if (value == 'Field Experience') {
      setField(true)
    } else {
      setField(false)
    }
  }

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  //fetch data
  useEffect(() => {
    dispatch(fetchUniversities())
    dispatch(fetchAreas())
    dispatch(fetchSubAreas())
    dispatch(fetchLevels())
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        setMappedLevels(originalPromiseResult)
      })
    dispatch(fetchRequisites())
    handleFetchLocations();
  }, [])

  //set form data
  useEffect(() => {
    dispatch(fetchCourseById(id))
      .then(unwrapResult)
      .then((course) => {
        if (course.prerequisites_ids.length == 0 || course.co_requisites_ids.length == 0) {
          setPrerequisites_idsAndCo_requisites_idsFilled(false)
        }
        form.setFieldsValue({
          title: course.title,
          code: course.code,
          subAreaSpec_ids: course.subAreaSpec_ids,
          credit_hours: course.credit_hours,
          credit: course.credit,
          description: course.description,
          main_objectives: course.main_objectives,
          student_academic_counseling_and_support:
            course.student_academic_counseling_and_support,
          institution_id: course.institution_id,
          department_ids: course.department_ids,
          college_ids: course.college_ids,
          prerequisites_ids: course.prerequisites_ids,
          co_requisites_ids: course.co_requisites_ids,
          category: course.category,
          program_structure: course.program_structure,
          type: course.type,
          level_id: course.level_id,
          number_of_weeks: course.number_of_weeks,
          number_of_days: course.number_of_days,
          number_of_hours: course.number_of_hours,
          decision_making: course.decision_making,
          follow_up: course.follow_up,
          location: course.locations,
        })
        setCoordinator_id(course.coordinator_id)
        setAreaSpec_ids(course.areaSpec_ids)
        // setSubAreaSpec_ids(course.subAreaSpec_ids)
        if (course.type == 'Field Experience') {
          setField(true)
        } else {
          setField(false)
        }
        dispatch(fetchUniversityById(course.institution_id))
      })
  }, [])

  //global state
  const course = useSelector((state) => state.CourseSpec.courseSpecification)
  const university = useSelector((state) => state.university.university)
  const universities = useSelector((state) => state.university.universities)
  const requisites = useSelector((state) => state.requisites.requisites)
  const areas = useSelector((state) => state.areaSpec.areas)
  const subAreas = useSelector((state) => state.subAreaSpec.subAreas)
  const { locations } = useSelector((state) => state.locations);

  //set areas , subareas and levels
  useEffect(() => {
    // extract preselected subareas objects from subAreaSpec_ids and areas objects
    const preselectedSubAreas = subAreas?.filter((subArea) => subAreaSpec_ids?.includes(subArea.id))
    // extract preselected areaSpec_ids from preselectedSubAreas
    const preAreaSpec_ids =
      preselectedSubAreas?.map((subArea) => {
        return subArea.areaSpec_id
      })
    setAreaSpec_ids(preAreaSpec_ids)
    // extract preselected areas objects from areaSpec_ids and areas objects
    let preselectedAreas =
      areas?.filter((area) => preAreaSpec_ids?.includes(area.id))
    // extract preselected levels from preselected preselectedAreas
    setMappedLevels([... new Set(preselectedAreas?.map(
      (area) =>
        area.levels?.map((level) => level),
    ).flat())])
  }, [subAreaSpec_ids])

  const onFinishFailed = (errorInfo) => {
    message.error('Submit failed!')
  }

  const onFinish = async (values) => {
    let course
    if (
      values.prerequisites_ids?.length &&
      values.co_requisites_ids?.length
    ) {
      course = { ...values, areaSpec_ids, coordinator_id, courseSpec_id: id }
      //co_requisites_ids : [1,2,3,]
    } else {
      if (prerequisites_idsAndCo_requisites_idsFilled) {
        course = { ...values, areaSpec_ids, coordinator_id, courseSpec_id: id }
        //co_requisites_ids : []
      } else {
        const { prerequisites_ids, co_requisites_ids, ...valuesRest } = values
        course = { ...valuesRest, areaSpec_ids, coordinator_id, courseSpec_id: id }
        // without co_requisites_ids
      }
    }
    const resultAction = await dispatch(
      courseUpdate({ id: id, modified: course }),
    )
    dispatch(courseUpdateWeeks(course))
    if (courseUpdate.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const course = resultAction.payload
      openNotificationWithIcon('success', `Edited ${course.title}`)
      history.push('/citrine/CourseSpecificationList')
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  return (
    <div>
      <GridContainer
        style={{
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/CourseSpecificationList/`}>
                {t('Courses List')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/courseSpecDetails/${id}`}>
                {course && course.title && course.title}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link
                to={`/citrine/courseSpecUpdate/${id}`}
              >
                Edit
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${primaryColor[0]}` }}>
                Course General Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Code')}
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={8}>
                    <Form.Item
                      label={t('Title')}
                      name="title"
                      // hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's title",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Credit hours')}
                      name="credit_hours"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's credit hours",
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Credit')}
                      name="credit"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's credit",
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      name="number_of_weeks"
                      label="Number of weeks "
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <InputNumber autoSize={true} style={{ width: '100%' }} />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Sub Area')}
                      name="subAreaSpec_ids"
                      rules={[
                        {
                          required: false,
                          message: "Please input the course's area and subarea",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangeSubArea}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {subAreas &&
                          subAreas.map((subarea) => (
                            <Option key={subarea.id} value={subarea.id}>
                              {`${subarea.areaSpec_detail.name} /  ${subarea.name}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Level')}
                      name="level_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's level",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={(value) => console.log(value)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >

                        {mappedLevels?.map((level) => (
                          <Option key={level.id} value={level.id}>
                            {level.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Category')}
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's type",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        // onChange={onChangeCategory}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value="Required">Required</Option>
                        <Option value="Elective">Elective</Option>
                        <Option value="Selected Elective">Selected Elective</Option>

                        {/* {selectedAreas && selectedAreas.map(area => (
                                                    area.types && area.types.map((type) => (
                                                        <Option key={type.id} value={type.id}>
                                                            {type.name}
                                                        </Option>
                                                    ))))} */}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Prerequisites')}
                      name="prerequisites_ids"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {requisites.map((requisite) => (
                            <Option key={requisite.id} value={requisite.id}>
                              {requisite.requisites}
                            </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Co_requisites')}
                      name="co_requisites_ids"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {requisites.map((requisite) => (
                            <Option key={requisite.id} value={requisite.id}>
                              {requisite.requisites}
                            </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Type')}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's type",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        onChange={onChangeType}
                      // optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      // }
                      >
                        <Option value="Conventional Course">
                          Conventional Course
                        </Option>
                        <Option value="Final Year Project">
                          Final Year Project
                        </Option>
                        <Option value="Senior Design">Senior Design</Option>
                        <Option value="Field Experience">
                          Field Experience
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Program Structure')}
                      name="program_structure"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the course's Program Structure",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value={'Institution Requirements'}>
                          {'Institution Requirements'}
                        </Option>
                        <Option value={'College Requirements'}>
                          {'College Requirements'}
                        </Option>
                        <Option value={'Program Requirements'}>
                          {'Program Requirements'}
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Description')}
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's description",
                        },
                      ]}
                    >
                      <TextArea rows={4} maxLength={100000} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Main objectives')}
                      name="main_objectives"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's main objectives",
                        },
                      ]}
                    >
                      <TextArea rows={4} maxLength={100000} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('student academic counseling and support')}
                      name="student_academic_counseling_and_support"
                      rules={[
                        {
                          required: false,
                          message:
                            "Please input the course's student academic counseling and support",
                        },
                      ]}
                    >
                      <TextArea rows={4} placeholder="" maxLength={100000} />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            {field && (
              <div>
                <h3>
                  <small style={{ color: `${primaryColor[0]}` }}>
                    Dates and times allocation of field experience activities
                  </small>
                </h3>
              </div>
            )}
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            {field && (
              <div>
                <Card>
                  <CardBody>
                    <GridContainer>

                      <GridItem xs={12} sm={12} md={6}>
                        <Form.Item
                          name="number_of_days"
                          label="Number of days"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber
                            autoSize={true}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Form.Item
                          name="number_of_hours"
                          label="Number of hours"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber
                            autoSize={true}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t(
                            'Decision-making procedures for identifying appropriate locations for field experience',
                          )}
                          name="decision_making"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input the course's Decision-making procedures for identifying appropriate locations for field experience",
                            },
                          ]}
                        >
                          <TextArea rows={4} maxLength={100000} />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Supervision and Follow-up Mechanism')}
                          name="follow_up"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input the course's Supervision and Follow-up Mechanism",
                            },
                          ]}
                        >
                          <TextArea rows={4} maxLength={100000} />
                        </Form.Item>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            )}
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${primaryColor[0]}` }}>
                Institutional Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Institution')}
                      name="institution_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's institution",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangeUniversity}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {universities.map((university) => (
                          <Option key={university.id} value={university.id}>
                            {university.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Colleges')}
                      name="college_ids"
                      rules={[
                        {
                          required: false,
                          message: "Please input the program's colleges",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {university?.colleges?.map((college) => (
                            <Option key={college.id} value={college.id}>
                              {college.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item label={t('Departments')} name="department_ids">
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {university?.colleges?.map(
                            (college) =>
                              college.departments?.map((department) => (
                                <Option
                                  key={department.id}
                                  value={department.id}
                                >
                                  {department.name}
                                </Option>
                              )),
                          )}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Campus Location")}
                      name="location"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the course's campus location",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%" }}
                      >
                        {locations?.map((location) => (
                          <Option key={location.id} value={location.id}>
                            {location.location}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>

        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t('Save')}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
