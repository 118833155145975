// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Input, message, notification, Select } from "antd";
import "antd/dist/antd.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { fetchAdmissionRequirements } from "reduxToolkit/features/admissionRequirement/AdmissionRequirementSpecSlice";
import { fetchBranches } from "reduxToolkit/features/branche/BranchesSlice";
import { fetchColleges } from "reduxToolkit/features/college/CollegesSlice";
import { fetchDepartments } from "reduxToolkit/features/department/DepartmentsSlice";
import { fetchGraduationRequirements } from "reduxToolkit/features/graduationRequirement/GraduationRequirementSpecSlice";
import { fetchTransferRequirements as fetchTransferStudentsAcceptanceRequirements } from "../../../../reduxToolkit/features/transferAcceptanceRequirements/TransferStudentsAcceptanceRequirements";
import { fetchTransferRequirements as fetchTransferCreditsAcceptanceRequirements } from "../../../../reduxToolkit/features/transferAcceptanceRequirements/TransferCreditsAcceptanceRequirements";
import {
  addNewKeyWords,
  fetchKeyWords,
} from "reduxToolkit/features/keywords/KeywordSpecSlice";
import {
  grayColor,
  primaryColor,
} from "../../../../assets/jss/material-dashboard-pro-react";
import {
  fetchFieldById,
  fetchFields,
} from "../../../../reduxToolkit/features/field/FieldSpecSlice";
import { addNewProgram } from "../../../../reduxToolkit/features/program/ProgramSpecSlice";
import { fetchSpecializations } from "../../../../reduxToolkit/features/specialization/SpecializationSpecSlice";
import { fetchTotalLevels } from "../../../../reduxToolkit/features/totalLevel/TotalLevelSlice";
import {
  fetchUniversities,
  fetchUniversityById,
} from "../../../../reduxToolkit/features/university/UniversitiesSlice";
import { fetchStaff } from "../../../../reduxToolkit/features/user/UserSlice";
import ErrorModal from "../../../Components/ErrorModal";
import { fetchLocations } from "../../../../reduxToolkit/features/location/LocationSlice";
import {
  fetchSectors,
  createSector,
} from "reduxToolkit/features/sector/SectorSlice";

const useStyles = makeStyles(styles);
const useTitleStyles = makeStyles(titleStyles);

export default function CreateProgramForm() {
  const { t } = useTranslation();

  const [program_degree_name, setProgram_degree_name] = useState("");
  const [awardedDegree, setAwardedDegree] = useState("");
  const [major, setMajor] = useState("");
  const [disclosureLink, setDisclosureLink] = useState("");
  const [workInLieuOfCourses, setWorkInLieuOfCourses] = useState("");

  const [accredited_campus_locations, setAccredited_campus_locations] =
    useState("");
  const [criteria, setCriteria] = useState("");
  const [mission, setMission] = useState("");
  const [goals, setGoals] = useState("");
  const [fieldSpec_id, setFieldSpec_id] = useState("");
  const [specialization_spec, setSpecialization] = useState("");
  const [university_id, setUniversity_id] = useState("");
  const [college_ids, setCollege_ids] = useState([]);
  const [department_ids, setDepartment_ids] = useState([]);
  const [keyWordsSpec_ids, setKeyWordsSpec_ids] = useState([]);
  const [sectors_ids, setSectors_ids] = useState([]);
  const [code, setCode] = useState([]);
  const [admissionRequirementSpec_ids, setAdmissionRequirementSpec_ids] =
    useState([]);
  const [graduationRequirementSpec_ids, setGraduationRequirementSpec_ids] =
    useState([]);
  const [acceptance_requirements, setAcceptance_requirements] = useState([]);
  const [transfer_credits, setTransfer_credits] = useState([]);
  const [category_of_the_program, setCategory_of_the_program] = useState(null);
  const [system_of_study, setSystem_of_study] = useState("coursework");
  const [learningSpecificProcedures, setLearningSpecificProcedures] =
    useState(null);
  const [programHistory, setProgramHistory] = useState(null);
  const [total_level_id, setTotalLevels] = useState([]);
  const [reasons, setReasons] = useState("");
  const [guidance_for_new_students, setGuidance_for_new_students] = useState(
    []
  );
  const [special_support, setSpecial_support] = useState([]);
  const [student_counseling, setStudent_canceling] = useState([]);
  const [branches_ids, setBranche_ids] = useState([]);
  const [assessment_calculation_method, setAssessment_calculation_method] =
    useState("clo");
  const [coordinator_id, setCoordinator_id] = useState();
  const [filteredTotalLevels, setFilteredTotalLevels] = useState([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  let history = useHistory();

  const dispatch = useDispatch();

  const universitiesStatus = useSelector(
    (state) => state.university.universitiesStatus
  );
  const fieldsStatus = useSelector((state) => state.fieldSpec.fieldsStatus);
  const collegesStatus = useSelector((state) => state.college.collegesStatus);
  const listStaff = useSelector((state) => state.user.staffList);
  const departmentsStatus = useSelector(
    (state) => state.department.departmentsStatus
  );
  const branchesStatus = useSelector((state) => state.branches.branchesStatus);
  const specializationsStatus = useSelector(
    (state) => state.specializationSpec.specializationsStatus
  );

  const totalLevelsStatus = useSelector(
    (state) => state.totalLevel.totalLevelsStatus
  );
  const keyWordsStatus = useSelector(
    (state) => state.keyWordSpec.keyWordsStatus
  );
  const staffStatus = useSelector((state) => state.user.staffStatus);
  const universities = useSelector((state) => state.university.universities);
  const fields = useSelector((state) => state.fieldSpec.fields);
  const departments = useSelector((state) => state.department.departments);
  const colleges = useSelector((state) => state.college.colleges);
  const branches = useSelector((state) => state.branches.branches);
  const totalLevels = useSelector((state) => state.totalLevel.totalLevels);
  const keyWords = useSelector((state) => state.keyWordSpec.keyWords);
  const universityStatus = useSelector(
    (state) => state.university.universityStatus
  );
  const admissionRequirementsStatus = useSelector(
    (state) => state.admissionRequirementSpec.admissionRequirementsStatus
  );
  const graduationRequirementsStatus = useSelector(
    (state) => state.graduationRequirementSpec.graduationRequirementsStatus
  );
  const admissionRequirements = useSelector(
    (state) => state.admissionRequirementSpec.admissionRequirements
  );
  const graduationRequirements = useSelector(
    (state) => state.graduationRequirementSpec.graduationRequirements
  );
  const transferStudentsAcceptanceRequirements = useSelector(
    (state) =>
      state.transferStudentsAcceptanceRequirement.acceptanceRequirements
  );
  const transferCreditsAcceptanceRequirements = useSelector(
    (state) => state.transferCreditsAcceptanceRequirement.acceptanceRequirements
  );

  const field = useSelector((state) => state.fieldSpec.field);
  const university = useSelector((state) => state.university.university);
  const { currentUser } = useSelector((state) => state.user);

  const { Option } = Select;
  const selectBefore = (
    <Select defaultValue="http://">
      <Option value="http://">http://</Option>
      <Option value="https://">https://</Option>
    </Select>
  );
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const { TextArea } = Input;

  const handleFetchLocations = async () => {
    try {
      await dispatch(fetchLocations()).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchSectors = async () => {
    try {
      await dispatch(fetchSectors()).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchTransferStudentsAcceptanceRequirements = async () => {
    try {
      await dispatch(fetchTransferStudentsAcceptanceRequirements()).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchTransferCreditsAcceptanceRequirements = async () => {
    try {
      await dispatch(fetchTransferCreditsAcceptanceRequirements()).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (universitiesStatus === "idle") {
      dispatch(fetchUniversities());
    }
    if (fieldsStatus === "idle") {
      dispatch(fetchFields());
    }
    if (specializationsStatus === "idle") {
      dispatch(fetchSpecializations());
    }
    if (departmentsStatus === "idle") {
      dispatch(fetchDepartments());
    }
    if (branchesStatus === "idle") {
      dispatch(fetchBranches());
    }
    if (collegesStatus === "idle") {
      dispatch(fetchColleges());
    }
    if (keyWordsStatus === "idle") {
      dispatch(fetchKeyWords());
    }
    if (admissionRequirementsStatus === "idle") {
      dispatch(fetchAdmissionRequirements());
    }
    if (graduationRequirementsStatus === "idle") {
      dispatch(fetchGraduationRequirements());
    }
    if (totalLevelsStatus === "idle") {
      dispatch(fetchTotalLevels());
    }
    if (staffStatus === "idle") {
      dispatch(fetchStaff());
    }
    handleFetchLocations();

    handleFetchSectors();

    handleFetchTransferStudentsAcceptanceRequirements();

    handleFetchTransferCreditsAcceptanceRequirements();
  }, []);

  const { locations } = useSelector((state) => state.locations);
  const { sectors } = useSelector((state) => state.sectors);

  useEffect(() => {
    setCoordinator_id(currentUser?.staff_details?.[0]?.id);
  }, []);

  const onChangeField_id = (value) => {
    setFieldSpec_id(value);
    dispatch(fetchFieldById(value))
      .then(unwrapResult)
      .then((field) => {
        setFilteredTotalLevels(
          totalLevels?.filter((totalLevel) =>
            totalLevel.fieldSpec_ids.includes(field.id)
          )
        );
      });
  };

  const onChangeCategory = (value) => {
    setCategory_of_the_program(value);
  };

  const onChangeMajor = (value) => {
    setMajor(value);
  };

  const onChangeTotalLevels = (value) => {
    setTotalLevels(value);
  };

  const onChangeSpecialization = (value) => {
    setSpecialization(value);
  };

  const onChangeUniversity = (value) => {
    setUniversity_id(value);
    dispatch(fetchUniversityById(value))
      .unwrap()
      .then((result) => console.log("university", result));
  };

  const onChangeCollege_ids = (value) => {
    setCollege_ids(value);
  };
  const onChangeDepartment_ids = (value) => {
    setDepartment_ids(value);
  };
  const onChangeBranches_ids = (value) => {
    setBranche_ids(value);
  };

  const onChangeAdmissionRequirements_ids = (value) => {
    setAdmissionRequirementSpec_ids(value);
  };

  const onChangeGraduationRequirements_ids = (value) => {
    setGraduationRequirementSpec_ids(value);
  };

  const onChangeTransferStudentsAcceptanceRequirements = (value) => {
    setAcceptance_requirements(value);
  };

  const onChangeTransferCreditsAcceptanceRequirements = (value) => {
    setTransfer_credits(value);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Submit failed!");
  };

  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewProgram({
        coordinator_id,
        program_degree_name,
        degree_awarded: awardedDegree,
        major,
        program_disclosure_link: disclosureLink,
        work_in_lieu_of_courses: workInLieuOfCourses,
        accredited_campus_locations,
        criteria,
        mission,
        goals,
        fieldSpec_id,
        specialization_spec,
        university_id,
        college_ids,
        department_ids,
        branches_ids,
        keyWordsSpec_ids,
        sector: sectors_ids,
        category_of_the_program,
        system_of_study,
        history: programHistory,
        learningSpecificProcedures,
        graduationRequirementSpec_ids,
        admissionRequirementSpec_ids,
        total_level_id,
        code,
        reasons,
        guidance_for_new_students,
        special_support,
        student_counseling,
        assessment_calculation_method,
        acceptance_requirements,
        transfer_credits,
      })
    );
    if (addNewProgram.fulfilled.match(resultAction)) {
      const progarm = resultAction.payload;
      openNotificationWithIcon(
        "success",
        `Added ${progarm.program_degree_name}`
      );
      history.push("/citrine/programSpec");
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  // let children = [];

  // for (let i = 10; i < 36; i++) {
  //   children.push(
  //     <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
  //   );
  // }

  const handleChangeKeywords = (value) => {
    console.log("value", value);

    if (value.length > 0) {
      //if there are selected values, extract the keywords objects related to the selected values from the keyWords array
      let chosenKWObjects = keyWords?.filter((kw) =>
        value.includes(kw.key_words)
      );
      console.log("chosenKWObjects", chosenKWObjects);

      //extract their ids and store them in the keyWordsSpec_ids array. If the keyword does not exist,
      //add it to the keyWords array and then add its id to the keyWordsSpec_ids array.
      let ids = chosenKWObjects.map((el) => {
        return el.id;
      });

      let mapped = keyWords?.map((el) => {
        return el.key_words;
      });

      const key_words = [...value].pop();
      if (mapped.includes(key_words)) {
        setKeyWordsSpec_ids(ids);
      } else {
        dispatch(addNewKeyWords({ key_words }))
          .then(unwrapResult)
          .then((newKeyWord) => {
            console.log("added key word", newKeyWord);
            setKeyWordsSpec_ids([...ids, newKeyWord.id]);
          });
      }
    } else {
      setKeyWordsSpec_ids([]);
    }
  };

  const handleChangeSectors = (value) => {
    if (value.length > 0) {
      let chosenSectorsObjects = sectors?.filter((sector) =>
        value.includes(sector.name)
      );

      let ids = chosenSectorsObjects.map((el) => {
        return el.id;
      });

      let mapped = sectors?.map((el) => {
        return el.name;
      });

      const newSector = [...value].pop();
      if (mapped.includes(newSector)) {
        setSectors_ids(ids);
      } else {
        dispatch(createSector({ name: newSector, code: newSector }))
          .then(unwrapResult)
          .then((newSector) => {
            setSectors_ids([...ids, newSector.id]);
          });
      }
    } else {
      setSectors_ids([]);
    }
  };

  return (
    <div>
      <GridContainer
        style={{
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
      >
        <GridItem>
          <div style={{ height: "50px" }}>
            <Link to={`/citrine/programSpec`}>
              <span className={titleClasses.title}> {t("Programs")} </span>
            </Link>

            <Link to={`/citrine/addProgramSpec`}>
              <span className={titleClasses.title}>
                {" "}
                {` > `}
                {t("Create")}{" "}
              </span>
            </Link>
          </div>
        </GridItem>
      </GridContainer>
      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={12}>
            <h3>
              <small style={{ color: `${primaryColor[0]}` }}>
                Program General Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <Form.Item
                      label={t("Name")}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's name",
                        },
                      ]}
                    >
                      <Input
                        id="program_degree_name"
                        value={program_degree_name}
                        onChange={(event) => {
                          setProgram_degree_name(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t("Code")}
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's code",
                        },
                      ]}
                    >
                      <Input
                        id="code"
                        value={code}
                        onChange={(event) => {
                          setCode(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Awarded degree")}
                      name="awardedDegree"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's awarded degree",
                        },
                      ]}
                    >
                      <Input
                        id="awardedDegree"
                        value={awardedDegree}
                        onChange={(event) => {
                          setAwardedDegree(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Major")}
                      name="major"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's major",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="major"
                        value={major}
                        onChange={onChangeMajor}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option key="bachelor" value="bachelor">
                          Bachelor
                        </Option>
                        <Option key="master" value="master">
                          Master
                        </Option>
                        <Option key="doctor" value="doctor">
                          Doctor
                        </Option>
                        <Option key="associate" value="associate">
                          Associate
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t("Field")}
                      name="fieldSpec_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's field",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="fieldSpec_id"
                        value={fieldSpec_id}
                        onChange={onChangeField_id}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {fields.map((field) => (
                          <Option key={field.id} value={field.id}>
                            {field.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t("Specialization")}
                      name="specialization_spec"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's specialization",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        name="specialization_spec"
                        value={specialization_spec}
                        onChange={onChangeSpecialization}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%" }}
                      >
                        {field &&
                          field.specializationsSpec &&
                          field.specializationsSpec.map((specialization) => (
                            <Option
                              key={specialization.id}
                              value={specialization.id}
                            >
                              {specialization.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t("Total levels")}
                      name="total_level_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's total levels",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="total_level_id"
                        value={total_level_id}
                        onChange={onChangeTotalLevels}
                      >
                        {field &&
                          totalLevels &&
                          filteredTotalLevels &&
                          filteredTotalLevels.map((totalLevel) => (
                            <Option key={totalLevel.id} value={totalLevel.id}>
                              {totalLevel.total_levels}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Category")}
                      name="category_of_the_program"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's category",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="category_of_the_program"
                        value={category_of_the_program}
                        onChange={onChangeCategory}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option key="under-graduate" value="under-graduate">
                          Under Graduate
                        </Option>
                        <Option key="post-graduate" value="post-graduate">
                          Post Graduate
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("System of study")}
                      name="system_of_study"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's system of study",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="system_of_study"
                        value={system_of_study}
                        onChange={(value) => setSystem_of_study(value)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option key="coursework" value="coursework">
                          Coursework
                        </Option>
                        <Option key="thesis" value="thesis">
                          Thesis
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Admission requirements")}
                      name="admissionRequirementSpec_ids"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the program's admission requirements",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        value={admissionRequirementSpec_ids}
                        onChange={onChangeAdmissionRequirements_ids}
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showArrow
                      >
                        {admissionRequirements.map((req) => (
                          <Option key={req.id} value={req.id}>
                            {req.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Graduation requirements")}
                      name="graduationRequirementSpec_ids"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the program's graduation requirements",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        value={graduationRequirementSpec_ids}
                        onChange={onChangeGraduationRequirements_ids}
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showArrow
                      >
                        {graduationRequirements.map((req) => (
                          <Option key={req.id} value={req.id}>
                            {req.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Transfer students acceptance requirements")}
                      name="acceptance_requirements"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the program's transfer students acceptance requirements",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        value={acceptance_requirements}
                        onChange={
                          onChangeTransferStudentsAcceptanceRequirements
                        }
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showArrow
                      >
                        {transferStudentsAcceptanceRequirements.map((req) => (
                          <Option key={req.id} value={req.id}>
                            {req.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Transfer credits acceptance requirements")}
                      name="transfer_credits"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the program's transfer credits acceptance requirements",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        value={transfer_credits}
                        onChange={onChangeTransferCreditsAcceptanceRequirements}
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showArrow
                      >
                        {transferCreditsAcceptanceRequirements.map((req) => (
                          <Option key={req.id} value={req.id}>
                            {req.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Sectors")}
                      name="sectors_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's sectors",
                        },
                      ]}
                    >
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Sectors"
                        onChange={handleChangeSectors}
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {sectors?.map((sector) => (
                          <Option key={sector.id} value={sector.name}>
                            {sector.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Keywords")}
                      name="keyWordsSpec_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's keyWords",
                        },
                      ]}
                    >
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Keywords"
                        onChange={handleChangeKeywords}
                        optionFilterProp="children"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {keyWords &&
                          keyWords.map((keyWord) => (
                            <Option key={keyWord.id} value={keyWord.key_words}>
                              {keyWord.key_words}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <h3>
              <small style={{ color: `${primaryColor[0]}` }}>
                Criteria, Mission & Goals
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Reasons for Establishing the Program")}
                      name="reasons"
                      rules={[{ required: true }]}
                    >
                      <TextArea
                        rows={4}
                        placeholder=""
                        maxLength={100000}
                        id="reasons"
                        value={reasons}
                        onChange={(event) => {
                          setReasons(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Criteria")}
                      name="criteria"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's criteria",
                        },
                      ]}
                    >
                      <Input.TextArea
                        maxLength={1000}
                        id="criteria"
                        value={criteria}
                        onChange={(event) => {
                          setCriteria(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Mission")}
                      name="mission"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's mission",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder=""
                        maxLength={100000}
                        id="mission"
                        value={mission}
                        onChange={(event) => {
                          setMission(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Goals")}
                      name="goals"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's goals",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder=""
                        maxLength={100000}
                        id="goals"
                        value={goals}
                        onChange={(event) => {
                          setGoals(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("History")}
                      name="history"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's history",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder=""
                        maxLength={100000}
                        id="history"
                        value={programHistory}
                        onChange={(event) => {
                          setProgramHistory(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Learning specific procedures")}
                      name="learningSpecificProcedures"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the program's learning specific procedures",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder=""
                        maxLength={100000}
                        id="learningSpecificProcedures"
                        value={learningSpecificProcedures}
                        onChange={(event) => {
                          setLearningSpecificProcedures(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Work in lieu of courses")}
                      name="workInLieuOfCourses"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "If this program allows for work in lieu of courses, state it",
                      //   },
                      // ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder=""
                        maxLength={100000}
                        id="workInLieuOfCourses"
                        value={workInLieuOfCourses}
                        onChange={(event) => {
                          setWorkInLieuOfCourses(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <h3>
              <small style={{ color: `${primaryColor[0]}` }}>
                Institutional Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("University")}
                      name="university_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's university",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="university_id"
                        value={university_id}
                        onChange={onChangeUniversity}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {universities.map((university) => (
                          <Option key={university.id} value={university.id}>
                            {university.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Colleges")}
                      name="college_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's colleges",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="college_ids"
                        value={college_ids}
                        onChange={onChangeCollege_ids}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {university &&
                          university.colleges &&
                          university.colleges.map((college) => (
                            <Option key={college.id} value={college.id}>
                              {college.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item label={t("Departments")} name="department_ids">
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="department_ids"
                        value={department_ids}
                        onChange={onChangeDepartment_ids}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {university &&
                          university.colleges &&
                          university.colleges.map(
                            (college) =>
                              college &&
                              college.departments &&
                              college.departments.map((department) => (
                                <Option
                                  key={department.id}
                                  value={department.id}
                                >
                                  {department.name}
                                </Option>
                              ))
                          )}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t("Branches Offering the Program")}
                      name="branches_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's branches",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="branches_ids"
                        value={branches_ids}
                        onChange={onChangeBranches_ids}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {branches &&
                          branches.map((branche) => (
                            <Option key={branche.id} value={branche.id}>
                              {branche.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Campus location")}
                      name="accredited_campus_locations"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the program's accredited_campus_locations",
                        },
                      ]}
                    >
                      <Select
                        id="accredited_campus_locations"
                        showSearch
                        optionFilterProp="children"
                        name="accredited_campus_locations"
                        value={accredited_campus_locations}
                        onChange={(value) =>
                          setAccredited_campus_locations(value)
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%" }}
                      >
                        {locations?.map((location) => (
                          <Option key={location.id} value={location.id}>
                            {location.location}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Disclosure link")}
                      name="DisclosureLink"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please provide the program's disclosure link",
                        },
                      ]}
                    >
                      <Input
                        id="DisclosureLink"
                        value={disclosureLink}
                        onChange={(event) => {
                          setDisclosureLink(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <h3>
              <small style={{ color: `${primaryColor[0]}` }}>
                Student Support
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t(
                        "Guidance and Orientation Programs for New Students "
                      )}
                      name="guidance_for_new_students"
                      rules={[{ required: true }]}
                    >
                      <TextArea
                        rows={4}
                        placeholder=""
                        maxLength={1000}
                        id="guidance_for_new_students"
                        value={guidance_for_new_students}
                        onChange={(event) => {
                          setGuidance_for_new_students(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Student Counseling Services ")}
                      name="student_counseling"
                      rules={[
                        {
                          required: true,
                          message: "Please input Student Counseling Services",
                        },
                      ]}
                    >
                      <TextArea
                        id="student_counseling"
                        value={student_counseling}
                        onChange={(event) => {
                          setStudent_canceling(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Special Support ")}
                      name="special_support"
                      rules={[
                        {
                          required: true,
                          message: "Please input Special Support",
                        },
                      ]}
                    >
                      <TextArea
                        id="special_support"
                        value={special_support}
                        defaultValue={""}
                        onChange={(event) => {
                          setSpecial_support(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Form.Item>
              <GridContainer justify="flex-end">
                <GridItem>
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push("/citrine/programSpec");
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                </GridItem>
                <GridItem>
                  <Button type="submit" color="primary">
                    {t("Save")}
                  </Button>
                </GridItem>
              </GridContainer>
            </Form.Item>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
