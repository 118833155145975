import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import { primaryColor } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const FloatingEditButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Fab 
    // color="primary" 
    style={{ 
        backgroundColor: primaryColor[0], // Assuming you want primary color for this button, 
        color: 'white' // Assuming you want black text for better contrast
      }}
    className={classes.fab} onClick={onClick}>
      <SaveIcon />
    </Fab>
  );
};

export default FloatingEditButton;