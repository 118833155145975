import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReports,
  deleteReport,
} from "../../../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";
import { fetchStaff } from "reduxToolkit/features/user/UserSlice";
import { fetchGroupStatesByReport } from "reduxToolkit/features/groupState/GroupStateSlice";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, notification } from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import DropDownButtonAnnualProgramReport from "../../../Components/DropDownButtonAnnualProgramReport";
import ErrorModal from "../../../Components/ErrorModal";
import Info from "components/Typography/Info.js";
import DNDBoard from "components/dnd/Board";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import SuperGeneralDeleteModal from "views/Components/SuperGeneralDeleteModal";

export default function SSRList(props) {
  let initialBoard = {
    columns: [],
  };
  let content;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { type } = useParams();
  const [board, setBoard] = useState(initialBoard);

  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);

  const handleOpenDeleteModal = (reportId) => {
    setReportToDelete(reportId);
    setDeleteModalIsOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalIsOpen(false);
    setReportToDelete(null);
  };

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleDeleteReport = async (id) => {
    if (!id) return;

    try {
      const resultAction = await dispatch(deleteReport(id));
      unwrapResult(resultAction);
      handleCloseDeleteModal();
      openNotificationWithIcon("success", `Deleted`);
      orderReportsAccordingToStates();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    } finally {
      setReportToDelete(null);
    }
  };

  function deepCopy(obj) {
    let newObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        newObj[key] = deepCopy(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }

  const orderReportsAccordingToStates = () => {
    dispatch(fetchGroupStatesByReport("APReport"))
      .then(unwrapResult)
      .then((states) => {
        if (states?.columns?.length > 0) {
          draftBoard = deepCopy(states);
          dispatch(fetchReports())
            .then(unwrapResult)
            .then((reports) => {
              if (type !== "ABET") {
                reports = [];
              }
              if (reports?.length > 0) {
                reports
                  .filter(
                    (report) =>
                      report.body === "ABET" &&
                      report.type === "Self Study Report"
                  )
                  .forEach((report) => {
                    let stateObject = report.state_object;
                    let column = draftBoard?.columns?.find(
                      (col) => col.title === stateObject
                    );
                    if (column && column.cards) {
                      // Ensure cards array exists
                      column.cards.push(report);
                    }
                  });
              }
              setBoard(draftBoard);
            })
            .catch((error) => {
              setHandledError(error);
              handleOpenErrorModal();
            });
        }
      })
      .catch((error) => {
        setHandledError(error);
        handleOpenErrorModal();
      });
  };

  //   const handleDragEnd = async (result) => {
  //     const { destination, source, draggableId } = result;

  //     if (!destination || source.droppableId == destination.droppableId) return;

  //     const destinationState = board.columns.find(
  //       (state) => state.id == destination.droppableId
  //     );
  //     const report = AnnualProgramReports.find(
  //       (report) => report.id == draggableId
  //     );
  //     const state_object = destinationState?.title;

  //     if (state_object && report) {
  //       // Dispatch the update request

  //       try {
  //         dispatch(
  //           UpdateAnnualProgramReport({
  //             id: report.id,
  //             updatedAnnualProgramReport: { ...report, state_object },
  //           })
  //         ).unwrap();
  //         // handle result here
  //         // If the request is successful, update the state
  //         const updatedColumns = board.columns.map((column) => {
  //           if (column.id == source.droppableId) {
  //             // Remove the report from the source column
  //             column.cards = column.cards.filter((card) => card.id !== report.id);
  //           } else if (column.id == destination.droppableId) {
  //             // Add the report to the destination column
  //             column.cards.push(report);
  //           }
  //           return column;
  //         });

  //         // Update the state with the new columns structure
  //         setBoard((prevBoard) => ({ ...prevBoard, columns: updatedColumns }));
  //       } catch (rejectedValueOrSerializedError) {
  //         // handle error here
  //         setHandledError(rejectedValueOrSerializedError);
  //         handleOpenErrorModal();
  //       }
  //     }
  //   };

  const renderCardContent = (report, showDropDown) => (
    <div>
      <GridContainer alignItems="baseline" style={{ minHeight: "80px" }}>
        <GridItem
          key={Element.id}
          xs={showDropDown ? 12 : 12}
          sm={showDropDown ? 9 : 12}
          md={showDropDown ? 9 : 12}
          lg={showDropDown ? 10 : 12}
        >
          <Link to={`/citrine/reports/${type}/SSR/${report.id}`}>
            <strong>
              <p style={{ marginTop: "10px" }}>
                {report?.resource_details?.program_degree_name}
              </p>
            </strong>
          </Link>
          {/* {Moment(report.date).format("YYYY-MM-DD")} */}
        </GridItem>
        {showDropDown && (
          <GridItem key={Element.id} xs={12} sm={3} md={3} lg={2}>
            <CustomDropdown
              iconButtonProps={{
                size: "small",
                style: {
                  /* other styles if needed */
                },
              }}
              isButtonIcon
              dropdownList={[
                {
                  text: "Delete",
                  onClickHandler() {
                    handleOpenDeleteModal(report.id);
                  },
                },
              ]}
            />
          </GridItem>
        )}
      </GridContainer>
      <p style={{ fontSize: "12px" }}>{`Version: ${report?.version}`}</p>
    </div>
  );

  let draftBoard;
  useEffect(() => {
    orderReportsAccordingToStates();
    dispatch(fetchStaff());
  }, []);

  const staffList = useSelector((state) => state.user.staffList);

  const ReportsStatus = useSelector(
    (state) => state.AnnualProgramReport.ReportsStatus
  );

  const APReport_StateBoard = useSelector(
    (state) => state.groupState.groupStatesByReport
  );
  const groupStatesByReportStatus = useSelector(
    (state) => state.groupState.groupStatesByReportStatus
  );

  if (ReportsStatus === "loading" || groupStatesByReportStatus === "loading") {
    content = (
      <GridContainer
        style={{ height: "100%" }}
        justifyContent="center"
        alignItems="center"
      >
        <GridItem>
          <CircularProgress color="inherit" />
        </GridItem>
      </GridContainer>
    );
  }

  if (ReportsStatus === "failed" || groupStatesByReportStatus === "failed") {
    return (
      <GridContainer justifyContent="center" alignItems="center">
        <GridItem style={{ width: "60%" }}>
          <Info>
            Oops! Something went wrong. We're having trouble retrieving the
            annual programs reports. Please try again later. If the issue
            persists, contact support for assistance.
          </Info>
        </GridItem>
      </GridContainer>
    );
  }

  if (groupStatesByReportStatus === "succeeded") {
    if (APReport_StateBoard?.columns?.length == 0) {
      content = (
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem style={{ width: "60%" }}>
            <Info>
              Oops! Something went wrong. We could not find any annual programs
              reports states. Please add the needed states in the configuration
              menu or contact support for assistance.
            </Info>
          </GridItem>
        </GridContainer>
      );
    }

    if (ReportsStatus === "succeeded") {
      content = (
        <DNDBoard
          columns={board}
          renderCardContent={renderCardContent}
          //   handleDragEnd={handleDragEnd}
        />
      );
    }
  }

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={`/citrine/reports/${type}/SSR`}>
            {t("Self Study Report")}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      {content}
      <SuperGeneralDeleteModal
        delete_Element={handleDeleteReport}
        Element_ID={reportToDelete}
        open={deleteModalIsOpen}
        handleClose={handleCloseDeleteModal}
      />
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  );
}
