import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";

// core components
/// test
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import "assets/css/styles.css";

import "antd/dist/antd.css";
import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import moment from "moment";

import { useTranslation } from "react-i18next";
import { Form, Input, Select, notification, DatePicker, Checkbox } from "antd";
import ErrorModal from "../../../../Components/ErrorModal";

import AddIcon from "@material-ui/icons/Add";
import buttonStyles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

import {
  addNewEducation,
  fetchEducationsByPersonId,
} from "../../../../../reduxToolkit/features/Education/EducationSlice";

import {
  addNewDifficultiesAndChallenges,
  fetchDifficultiesAndChallengesByCourseId,
} from "../../../../../reduxToolkit/features/DifficultiesAndChallenges/DifficultiesAndChallengesSlice";
import { fetchDifficultyTypes } from "../../../../../reduxToolkit/features/difficultyType/DifficultyTypeSlice";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 0,
  },
};

const useTitleStyles = makeStyles(titleStyles);
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));
const useButtonStyles = makeStyles(buttonStyles);

export default function AddDifficultiesAndChallengesModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const buttonClasses = useButtonStyles();
  const anchorRef = React.useRef(null);

  const { Option } = Select;
  const { TextArea } = Input;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [course_id, setCourse_id] = React.useState(props.idCourse);

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleFetchDifficultyTypes = async () => {
    try {
      await dispatch(fetchDifficultyTypes()).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  useEffect(() => {
    handleFetchDifficultyTypes();
  }, []);

  const { difficultyTypes, error, loading } = useSelector(
    (state) => state.difficultyTypes
  );
  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewDifficultiesAndChallenges({
        ...values,
        course_id,
      })
    );

    if (addNewDifficultiesAndChallenges.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Add successfully`);
      dispatch(fetchDifficultiesAndChallengesByCourseId(course_id));
      form.resetFields();
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        form.resetFields();
        handleClose();
      }
    }
  };

  return (
    <div>
      <Button
        color="primary"
        round
        ref={anchorRef}
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={(event) => {
          handleClickOpen(event);
        }}
        className={`${buttonClasses.justIcon} ${buttonClasses.round}`}
      >
        <AddIcon />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          <center>{t("Add Difficulties and Challenges")}</center>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    {
                      required: true,
                      message: "Please input the type field.",
                    },
                  ]}
                >
                  {/* {props.type.toLowerCase().includes("conventional course") ? (
                    <Select
                      placeholder="Select Skills "
                      dropdownStyle={{ zIndex: 10000 }}
                    >
                      <Option value={"Administrative Issues"}>
                        Administrative Issues
                      </Option>
                      <Option value={"Learning Resources"}>
                        Learning Resources
                      </Option>
                      <Option value={"Facilities"}>Facilities</Option>
                    </Select>
                  ) : (
                    <Select
                      placeholder="Select Skills "
                      dropdownStyle={{ zIndex: 10000 }}
                    >
                      <Option value={"Administrative Issues"}>
                        Administrative Issues
                      </Option>
                      <Option value={"Supervision"}>Supervision</Option>
                      <Option value={"Students"}>Students</Option>
                    </Select>
                  )} */}
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    name="type"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownStyle={{ zIndex: 10000 }}
                  >
                    {difficultyTypes?.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.type}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="difficulties_and_Challenges"
                  label={t("Difficulties And Challenges")}
                  rules={[
                    {
                      required: true,
                      message:
                        "Please input the difficulties_and_challenges field.",
                    },
                  ]}
                >
                  <TextArea rows={2} />
                </Form.Item>
                
                <Form.Item
                  name="consequences"
                  label={t("Consequences ")}
                  rules={[
                    {
                      required: true,
                      message: "Please input the consequences  field.",
                    },
                  ]}
                >
                  <TextArea rows={2} />
                </Form.Item>

                <Form.Item
                  name="actions_taken"
                  label={t("Actions Taken ")}
                  rules={[
                    {
                      required: true,
                      message: "Please input the actions_taken  field.",
                    },
                  ]}
                >
                  <TextArea rows={2} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ marginRight: "20px" }}
                    color="danger"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" color="info" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
