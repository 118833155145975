import { configureStore } from "@reduxjs/toolkit";
import DistributionOfGradesReducer from "reduxToolkit/features/DistributionOfGrades/DistributionOfGradesSlice";
import TeachingStrategieReducer from "reduxToolkit/features/TeachingStrategies/TeachingStrategiesSlice";
//kpi improvement
import KpiStandard from "reduxToolkit/features/kpiStandardConfigType/kpiStandardConfigTypeSlice";
import KpiReportReducer from "../reduxToolkit/features/KpiReport/KpiReportSlice";
import CoursesSpecReducer from "./Course/coursesSpecSlice";
import academicYearReducer from "./features/academicYear/AcademicYearsSlice";
import academicYearEventReducer from "./features/academicYearEvents/academicYearEventsSlice.js";
import authorityReducer from "./features/AccreditationAuthority/AuthoritySlice";
import ActionTypeReducer from "./features/actionType/ActionTypeSlice.js";
import accreditationDateReducer from "./features/AccreditationDates/AccreditationDatesSlice";
import ActionPlanReducer from "./features/ActionPlan/ActionPlanSlice";
import ActionRecommendationReducer from "./features/ActionRecommendation/ActionRecommendationSlice";
import ActivitiesAssessmentMethodReducer from "./features/ActivitiesAssessmentMethod/ActivitiesAssessmentMethodSlice";
import ActivitiesAssessmentMethodSpecReducer from "./features/ActivitiesAssessmentMethod/ActivitiesAssessmentMethodSpecSlice";
import ActivityReducer from "./features/Activitiy/ActivitiesSlice";
import ActualLearningHourConfigReducer from "./features/ActualLearningHours/ActualLearningConfigSlice";
import ActualLearningHourConfigTypeReducer from "./features/ActualLearningHours/ActualLearningHourConfigTypeSlice";
import ActualLearningHourReducer from "./features/ActualLearningHours/ActualLearningHourSlice";
import ActualLearningHourSpecReducer from "./features/ActualLearningHours/ActualLearningHoursSpecSlice";
import AdmissionRequirementReducer from "./features/admissionRequirement/AdmissionRequirementSlice";
import AdmissionRequirementSpecReducer from "./features/admissionRequirement/AdmissionRequirementSpecSlice";
import AlignmentOfCLOWithTMAndAMReducer from "./features/AlignmentOfCLOWithTMAndAM/AlignmentOfCLOWithTMAndAMSlice";
import AnnualProgramAnalysisReducer from "./features/annualProgramAnalysis/annualProgramAnalysisSlice";
import AnnualProgramReportDocumentReducer from "./features/AnnualProgramReport/AnnualProgramReportDocumentSlice";
//AnnualProgramReportReducer
import AnnualProgramReportReducer from "./features/AnnualProgramReport/AnnualProgramReportSlice";
import areaReducer from "./features/area/AreaSlice";
import areaSpecReducer from "./features/area/AreaSpecSlice";
//Assessments
import assessmentReducer from "./features/assessment/AssessmentSlice";
import AssessmentMethodsReducer from "./features/assessmentMethod/AssessmentMethodsSlice";
import QuestionsReducer from "./features/assessmentMethod/QuestionSlice";
import assessmentMethodReducer from "./features/assessmentMethod/SliceAssessmentMethodSliceSpec";
import assessmentModeReducer from "./features/assessmentMode/AssessmentModeSlice";
import assessmentModeSpecReducer from "./features/assessmentMode/AssessmentModeSpecSlice";
import AssessmentResponsibilitiesReducer from "./features/AssessmentResponsibilities/AssessmentResponsibilitiesSlice";
import AssessmentTasksForStudentReducer from "./features/AssessmentTasksForStudent/AssessmentTasksForStudentSlice";
import attachmentReducer from "./features/attachment/AttachmentsSlice";
import AuthorityConfigReducer from "./features/AuthorityConfig/AuthorityConfigSlice";
import branchesReducer from "./features/branche/BranchesSlice";
import calculNotesReducer from "./features/CalculNotes/CalculNotesSlice";
import EvidenceTypeReducer from "./features/EvidenceType/EvidenceTypeSlice";
// campaign
import CampaignReducer from "./features/campaign/CampaignsSlice";
//camunda
import CamundaReducer from "./features/camunda/CamundaSlice";
import categoryReducer from "./features/category/CategorySlice";
// specifications : course
import cloReducer from "./features/CLO/CloSlice";
import cloSpecReducer from "./features/CLO/CloSpecSlice";
import CLOSpecWeightReducer from "./features/CLO/WeightCloSpecSlice";
import CLOWeightReducer from "./features/CLO/CLOWeightSlice";
import CodeLevelReducer from "./features/CodeLevel/CodeLevelConfigSlice";
// configurations
import collegeReducer from "./features/college/CollegesSlice";
import CommentsReducer from "./features/Comments/CommentsSlice";
import CommuntyServicesReducer from "./features/CommunityServices/CommunityServicesSlice";
import ConclusionsDifficultiesReducer from "./features/ConclusionsDifficulties/ConclusionsDifficultiesSlice";
import CoursesReducer from "./features/course/CoursesSlice";
import IndexResearchConfigReducer from "./features/indexResearchConfig/indexResearchConfigSlice";
import InternationalReasearchConfigReducer from "./features/InternationalSearchConfig/internationalResearchConfigSlice";
import PublisherConfigReducer from "./features/Publisher/publisherSlice";
import ResearchDirectionReducer from "./features/ResearchDirection/researchDirectionSlice";
import RolesConfigReducer from "./features/RolesConfig/RolesConfigSlice";
import StandardConfigReducer from "./features/standardConfig/StandardConfigSlice";
import surveyTemplatesTypeConfigReducer from "./features/surveyTemplateTypeConfig/SurveyTemplateTypeConfigSlice";
import TypesConfigReducer from "./features/TypesConfig/TypesConfigSlice";
import UtilizedResearchConfigReducer from "./features/UtilizedRsearch/utilizedResearchSlice";

//Assessment Methods
import courseAssessmentMethodReducer from "./features/courseAssessmentMethod/courseAssessmentMethodSlice";
import courseAssessmentMethodSpecReducer from "./features/courseAssessmentMethod/courseAssessmentMethodSpecSlice";
import CourseContactReducer from "./features/CourseContact/CourseContactSlice";
import CourseContentReducer from "./features/CourseContent/CourseContentSlice";
import CourseEvaluationReducer from "./features/CourseEvaluation/CourseEvaluationSlice";
import CourseReportReducer from "./features/CourseImprovement/courseImprovementSlice";
import CourseOutlineReducer from "./features/CourseOutline/CourseOutlineSlice";
import CourseQualityEvaluationReducer from "./features/CourseQualityEvaluation/CourseQualityEvaluationSlice";
import CourseQualityEvaluationSpecReducer from "./features/CourseQualityEvaluation/CourseQualityEvaluationSpecSlice";
import CourseReportDocumentReducer from "./features/CourseReportDocument/CourseReportDocumentSlice";
import CourseSpecReportDocumentReducer from "./features/CourseSpecificationReportDocument/CourseSpecificationReportDocumentSlice";
import CourseSyllabusReportDocumentReducer from "./features/CourseSyllabusReportDocument/CourseSyllabusReportDocumentSlice";
//dashboard
import dashboardDataReducer from "./features/Dashboard/DashboardsSlice";
import DatesAndTimesAllocationReducer from "./features/DatesAndTimesAllocation/DatesAndTimesAllocationSlice";
import departmentReducer from "./features/department/DepartmentsSlice";
import DifficultiesAndChallengesReducer from "./features/DifficultiesAndChallenges/DifficultiesAndChallengesSlice";
// cvTech
import EducationReducer from "./features/Education/EducationSlice";
import exitPointsConfigReducer from "./features/exitPoints/ExitPointsConfigSlice";
import exitPointsReducer from "./features/exitPoints/ExitPointsSlice";
import FacilitiesRequiredConfigReducer from "./features/FacilitiesRequired/FacilitiesRequiredConfigSlice";
import FacilitiesRequiredReducer from "./features/FacilitiesRequired/FacilitiesRequiredSlice";
// Activities
import facultyActivitiesReducer from "./features/FacultyActivity/FacultyActivitySlice.jsx";
import fieldSpecReducer from "./features/field/FieldSpecSlice";
import fieldReducer from "./features/field/FieldsSlice";
import FieldExperienceLocationReducer from "./features/FieldExperienceLocations/FieldExperienceLocationsSlice";
import GAIndicatorReducer from "./features/GAIndicators/GAIndicatorSlice";
import GAIndicatorSpecReducer from "./features/GAIndicators/GAIndicatorSpecSlice";
//GA Improvement
import GAReportReducer from "./features/GAReport/GAResportSlice";
import GAReportDocumentReducer from "./features/GAReportDocument/GAReportDocumentSlice";
import GA_PLOMappingRecuder from "./features/GraduateAttribute/GA_PLOMappingSlice.jsx";
import GraduateAttributeRecuder from "./features/GraduateAttribute/GraduateAttributeSlice";
import GraduateAttributeSpecRecuder from "./features/GraduateAttribute/GraduateAttributeSpecSlice";
import graduationRequirementReducer from "./features/graduationRequirement/GraduationRequirementSlice";
import graduationRequirementSpecReducer from "./features/graduationRequirement/GraduationRequirementSpecSlice";
import groupReducer from "./features/group/GroupSlice";
import groupeAssetmentReducer from "./features/groupeAssessment/groupeAssessmentSlice.js";
import groupStateReducer from "./features/groupState/GroupStateSlice";
import groupStateActionsReducer from "./features/groupStateActions/GroupStateActionsSlice";
import HTMLTemplateReducer from "./features/HTMLTemplate/HTMLTemplateSlice";
import jobsReducer from "./features/jobs/JobsSlice";
import keywordReducer from "./features/keywords/KeywordSlice";
import keywordSpecReducer from "./features/keywords/KeywordSpecSlice";
import KPICollegeReducer from "./features/KPI/KPICollegeSlice";
import KPIConfigReducer from "./features/KPI/KPIConfigSlice";
import KPIDepartmentReducer from "./features/KPI/KPIDepartmentSlice";
//KPI
import KPIProgramReducer from "./features/KPI/KPIProgramSlice";
import KPISpecReducer from "./features/KPI/KPISpecSlice";
import KPIUniversityReducer from "./features/KPI/KPIUniversitySlice";
import KPICategoryReducer from "./features/KPICategory/KPICategorySlice";
import KPIReportDocumentReducer from "./features/KpiReportDocument/KpiReportDocument";
// others
import languageReducer from "./features/language/LanguageSlice";
import LanguagePersonReducer from "./features/LanguagePerson/LanguagePersonSlice";
import LearningResourceReducer from "./features/LearningResource/LearningResourceSlice";
import levelReducer from "./features/level/LevelSlice";
import LevelPlosReducer from "./features/levelPlo/LevelPloSlice";
import MailReducer from "./features/Mail/MailSlice";
import MailTemplateReducer from "./features/MailTemplate/MailTemplateSlice";
import majorTracksReducer from "./features/majorTracks/MajorTracksSlice";
import menuReducer from "./features/menu/MenuSlice";
import modelReducer from "./features/model/ModelSlice";
import SectionReducer from "./features/Section/SectionSlice";
import modelGroupPermissionReducer from "./features/modelGroupPermission/ModelGroupPermissionSlice";
import ModeOfInstructionReducer from "./features/ModeOfInstruction/ModeOfInstructionSlice";
import ModeOfInstructionConfigReducer from "./features/modeOfInstructionConfig/ModeOfInstructionConfigSlice";
import OutGoingMailServerReducer from "./features/outgoingMailServer/OutGoingMailServerSlice";
import peoSpecReducer from "./features/PEO/PEOSpecSlice";
import peoReducer from "./features/PEO/PEOsSlice";
import peoIndicatorSpecReducer from "./features/PEOIndicator/PEOIndicatorSpecSlice";
import peoIndicatorReducer from "./features/PEOIndicator/PEOIndicatorsSlice";
import ApprovalLevelReducer from "./features/Configuration/ApprovalLevel/ApprovalLevelSlice.jsx";
import ActionApprovalReducer from "./features/ActionApproval/ActionApprovalSlice";
import ActionApprovalProcessReducer from "./features/ActionApprovalProcess/ActionApprovalProcessSlice";
import StateNamesReducer from "./features/StateNames/StateNamesSlice";
import stepConfigReducer from "./features/stepConfig/StepsConfig";
// peo improvement
import PeoReportReducer from "./features/PeoReport/PeoReportSlice";
import PEOReportDocumentReducer from "./features/PEOReportDocument/PEOReportDocumentSlice";
import personReducer from "./features/person/PersonsSlice";
import plannedAccreditationDateReducer from "./features/PlannedAccreditationDates/PlannedAccreditationDatesSlice";
import plannedActivityReducer from "./features/PlannedActivities/PlannedActivitiesSlice";
import patypeReducer from "./features/PlannedActivities/PlannedActivitiesTypeSlice";
import ploSpecReducer from "./features/PLO/PLOSpecSlice";
import ploReducer from "./features/PLO/PLOsSlice";
import PLOWeightReducer from "./features/PLO/PLOWeightSlice";
import ploIndicatorSpecReducer from "./features/PLOIndicator/PLOIndicatorSpecSlice";
import ploIndicatorReducer from "./features/PLOIndicator/PLOIndicatorsSlice";
import PLOReportDocumentReducer from "./features/PLOReportDocument/PLOReportDocumentSlice";
// plo Improvement
import PloReportReducer from "./features/PloReporte/PloReporteSlice";
import ProfessionalDevelopmentActivitesReducer from "./features/ProfessionalDevelopment/ProfessionalDevelopmentSlice";
import ProfessionalExperienceReducer from "./features/ProfessionalExperience/ProfessionalExperienceSlice";
import programSpecReducer from "./features/program/ProgramSpecSlice";
import programReducer from "./features/program/ProgramsSlice";
// specifications : program
import StatusReducer from "./features/program/StatusProgramSpecSlice";
import ProgramSpecificationReportDocumentReducer from "./features/ProgramSpecificationReportDocument/ProgramSpecificationReportDocumentSlice";
import ProjectReducer from "./features/Project/projectSlice";
//import PublisherConfigReducer from './features/Publisher/publisherSlice';
import rangeReducer from "./features/range/RangeSlice";
import rangeSpecReducer from "./features/range/RangeSpecSlice";
import RecommendationsReducer from "./features/Recommendations/RecommendationsSlice";
import ResearchAndDevelopmentActivityReducer from "./features/ResearchAndDevelopmentActivity/ResearchAndDevelopmentActivitySlice";
import researchAndInnovationActivitiesReducer from "./features/ResearchAndInnovationActivities/ResearchAndInnovationActivitiesSlice";
//import ResearchDirectionReducer from './features/ResearchDirection/researchDirectionSlice';
import resourceTypeConfigReducer from "./features/resourceTypeConfig/ResourceTypeConfigSlice";
import semesterReducer from "./features/semester/SemestersSlice";
import ConfigSkillsReducer from "./features/Skills/ConfigSkillsSlice";
import CvTechSkillsReducer from "./features/Skills/CvTechSkillsSlice";
import specializationSpecReducer from "./features/specialization/SpecializationSpecSlice";
import specializationReducer from "./features/specialization/SpecializationsSlice";
import SpecificationAprovalDataReducer from "./features/SpecificationAprovalData/SpecificationAprovalDataSlice";
//import StandardConfigReducer from './features/standardConfig/StandardConfigSlice';
// CourseImprovement
import StudyPlanReportDocumentReducer from "./features/StudyPlan/StudyPlanReportDocumentSlice";
import SubAreaReducer from "./features/subarea/SubAreaSlice";
import SubAreaSpecReducer from "./features/subarea/SubAreaSpecSlice";
import SubCodeLevelReducer from "./features/SubCodeLevel/SubCodeLevelConfigSlice";
import SubLevelPloReducer from "./features/SubLevelPlo/SubLevelPloSlice";
import SurveyResponseReducer from "./features/Survey/SurveyResponseSlice";
import SurveyReducer from "./features/Survey/SurveySlice";
import TeachingAcademicExperienceReducer from "./features/TeachingAcademicExperience/TeachingAcademicExperienceSlice";
import TeachingLoadReducer from "./features/TeachingLoad/TeachingLoadSlice";
import PlannedCoursesReducer from "./features/TeachingOfPlannedCourses/TeachingOfPlannedCoursesSlice";
import TeachingStrategieConfigReducer from "./features/TeachingStrategies/TeachingStrategiesConfigSlice";
import TeachingStrategieSpecReducer from "./features/TeachingStrategies/TeachingStrategiesSpecSlice";
import totalLevelReducer from "./features/totalLevel/TotalLevelSlice";
import typeReducer from "./features/type/TypeSlice";
import universityReducer from "./features/university/UniversitiesSlice";
import { userSlice } from "./features/user/UserSlice";
import userGroupReducer from "./features/userGroups/UserGroupSlice";
import StudentReducer from "./features/Student/StudentSlice";
import VariationsCoursesReducer from "./features/VariationsCourses/VariationsCoursesSlice";
import verificationCredibilityStudentsResultsConfigReducer from "./features/VerificationCredibilityStudentsResultsConfig/VerificationCredibilityStudentsResultsConfigSlice";
import VerificationOfCredibilityReducer from "./features/VerificationOfCredibility/VerificationOfCredibilitySlice";
import VerificationOfCredibilitySpecReducer from "./features/VerificationOfCredibility/VerificationOfCredibilitySpecSlice";
import yearSemestersReducer from "./features/yearSemester/YearSemesterSlice";
import requisitesReducer from "./features/Requisite/RequisiteSlice";
import DocSlice from "./features/docslice/DocSlice";
import EvidanceSlice from "./features/evidanceslice/EvidanceSlice";
import EvidanceTypeSlice from "./features/evidancetypeslice/EvidanceTypeSlice";
import CounselingAndSupportActivitiesReducer from "./features/CounselingAndSupportActivities/CounselingAndSupportActivitiesSlice";
import ReportStatesConfigReducer from "./features/ReportStatesConfig/ReportStatesConfigSlice";
import ResearchAndDevelopmentOutputsReducer from "./features/ResearchAndDevelopmentActivity/ResearchAndInnovationOutputsSlice";
import graduateAttributeConfigReducer from "./features/graduateAttributeConfig/GraduateAttributeConfigSlice";
import CourseInstructorReducer from "./features/CourseInstructor/CourseInstructorSlice";
import CourseSpecPerformancePloReducer from "./features/PLO/CourseSpecPerformancePlo";
import AuthorityLevelsConfigReducer from "./features/AuthorityLevelConfig/AuthorityLevelConfigSlice";
import SurveyMappingReducer from "./features/SurveyMapping/SurveyMappingSlice";
import SurveyAreaReducer from "./features/SurveyArea/SurveyAreaSlice";
import LMSIntegrationReducer from "./features/LMSIntegration/LMSIntegrationSlice";
import LocationsReducer from "./features/location/LocationSlice.js";
import SectorsReducer from "./features/sector/SectorSlice";
import ReportDocumentsReducer from "./features/Report/ReportDocument/ReportDocumentSlice";
import TransferStudentsAcceptanceRequirementsReducer from "./features/transferAcceptanceRequirements/TransferStudentsAcceptanceRequirements.jsx";
import TransferCreditsAcceptanceRequirementsReducer from "./features/transferAcceptanceRequirements/TransferCreditsAcceptanceRequirements.jsx";
import DifficultyTypesReducer from "./features/difficultyType/DifficultyTypeSlice.jsx";

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  reducer: {
    program: programReducer,
    programSpec: programSpecReducer,
    authority: authorityReducer,
    ActionType: ActionTypeReducer,
    ApprovalLevel: ApprovalLevelReducer,
    accreditationDate: accreditationDateReducer,
    plannedAccreditationDate: plannedAccreditationDateReducer,
    peo: peoReducer,
    peoSpec: peoSpecReducer,
    peoIndicator: peoIndicatorReducer,
    peoIndicatorSpec: peoIndicatorSpecReducer,
    ploIndicator: ploIndicatorReducer,
    ploIndicatorSpec: ploIndicatorSpecReducer,
    surveyResponse: SurveyResponseReducer,
    plo: ploReducer,
    ploSpec: ploSpecReducer,
    ploWeight: PLOWeightReducer,
    field: fieldReducer,
    fieldSpec: fieldSpecReducer,
    specialization: specializationReducer,
    specializationSpec: specializationSpecReducer,
    branches: branchesReducer,
    majorTracks: majorTracksReducer,
    jobs: jobsReducer,
    yearSemesters: yearSemestersReducer,
    requisites: requisitesReducer,
    exitpoints: exitPointsReducer,
    exitpointsConfig: exitPointsConfigReducer,
    person: personReducer,
    academicYear: academicYearReducer,
    semester: semesterReducer,
    keyWord: keywordReducer,
    keyWordSpec: keywordSpecReducer,
    GraduateAttributeSpec: GraduateAttributeSpecRecuder,
    GA_PLOMapping: GA_PLOMappingRecuder,
    GAIndicatorSpec: GAIndicatorSpecReducer,
    GraduateAttribute: GraduateAttributeRecuder,
    Status: StatusReducer,
    academicYearEvent: academicYearEventReducer,
    // campaign
    campaign: CampaignReducer,
    survey: SurveyReducer,
    HTMLTemplate: HTMLTemplateReducer,
    courseReportDocument: CourseReportDocumentReducer,
    peoReportDocument: PEOReportDocumentReducer,
    ploReportDocument: PLOReportDocumentReducer,
    gaReportDocument: GAReportDocumentReducer,
    ProgramSpecificationReportDocument:
      ProgramSpecificationReportDocumentReducer,
    CourseSpecReportDocument: CourseSpecReportDocumentReducer,
    CourseSyllabusReportDocument: CourseSyllabusReportDocumentReducer,
    StudyPlanReportDocument: StudyPlanReportDocumentReducer,
    // specification : course
    CourseSpec: CoursesSpecReducer,
    clo: cloReducer,
    cloSpec: cloSpecReducer,
    cloSpecWeight: CLOSpecWeightReducer,
    CLOWeight: CLOWeightReducer,
    SpecificationAprovalData: SpecificationAprovalDataReducer,
    ModeOfInstruction: ModeOfInstructionReducer,
    ModeOfInstructionConfig: ModeOfInstructionConfigReducer,
    LearningResource: LearningResourceReducer,
    FacilitiesRequired: FacilitiesRequiredReducer,
    ActualLearningHour: ActualLearningHourReducer,
    CourseContent: CourseContentReducer,
    CourseContact: CourseContactReducer,
    CourseOutline: CourseOutlineReducer,
    AssessmentTasksForStudent: AssessmentTasksForStudentReducer,
    AlignmentOfCLOWithTMAndAM: AlignmentOfCLOWithTMAndAMReducer,
    CourseQualityEvaluation: CourseQualityEvaluationReducer,
    CourseQualityEvaluationSpec: CourseQualityEvaluationSpecReducer,
    VerificationOfCredibility: VerificationOfCredibilitySpecReducer,
    ActualLearningHourSpec: ActualLearningHourSpecReducer,

    DatesAndTimesAllocation: DatesAndTimesAllocationReducer,
    FieldExperienceLocation: FieldExperienceLocationReducer,
    AssessmentResponsibility: AssessmentResponsibilitiesReducer,

    // configurations
    stateNames: StateNamesReducer,
    stepConfig: stepConfigReducer,
    actionApprovalProcess: ActionApprovalProcessReducer,
    actionApproval: ActionApprovalReducer,
    approvalLevel: ApprovalLevelReducer,
    authorityLevelConfig: AuthorityLevelsConfigReducer,
    university: universityReducer,
    college: collegeReducer,
    menu: menuReducer,
    model: modelReducer,
    section: SectionReducer,
    modelGroupPermission: modelGroupPermissionReducer,
    department: departmentReducer,
    area: areaReducer,
    patype: patypeReducer,
    plannedActivities: plannedActivityReducer,
    areaSpec: areaSpecReducer,
    range: rangeReducer,
    subArea: SubAreaReducer,
    subAreaSpec: SubAreaSpecReducer,
    admissionRequirementSpec: AdmissionRequirementSpecReducer,
    admissionRequirement: AdmissionRequirementReducer,
    graduationRequirement: graduationRequirementReducer,
    graduationRequirementSpec: graduationRequirementSpecReducer,
    resourceTypeConfig: resourceTypeConfigReducer,
    verificationCredibilityStudentsResultsConfig:
      verificationCredibilityStudentsResultsConfigReducer,
    TeachingStrategieConfig: TeachingStrategieConfigReducer,
    category: categoryReducer,
    totalLevel: totalLevelReducer,
    level: levelReducer,
    type: typeReducer,
    assessmentMode: assessmentModeReducer,
    assessmentModeSpec: assessmentModeSpecReducer,
    authorityConfig: AuthorityConfigReducer,
    typesConfig: TypesConfigReducer,
    rolesConfig: RolesConfigReducer,
    internationalConfig: InternationalReasearchConfigReducer,
    utilizedConfig: UtilizedResearchConfigReducer,
    indexConfig: IndexResearchConfigReducer,

    ActualLearningHourConfig: ActualLearningHourConfigReducer,
    ActualLearningHourConfigType: ActualLearningHourConfigTypeReducer,
    FacilitiesRequiredConfig: FacilitiesRequiredConfigReducer,
    CodeLevel: CodeLevelReducer,
    // Activities
    facultyActivity: facultyActivitiesReducer,
    researchAndInnovationActivities: researchAndInnovationActivitiesReducer,
    communityServices: CommuntyServicesReducer,
    professionalDevelopmentActivities: ProfessionalDevelopmentActivitesReducer,
    TeachingLoad: TeachingLoadReducer,
    //cvTech
    Education: EducationReducer,
    TeachingAcademicExperience: TeachingAcademicExperienceReducer,
    ProfessionalExperience: ProfessionalExperienceReducer,
    Project: ProjectReducer,
    LanguagePerson: LanguagePersonReducer,
    ResearchAndDevelopmentActivity: ResearchAndDevelopmentActivityReducer,
    ConfigSkills: ConfigSkillsReducer,
    CvTechSkills: CvTechSkillsReducer,
    ResearchAndDevelopmentOutputs: ResearchAndDevelopmentOutputsReducer,

    // Assesments
    course: CoursesReducer,
    calcul: calculNotesReducer,
    group: groupReducer,
    groupAssessment: groupeAssetmentReducer,
    assessment: assessmentReducer,
    attachment: attachmentReducer,
    GAIndicator: GAIndicatorReducer,
    AssessmentMethods: AssessmentMethodsReducer,
    Questions: QuestionsReducer,
    EvidenceTypeStatesConfig: EvidenceTypeReducer,
    //Assessment Methods
    courseAssessmentMethod: courseAssessmentMethodReducer,
    courseAssessmentMethodSpec: courseAssessmentMethodSpecReducer,
    assessmentMethod: assessmentMethodReducer,
    CourseInstructor: CourseInstructorReducer,
    //AffectEvidenceTypeToAssessmentMethod: AssessmentMethodsReducer,

    // others
    language: languageReducer,
    //auth user
    user: userSlice.reducer,
    student: StudentReducer,

    //dashboard
    dashboardData: dashboardDataReducer,
    //CourseImprovement
    CourseReport: CourseReportReducer,
    TeachingStrategieSpec: TeachingStrategieSpecReducer,
    ActivitiesAssessmentMethodSpec: ActivitiesAssessmentMethodSpecReducer,
    ActivitiesAssessmentMethod: ActivitiesAssessmentMethodReducer,
    VerificationOfCredibilityAssessment: VerificationOfCredibilityReducer,
    Recommendations: RecommendationsReducer,
    Activity: ActivityReducer,
    TeachingStrategie: TeachingStrategieReducer,
    rangeSpec: rangeSpecReducer,
    userGroup: userGroupReducer,
    DistributionOfGrades: DistributionOfGradesReducer,
    CourseEvaluation: CourseEvaluationReducer,
    ConclusionsDifficulties: ConclusionsDifficultiesReducer,
    DifficultiesAndChallenges: DifficultiesAndChallengesReducer,
    // plo IMprovement
    PloReport: PloReportReducer,
    ActionPlan: ActionPlanReducer,

    ActionRecommendation: ActionRecommendationReducer,
    // ga improvement
    GAReport: GAReportReducer,
    // peo improvement
    PeoReport: PeoReportReducer,
    //AnnualProgramReport
    AnnualProgramReport: AnnualProgramReportReducer,
    AnnualProgramReportDocument: AnnualProgramReportDocumentReducer,
    PlannedCourses: PlannedCoursesReducer,
    VariationsCourses: VariationsCoursesReducer,
    annualProgramAnalysis: AnnualProgramAnalysisReducer,
    // camunda
    camunda: CamundaReducer,
    OutGoingMailServer: OutGoingMailServerReducer,
    MailTemplate: MailTemplateReducer,
    Mail: MailReducer,
    //KPI
    KPIProgram: KPIProgramReducer,
    KPIConfig: KPIConfigReducer,
    KPISpec: KPISpecReducer,
    KPIUniversity: KPIUniversityReducer,
    KPICollege: KPICollegeReducer,
    KPICategory: KPICategoryReducer,
    levelPlos: LevelPlosReducer,
    subLevelClo: SubCodeLevelReducer,
    subLevelPlo: SubLevelPloReducer,
    KPIDocumentReport: KPIReportDocumentReducer,
    KPIDepartment: KPIDepartmentReducer,
    // kpi report
    KpiStandard: KpiStandard,
    KpiReport: KpiReportReducer,
    CommentsOnAnnualProgram: CommentsReducer,
    StandardConfig: StandardConfigReducer,
    PublisherConfig: PublisherConfigReducer,
    ResearchDirection: ResearchDirectionReducer,
    groupState: groupStateReducer,
    groupStateAction: groupStateActionsReducer,
    surveyTemplatesTypeConfig: surveyTemplatesTypeConfigReducer,
    CounselingAndSupportActivities: CounselingAndSupportActivitiesReducer,
    ReportStates: ReportStatesConfigReducer,
    graduateAttributeConfig: graduateAttributeConfigReducer,
    Evidance: EvidanceSlice,
    EvidanceType: EvidanceTypeSlice,
    Doc: DocSlice,
    CourseSpecPerformancePlo: CourseSpecPerformancePloReducer,
    surveyMapping: SurveyMappingReducer,
    surveyArea: SurveyAreaReducer,
    LMSIntegration: LMSIntegrationReducer,
    locations: LocationsReducer,
    sectors: SectorsReducer,
    ReportDocument: ReportDocumentsReducer,
    transferStudentsAcceptanceRequirement:
      TransferStudentsAcceptanceRequirementsReducer,
    transferCreditsAcceptanceRequirement:
      TransferCreditsAcceptanceRequirementsReducer,
    difficultyTypes: DifficultyTypesReducer,
  },
});

export default store;
