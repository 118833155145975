import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";
import { updateReportBody } from "../reportUtils";

const ProcessForReviewOfTheProgramEducationalObjectives = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION2_PROGRAMEDUCATIONALOBJECTIVES",
      "ProcessForReviewOfTheProgramEducationalObjectives",
      "description",
      content
    );
  };

  return (
    <>
      <h3>
        PROGRAM EDUCATIONAL OBJECTIVES: Process for Review of the Program
        Educational Objectives
      </h3>

      <p className={styles.reportParagraphTitle}>
        The following is a description of the process that periodically reviews
        the program educational objectives including how ALL of the program’s
        various constituencies are involved in this process, and how this
        process is systematically utilized to ensure that the program’s
        educational objectives remain consistent with the institutional mission,
        the program constituents’ needs and these Criteria:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
    </>
  );
};

export default ProcessForReviewOfTheProgramEducationalObjectives;
