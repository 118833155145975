import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import styles from "../ReportStyles.module.css";
import { updateReportBody } from "../reportUtils";

const AdditionalInformation = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION4_CONTINUOUSIMPROVEMENT",
      "AdditionalInformation",
      "description",
      content
    );
  };

  return (
    <>
      <h3>CONTINUOUS IMPROVEMENT: Additional Information</h3>

      <p className={styles.reportParagraphTitle}>
        The following include information about the assessment instruments or
        materials used to assess the outcomes. Other information, such as
        minutes from meetings where the assessment results were evaluated and
        where recommendations for action were made, could also be included:
      </p>
      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
    </>
  );
};

export default AdditionalInformation;
