import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import BaseUrl from '../../../assets/utils/accessToken';

const initialState = {
  requisites: [],
  requisitesStatus: 'idle',
  requisiteStatus: 'idle',
  error: null,
  requisite: null,
  id: null
}

export const fetchRequisites = createAsyncThunk('requisite/fetchRequisites', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.get(BaseUrl + `/requisitesSpec/`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }

    )
    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})


export const fetchRequisiteById = createAsyncThunk('requisite/fetchRequisiteById',
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.get(BaseUrl + `/requisitesSpec/${id}/`, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const addNewRequisite = createAsyncThunk(
  'requisite/addNewRequisite',
  async (initialRequisite, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(BaseUrl + `/requisitesSpec/create/`, initialRequisite, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const updateRequisite = createAsyncThunk(
  'requisite/updateRequisite',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/requisitesSpec/${data.id}/edit/`, data.modified, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      return response.data
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  })

export const deleteRequisite = createAsyncThunk('requisite/deleteRequisite', async (id, { rejectWithValue }) => {
  const token = localStorage.getItem('token')
  try {
    const response = await axios.delete(BaseUrl + `/requisitesSpec/${id}/delete/`, {
      headers: {
        'Authorization': `token ${token}`
      }
    })
    return response.data
  } catch (err) {
    let error = err;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response);
  }
})

const requisitesSlice = createSlice({
  name: ' requisite',
  initialState,
  extraReducers: {
    [fetchRequisites.pending]: (state, action) => {
      state.requisitesStatus = 'loading'
    },
    [fetchRequisites.fulfilled]: (state, action) => {
      state.requisitesStatus = 'succeeded'
      // Add any fetched posts to the array
      state.requisites = action.payload
    },
    [fetchRequisites.rejected]: (state, action) => {
      state.requisitesStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [fetchRequisiteById.pending]: (state, action) => {
      state.requisiteStatus = 'loading'
    },
    [fetchRequisiteById.fulfilled]: (state, action) => {
      state.requisiteStatus = 'succeeded'
      state.requisite = action.payload
    },
    [fetchRequisiteById.rejected]: (state, action) => {
      state.requisiteStatus = 'failed'
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [addNewRequisite.fulfilled]: (state, action) => {
      state.requisites.push(action.payload)
    },
    [addNewRequisite.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [deleteRequisite.fulfilled]: (state, action) => {},
    [deleteRequisite.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },

    [updateRequisite.fulfilled]: (state, action) => {
      const result = state.requisites.filter(requisite => requisite.id !== action.payload.id);
      result.push(action.payload)
      state.requisites = result
    },
    [updateRequisite.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload
      } else {
        state.error = action.error
      }
    },
  },
})

export default requisitesSlice.reducer

export const selectAllRequisites = state => state.requisites.requisites
