// @material-ui/core components
import {unwrapResult} from "@reduxjs/toolkit";

import "antd/dist/antd.css";
import "assets/css/styles.css";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchAssessmentMethodsByCourseId} from "reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice";
import useTable from "../../../../Components/useTable";
import {TableBody, TableCell, TableRow} from "@material-ui/core";
import EditActivitiesAssessmentMethodsDifficultiesAction
    from "../../../../Components/EditActivitiesAssessmentMethodsDifficultiesAction"
import {
    fetchConclusionsDifficultiesByCourseId_And_Type,
} from "reduxToolkit/features/ConclusionsDifficulties/ConclusionsDifficultiesSlice.js";
import {
    primaryColor,
} from "../../../../../assets/jss/material-dashboard-pro-react"

const headCells = [
    {id: "Planned Assessment Methods", label: "Planned Assessment Methods"},
    {id: "Were They Implemented?", label: "Were They Implemented?"},
];

export default function AssessmentMethods(props) {

    const dispatch = useDispatch();

    const [records, setRecords] = [];

    const [array_difficulties_SuggestedAction, setArray_difficulties_SuggestedAction] = useState([]);
    const [filterFn, setFilterFn] = useState({
        fn: (items) => {
            return items;
        },
    });
    const [activitiesAssessmentMethods_ids, setActivitiesAssessmentMethods_ids,] = useState("");

    const ActivitiesAssessmentMethods = useSelector(
        (state) => state.AssessmentMethods.assessmentMethodsByCourseId
    );

    useEffect(() => {
        dispatch(fetchAssessmentMethodsByCourseId(props.idCourse))
            .then(unwrapResult)
            .then((ActivitiesAssessmentMethods) => {
                setActivitiesAssessmentMethods_ids(
                    ActivitiesAssessmentMethods.map((el) => el.id)
                );
            });
    }, []);

    useEffect(() => {
        dispatch(fetchConclusionsDifficultiesByCourseId_And_Type(object))
            .then(unwrapResult)
            .then((difficulties_SuggestedAction) => {
                setArray_difficulties_SuggestedAction([...difficulties_SuggestedAction]);
            });
    }, []);

    const {
        TblContainer,
        TblHead,
    } = useTable(records, headCells, filterFn);

    let object = {
        course_id: props.idCourse,
        type: "AM",
    }

    const fetchConclusionsDifficulties = () => {
        dispatch(fetchConclusionsDifficultiesByCourseId_And_Type(object))
            .then(unwrapResult)
            .then((difficulties_SuggestedAction) => {
                setArray_difficulties_SuggestedAction([...difficulties_SuggestedAction]);
            });
    }

    return (
        <GridContainer xs={12} sm={12} md={12}>
            <div
                style={{width: "100%"}}
            >
                <GridItem xs={12} sm={12} md={12}>
                    <TblContainer style={{marginTop: "0px", paddingTop: "0px"}}>
                        <TblHead/>
                        <TableBody>
                            {ActivitiesAssessmentMethods &&
                                ActivitiesAssessmentMethods.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell style={{borderLeft: "1px solid lightgrey"}}>
                                            {item &&
                                                item.exam &&
                                                item.exam}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                borderRight: "1px solid lightgrey",
                                                borderLeft: "1px solid lightgrey",
                                            }}
                                        >
                                            {item && item.implemented && item.implemented}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </TblContainer>
                </GridItem>
                <Card style={{marginRight: "10px"}}>
                    <CardBody>
                        <GridContainer xs={12} sm={12} md={12}>
                            <GridItem xs={12} sm={12} md={10}>
                                <p style={{height: "150px"}}>
                                    <strong style={{color: primaryColor[0]}}>Difficulties Experienced</strong> :
                                    <p>{array_difficulties_SuggestedAction && array_difficulties_SuggestedAction[0] && array_difficulties_SuggestedAction[0].difficulties}</p>
                                </p>
                                <p style={{height: "150px"}}><strong style={{color: primaryColor[0]}}>Suggested
                                    Action</strong> :
                                    <p>{array_difficulties_SuggestedAction && array_difficulties_SuggestedAction[0] && array_difficulties_SuggestedAction[0].suggested}</p>
                                </p>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={2} style={{textAlign: "end"}}>
                                <EditActivitiesAssessmentMethodsDifficultiesAction
                                    idCourse={props.idCourse}
                                    fetchConclusionsDifficulties={fetchConclusionsDifficulties}/>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        </GridContainer>
    );
}
