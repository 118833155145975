import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumb } from 'antd';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ErrorModal from "views/Components/ErrorModal";
import { grayColor } from "../../../../assets/jss/material-dashboard-pro-react";
import DropDownRequisiteButton from './DropDownRequisiteButton';
import {fetchRequisites} from "../../../../reduxToolkit/features/Requisite/RequisiteSlice";

const useStyles = makeStyles(styles);

const RequisiteExcerpt = ({ requisite }) => {

  const classes = useStyles();

  return (
    <GridContainer
      md={4}
      xs={12} sm={12}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <GridItem key={requisite.id}>
        <Card product className={classes.cardHover} >
          <CardBody style={{ width: 375, height: 200 }}>
            <GridContainer >
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={10}>
                    <h4 className={classes.cardProductTitle} style={{ width: 108 }}>
                      {requisite.requisites}
                    </h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <DropDownRequisiteButton id={requisite.id} />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default function RequisitesPage() {

  const { t } = useTranslation();
  const dispatch = useDispatch()

  const requisitesStatus = useSelector(state => state.requisites.requisitesStatus)
  const requisites = useSelector(state => state.requisites.requisites)
  const error = useSelector(state => state.requisites.error)

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchAllRequisites = async () => {
    try {
      await dispatch(fetchRequisites()).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    void fetchAllRequisites()
  }, [])

  let content;

  if (requisitesStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (requisitesStatus === 'succeeded') {
    content = requisites && requisites.map(requisite => (
      <RequisiteExcerpt key={requisite.id} requisite={requisite} />
    ))
  } else if (requisitesStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer style={{
        borderBottom: `1px solid  ${grayColor[15]}`,
        borderTop: `1px solid  ${grayColor[15]}`,
      }}
        justify="space-between"
        alignItems="center">
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item >
              {t('Configurations')}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/course`}>
                <span > {t('Course Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item >
              <Link to={`/citrine/configuration/course/requisites`} style={{ color: "orange" }}>
                {t('Requisites List')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Link to="/citrine/configuration/course/requisites/create" >
        <Button color="info">
          {t('  Add')}
        </Button>
      </Link>
      <GridContainer>
        {
          content
        }
      </GridContainer>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
