import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { updateReportBody } from "../reportUtils";

const ProgramBudgetAndFinancialSupport = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION8_INSTITUTIONALSUPPORT",
      "ProgramBudgetAndFinancialSupport",
      "description",
      content
    );
  };

  return (
    <>
      <h3>INSTITUTIONAL SUPPORT: Program Budget and Financial Support</h3>

      <p className={styles.reportParagraphTitle}>The following includes:</p>
      <ul>
        <li>
          A description of the process used to establish the program’s budget,
          evidence of continuity of institutional support for the program, and
          the sources of financial support both permanent (recurring) and
          temporary (one-time) funds.
        </li>
        <li>
          A description of how teaching is supported by the institution in terms
          of graders, teaching assistants, teaching workshops, etc.
        </li>
        <li>
          A description of how resources are provided to acquire, maintain, and
          upgrade the infrastructures, facilities, and equipment used in the
          program.
        </li>
        <li>
          An assessment of the adequacy of the resources with respect to the
          students in the program being able to attain the student outcomes.
        </li>
      </ul>

      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
    </>
  );
};

export default ProgramBudgetAndFinancialSupport;
