import React, { useState } from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

export default function ErrorModal(props) {

  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  let content;
  let details =
    props.handledError?.data?.error ||
    props.handledError?.data?.detail ||
    props.handledError?.data?.non_field_errors?.[0] ||
    props.handledError?.data;

  if (
    props.handledError?.status &&
    props.handledError?.statusText &&
    typeof props.handledError?.statusText === "string"
  ) {
    let insight = props.handledError?.statusText;
    console.log("props.handledError", props.handledError);

    content = (
      <>
        <div>{insight}</div>
        <hr />
        {!show && (
          <Button
            link
            onClick={() => {
              setShow(true);
            }}
          >
            See Details
          </Button>
        )}
        {show && (
          <div>
            <div
              style={{
                backgroundColor: "rgba(176, 48, 48, 0.3)",
                padding: "10px",
              }}
            >
              {details}
            </div>
            <br />
            <Button
              link
              onClick={() => {
                setShow(false);
              }}
            >
              Hide
            </Button>
            <Button link onClick={() => navigator.clipboard.writeText(details)}>
              Copy
            </Button>
          </div>
        )}
      </>
    );
  } else {
    if (props.handledError?.message == "Network Error") {
      content = (
        <div>
          <div>{props.handledError?.message}</div>
          <div>Please check your internet connection!</div>
        </div>
      );
    } else {
      content = <div>{props.handledError?.message}</div>;
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">
        <GridContainer justifyContent="space-between" alignItems="center">
          <GridItem>
            <h4>Error</h4>
          </GridItem>
          <GridItem>
            <Button
              // aria-describedby={id}
              aria-controls="customized-menu"
              variant="contained"
              color="danger"
              onClick={props.handleClose}
              justIcon
              round
              aria-haspopup="true"
            >
              <CloseIcon />
            </Button>
          </GridItem>
        </GridContainer>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.handleErrorText ? props.handleErrorText : content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="danger" autoFocus>
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
