import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPloReports,
  UpdatePloReport,
} from "../../../../reduxToolkit/features/PloReporte/PloReporteSlice";
import { fetchGroupStatesByReport } from "reduxToolkit/features/groupState/GroupStateSlice";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link, useParams } from "react-router-dom";
import "@lourenci/react-kanban/dist/styles.css";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, Card } from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import DropDownButtonPloReport from "../../../Components/DropDownButtonPloReport";
import ErrorModal from "../../../Components/ErrorModal";
import Info from "components/Typography/Info.js";
import { fetchStaff } from "../../../../reduxToolkit/features/user/UserSlice";
import DNDBoard from "../../../../components/dnd/Board";

export default function PloImprovementList(props) {
  const { type } = useParams();
  const initialBoard = {
    columns: [],
  };
  let content;
  let draftBoard;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [board, setBoard] = useState(initialBoard);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");

  useEffect(() => {
    orderReportsAccordingToStates();
    dispatch(fetchStaff());
  }, []);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  function deepCopy(obj) {
    let newObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        newObj[key] = deepCopy(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }

  const orderReportsAccordingToStates = () => {
    dispatch(fetchGroupStatesByReport("PLoReport"))
      .then(unwrapResult)
      .then((states) => {
        if (states?.columns?.length > 0) {
          draftBoard = deepCopy(states);
          dispatch(fetchPloReports())
            .then(unwrapResult)
            .then((PLoReports) => {
              if (type !== "NCAAA") { PLoReports = [] }
              if (PLoReports?.length > 0) {
                PLoReports.forEach((report) => {
                  let stateObject = report.state_object;
                  let column = draftBoard?.columns?.find(
                    (col) => col.title === stateObject
                  );
                  if (column && column.cards) {
                    // Ensure cards array exists
                    column.cards.push(report);
                  }
                });
              }
              setBoard(draftBoard);
            })
            .catch((error) => {
              setHandledError(error);
              handleOpenErrorModal();
            });
        }
      })
      .catch((error) => {
        setHandledError(error);
        handleOpenErrorModal();
      });
  };

  const handleDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination || source.droppableId == destination.droppableId) return;

    const destinationState = board.columns.find(
      (state) => state.id == destination.droppableId
    );
    const report = PloReports.find((report) => report.id == draggableId);
    const state_object = destinationState?.title;

    if (state_object && report) {
      // Dispatch the update request

      try {
        dispatch(
          UpdatePloReport({
            id: report.id,
            updatedPLoReport: { ...report, state_object },
          })
        ).unwrap();
        // handle result here
        // If the request is successful, update the state
        const updatedColumns = board.columns.map((column) => {
          if (column.id == source.droppableId) {
            // Remove the report from the source column
            column.cards = column.cards.filter((card) => card.id !== report.id);
          } else if (column.id == destination.droppableId) {
            // Add the report to the destination column
            column.cards.push(report);
          }
          return column;
        });

        // Update the state with the new columns structure
        setBoard((prevBoard) => ({ ...prevBoard, columns: updatedColumns }));
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        setHandledError(rejectedValueOrSerializedError);
        handleOpenErrorModal();
      }
    }
  };

  const PLOReportsStatus = useSelector(
    (state) => state.PloReport.PLOReportsStatus
  );

  const PLoReport_StateBoard = useSelector(
    (state) => state.groupState.groupStatesByReport
  );

  const groupStatesByReportStatus = useSelector(
    (state) => state.groupState.groupStatesByReportStatus
  );

  const PloReports = useSelector((state) => state.PloReport.PloReports);

  const staffList = useSelector((state) => state.user.staffList);

  const renderCardContent = (report, showDropDown) => (
    <div>
      <GridContainer alignItems="baseline" style={{ minHeight: "80px" }}>
        <GridItem
          key={Element.id}
          xs={showDropDown ? 12 : 12}
          sm={showDropDown ? 9 : 12}
          md={showDropDown ? 9 : 12}
          lg={showDropDown ? 10 : 12}
        >
          <Link to={`/citrine/reports/${type}/PLO/${report.id}`}>
            <strong>
              <p style={{ marginTop: "10px" }}>
                {report?.program_detail?.program_degree_name}
              </p>
            </strong>
          </Link>
          {/* {Moment(report.date).format("YYYY-MM-DD")} */}
        </GridItem>
        {showDropDown && (
          <GridItem key={Element.id} xs={12} sm={3} md={3} lg={2}>
            <DropDownButtonPloReport
              data={report}
              id={report.id}
              staffList={staffList}
              orderReportsAccordingToStates={orderReportsAccordingToStates}
            />
          </GridItem>
        )}
      </GridContainer>
      <p style={{ fontSize: "12px" }}>
        {report?.program_detail?.academicYear}/
        {report?.program_detail?.semester_name}
      </p>
    </div>
  );

  if (
    PLOReportsStatus === "loading" ||
    groupStatesByReportStatus === "loading"
  ) {
    content = (
      <GridContainer
        style={{ height: "100%" }}
        justifyContent="center"
        alignItems="center"
      >
        <GridItem>
          <CircularProgress color="inherit" />
        </GridItem>
      </GridContainer>
    );
  }

  if (PLOReportsStatus === "failed" || groupStatesByReportStatus === "failed") {
    return (
      <GridContainer justifyContent="center" alignItems="center">
        <GridItem style={{ width: "60%" }}>
          <Info>
            Oops! Something went wrong. We're having trouble retrieving the PLO
            reports. Please try again later. If the issue persists, contact
            support for assistance.
          </Info>
        </GridItem>
      </GridContainer>
    );
  }

  if (groupStatesByReportStatus === "succeeded") {
    if (PLoReport_StateBoard?.columns?.length == 0) {
      content = (
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem style={{ width: "60%" }}>
            <Info>
              Oops! Something went wrong. We could not find any PLO reports
              states. Please add the needed states in the configuration menu or
              contact support for assistance.
            </Info>
          </GridItem>
        </GridContainer>
      );
    }

    if (PLOReportsStatus === "succeeded") {
      content = (
        <DNDBoard
          columns={board}
          renderCardContent={renderCardContent}
          handleDragEnd={handleDragEnd}
        />
      );
    }
  }

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={`/citrine/reports/${type}/PLO/`}>{t("PLO Report")}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      {content}
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  );
}
