import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";
import AntDDataGrid from "components/DataGrid/AntDDataGrid";
import styles from "../ReportStyles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { updateReportBody } from "../reportUtils";

const Staffing = ({ data, report, setReportBody }) => {
  const [valueContent, setValueContent] = useState(data.description ?? "");

  const handleChangeDescription = (content) => {
    setValueContent(content);
    updateReportBody(
      setReportBody,
      "CRITERION8_INSTITUTIONALSUPPORT",
      "Staffing",
      "description",
      content
    );
  };

  return (
    <>
      <h3>INSTITUTIONAL SUPPORT: Staffing</h3>

      <p className={styles.reportParagraphTitle}>
        The following includes, a description of the adequacy of the staff
        (administrative, instructional, and technical) and institutional
        services provided to the program, And a discussion of the methods used
        to retain and train staff:
      </p>

      <HTMLEditor
        valueContent={valueContent}
        setValueContent={handleChangeDescription}
      />
    </>
  );
};

export default Staffing;
