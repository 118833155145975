import React from "react";

import Button from "components/CustomButtons/Button.js";

import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";

import { useTranslation } from "react-i18next";

export default function SuperGeneralDeleteModal(props) {

  const { t } = useTranslation();

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title"> Confirm Delete</DialogTitle>
      <DialogContent>
        <h4> {t("Are you sure you want to Delete this element ?")}</h4>
        <DialogActions>
          <Button onClick={props.handleClose} color="danger">
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              props.delete_Element(props.Element_ID);
              props.handleClose();
            }}
            color="success"
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
