import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";

const initialState = {
  AnnualProgramReports: [],
  Reports: [],
  Report: null,
  ProgramImprovementPlanList: [],
  DifficultiesAndChallengesList: [],
  ReportsStatus: "idle",
  ReportStatus: "idle",
  AnnualProgramsStatus: "idle",
  AnnualProgramStatus: "idle",
  ProgramImprovementPlanStatus: "idle",
  DifficultiesAndChallengesStatus: "idle",
  error: null,
  AnnualProgramReport: null,
  id: null,
};

// TODO To verify the endpoint
export const fetchReports = createAsyncThunk(
  "AnnualProgramReport/fetchReports",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/reports`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  }
);

export const fetchReport = createAsyncThunk(
  "AnnualProgramReport/fetchReport",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/reports/${id}`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  }
);

export const addReport = createAsyncThunk(
  "AnnualProgramReport/addReport",
  async (reportObj, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/reports/create/`,
        reportObj,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const updateReport = createAsyncThunk(
  "AnnualProgramReport/updateReport",
  async (reportObj, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/reports/${reportObj.id}/edit/`,
        reportObj.data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const deleteReport = createAsyncThunk(
  "AnnualProgramReport/deleteReport",
  async (id, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(BaseUrl + `/reports/${id}/delete/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAnnualProgramReports = createAsyncThunk(
  "AnnualProgramReport/fetchAnnualProgramReports",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/annualProgramReport/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  }
);

export const fetchAnnualProgramReportById = createAsyncThunk(
  "AnnualProgramReport/fetchAnnualProgramReportById",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + `/annualProgramReport/${id}/`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  }
);

export const addNewAnnualProgramReport = createAsyncThunk(
  "AnnualProgramReport/addNewAnnualProgramReport",
  async (initialAnnualProgramReport, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        BaseUrl + `/annualProgramReport/create/`,
        initialAnnualProgramReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const deleteAnnualProgramReport = createAsyncThunk(
  "AnnualProgramReport/deleteAnnualProgramReport",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(
      BaseUrl + `/annualProgramReport/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const UpdateAnnualProgramReport = createAsyncThunk(
  "AnnualProgramReport/UpdateAnnualProgramReport",
  async (data, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/annualProgramReport/${data.id}/edit/`,
        data.updatedAnnualProgramReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {}
  }
);

export const addNewDifficultiesAndChallenges = createAsyncThunk(
  "AnnualProgramReport/addNewDifficultiesAndChallenges",
  async (DifficultiesAndChallenges) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/difficultiesAndChallenges/create/`,
      DifficultiesAndChallenges,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const addNewProgramImprovementPlan = createAsyncThunk(
  "AnnualProgramReport/addNewProgramImprovementPlan",
  async (ProgramImprovementPlan) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      BaseUrl + `/programImprovementPlan/create/`,
      ProgramImprovementPlan,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const fetchProgramImprovementPlan = createAsyncThunk(
  "AnnualProgramReport/fetchProgramImprovementPlan",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/programImprovementPlan/?program_id=${id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const deleteProgramImprovementPlan = createAsyncThunk(
  "AnnualProgramReport/deleteAnnualProgramReport",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(
      BaseUrl + `/programImprovementPlan/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const fetchDifficultiesAndChallenges = createAsyncThunk(
  "AnnualProgramReport/fetchDifficultiesAndChallenges",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      BaseUrl + `/difficultiesAndChallenges/?program_id=${id}`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const deleteDifficultiesAndChallenge = createAsyncThunk(
  "AnnualProgramReport/deleteDifficultiesAndChallenge",
  async (id) => {
    const token = localStorage.getItem("token");
    const response = await axios.delete(
      BaseUrl + `/difficultiesAndChallenges/${id}/delete/`,
      {
        headers: {
          Authorization: `token ${token}`,
        },
      }
    );
    return response.data;
  }
);

export const AffectCoordinatorToAnnualProgramReport = createAsyncThunk(
  "annualProgramReport/AffectCoordinatorToAnnualProgramReport",
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        BaseUrl + `/annualProgramReport/update/${data.id}/`,
        data.data,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response);
    }
  }
);

const AnnualProgramReportSlice = createSlice({
  name: "AnnualProgramReport",
  initialState,
  reducers: {
    AnnualProgramReportUpdate(state, action) {
      const { id, program, state_object, due_date, sent_to } = action.payload;
      const existingAnnualProgramReport = state.AnnualProgramReports.find(
        (AnnualProgramReport) => AnnualProgramReport.id === id
      );
      if (existingAnnualProgramReport) {
        existingAnnualProgramReport.program = program;
        existingAnnualProgramReport.state_object = state_object;
        existingAnnualProgramReport.due_date = due_date;
        existingAnnualProgramReport.sent_to = sent_to;
      }
    },
  },
  extraReducers: {
    [fetchReports.pending]: (state, action) => {
      state.ReportsStatus = "loading";
    },
    [fetchReports.fulfilled]: (state, action) => {
      state.ReportsStatus = "succeeded";
      state.Reports = action.payload;
    },
    [fetchReports.rejected]: (state, action) => {
      state.ReportsStatus = "failed";
      state.error = action.payload;
    },
    [fetchReport.pending]: (state, action) => {
      state.ReportStatus = "loading";
    },
    [fetchReport.fulfilled]: (state, action) => {
      state.ReportStatus = "succeeded";
      state.Report = action.payload;
    },
    [fetchReport.rejected]: (state, action) => {
      state.ReportStatus = "failed";
      state.error = action.payload;
    },
    [fetchAnnualProgramReports.pending]: (state, action) => {
      state.AnnualProgramsStatus = "loading";
    },
    [fetchAnnualProgramReports.fulfilled]: (state, action) => {
      state.AnnualProgramsStatus = "succeeded";
      state.AnnualProgramReports = action.payload;
    },
    [fetchAnnualProgramReports.rejected]: (state, action) => {
      state.AnnualProgramsStatus = "failed";
      state.error = action.payload;
    },
    [fetchProgramImprovementPlan.pending]: (state, action) => {
      state.ProgramImprovementPlanStatus = "loading";
    },
    [fetchProgramImprovementPlan.fulfilled]: (state, action) => {
      state.ProgramImprovementPlanStatus = "succeeded";
      state.ProgramImprovementPlanList = action.payload;
    },
    [fetchProgramImprovementPlan.rejected]: (state, action) => {
      state.ProgramImprovementPlanStatus = "failed";
      state.error = action.payload;
    },
    [fetchDifficultiesAndChallenges.pending]: (state, action) => {
      state.DifficultiesAndChallengesStatus = "loading";
    },
    [fetchDifficultiesAndChallenges.fulfilled]: (state, action) => {
      state.DifficultiesAndChallengesStatus = "succeeded";
      state.DifficultiesAndChallengesList = action.payload;
    },
    [fetchDifficultiesAndChallenges.rejected]: (state, action) => {
      state.DifficultiesAndChallengesStatus = "failed";
      state.error = action.payload;
    },
    [fetchAnnualProgramReportById.pending]: (state, action) => {
      state.AnnualProgramStatus = "loading";
    },
    [fetchAnnualProgramReportById.fulfilled]: (state, action) => {
      state.AnnualProgramStatus = "succeeded";
      state.AnnualProgramReport = action.payload;
    },
    [fetchAnnualProgramReportById.rejected]: (state, action) => {
      state.AnnualProgramStatus = "failed";
      state.error = action.payload;
    },
    [addNewAnnualProgramReport.pending]: (state, action) => {
      state.AnnualProgramStatus = "loading";
    },
    [addNewAnnualProgramReport.fulfilled]: (state, action) => {
      state.AnnualProgramStatus = "succeeded";
      state.AnnualProgramReports.push(action.payload);
    },
    [addNewAnnualProgramReport.rejected]: (state, action) => {
      state.AnnualProgramStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [addNewDifficultiesAndChallenges.fulfilled]: (state, action) => {},
    [addNewProgramImprovementPlan.fulfilled]: (state, action) => {},
    [deleteAnnualProgramReport.fulfilled]: (state, action) => {},
  },
});

export const { AnnualProgramReportAdded, AnnualProgramReportUpdate } =
  AnnualProgramReportSlice.actions;

export default AnnualProgramReportSlice.reducer;
