import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAnnualProgramReport,
  fetchAnnualProgramReports,
  addReport,
} from "../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { notification } from "antd";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import ErrorModal from "./ErrorModal.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import { calculateYearsBetweenExperienceDates } from "../../assets/utils/helperFunction";
import { fetchProgramById } from "../../reduxToolkit/features/program/ProgramsSlice";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
import validationStyles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
import { Analysis } from "views/Pages/Improvement/SSR/reportUtils";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(styles);
const useValidationStyles = makeStyles(validationStyles);

export default function AnnualProgramReportModal(props) {
  const classes = useStyles();
  const validationClasses = useValidationStyles();

  const defaultReportAccreditationBody = "NCAAA";
  const reportAccreditationBodies = ["NCAAA", "CTI", "ABET"];

  const defaultReportType = "Annual Program Report";
  const reportTypes = ["Annual Program Report", "Self Study Report"];

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [type, setType] = useState(defaultReportType);
  const [accreditationBody, setAccreditationBody] = useState(
    defaultReportAccreditationBody
  );

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [open, setOpen] = useState(false);
  const [version, setVersion] = useState(null);
  const [numberState, setnumberState] = useState("");

  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const handleChangeAccreditationBody = (event) => {
    setAccreditationBody(event.target.value);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchProgram = async (id) => {
    try {
      await dispatch(fetchProgramById(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  const program = useSelector((state) => state.program.program);

  //////////////////////////////////////////////////////////////////////

  const deriveBackgroundInformation = (program) => {
    return {
      ContactInformation: {
        name: `${program.person_detail?.[0]?.first_name} ${program.person_detail?.[0]?.last_name}`,
        department: program.department_detail?.[0]?.name,
        college: program.college_detail?.[0]?.name,
        university: program.university_detail?.[0]?.name,
        //-----------------------------------------------------//
        address: program.coordinators_detail?.[0]?.work_location ?? "",
        phone: program.coordinators_detail?.[0]?.work_phone ?? "",
        email: program.coordinators_detail?.[0]?.work_email ?? "",
        fax: program.coordinators_detail?.[0]?.work_phone ?? "",
      },
      // major changes are the actions (with "high priority", and whose states are "done") taken to address feedback of last general review
      ProgramHistory: {
        //-----------------------------------------------------//
        lastReviewDate: program.last_activity ?? "",
        //-----------------------------------------------------//
        majorChanges: [],
        history: program.history,
        description: ``,
      },
      Options: {
        //-----------------------------------------------------//
        options: program.specialization_details?.length
          ? program.specialization_details?.map((spec) => spec.name)
          : [],
        tracks: program.major_tracks_details?.length
          ? program.major_tracks_details?.map((track) => track.pathway)
          : [],
        description: ``,
      },
      ProgramDeliveryModes: {
        //-----------------------------------------------------//
        // modes: program.deliveryModes ?? [],
        modes: program?.courses
          ?.map((course) =>
            course?.mode_of_instructions?.map(
              (mode) => mode.mode_of_instruction__name
            )
          )
          .flat().length
          ? program?.courses
              ?.map((course) =>
                course?.mode_of_instructions?.map(
                  (mode) => mode.mode_of_instruction__name
                )
              )
              .flat()
          : [],
        description: ``,
      },
      ProgramLocations: {
        // locations: program.accredited_campus_location?.length
        //   ? program.accredited_campus_location.map((loc) => loc.location)
        //   : [],
        locations: program.accredited_campus_location,
        description: ``,
      },
      PublicDisclosure: {
        //-----------------------------------------------------//
        publicDisclosure:
          program.program_disclosure_link ??
          program.programSpec_detail?.program_disclosure_link ??
          "university website",
        description: ``,
      },
      PreviousEvaluations: {
        feedback: Analysis,
        description: ``,
      },
    };
  };

  const deriveCriterion1 = (program) => {
    return {
      StudentAdmissions: {
        // Program admission requirements
        studentAdmissionRequirements: program.admissions_detail?.length
          ? program.admissions_detail?.map((req) => req.title)
          : [],
        description: ``,
      },
      EvaluatingStudentPerformance: {
        prerequisites: program.courses?.length
          ? program.courses?.map((course) => ({
              key: course.id,
              title: course.title,
              children: course.prerequisites_ids?.length
                ? course.prerequisites_ids?.map((req) => ({
                    key: req.id,
                    title: req.requisite,
                  }))
                : [],
            }))
          : [],
        description: ``,
      },
      TransferStudentsAndTransferCourses: {
        //-----------------------------------------------------//
        transferStudentsAcceptanceRequirements: program.programSpec_detail
          ?.acceptance_details?.length
          ? program.programSpec_detail?.acceptance_details?.map(
              (req) => req.name
            )
          : [],
        //-----------------------------------------------------//
        transferCreditAcceptanceRequirements: program.programSpec_detail
          ?.transfer_credits_details?.length
          ? program.programSpec_detail?.transfer_credits_details?.map(
              (req) => req.name
            )
          : [],
        description: ``,
      },
      AdvisingAndCareerGuidance: {
        GuidanceAndOrientationProgramsForNewStudents:
          program.guidance_for_new_students ??
          program.programSpec_detail?.guidance_for_new_students ??
          "",
        StudentCounselingServices:
          program.student_counseling ??
          program.programSpec_detail?.student_counseling ??
          "",
        SpecialSupport:
          program.special_support ??
          program.programSpec_detail?.special_support ??
          "",
        description: ``,
      },
      //-----------------------------------------------------//
      WorkInLieuOfCourses: {
        WorkInLieuOfCourses:
          program.work_in_lieu_of_courses ??
          program.programSpec_detail?.work_in_lieu_of_courses ??
          "",
        description: ``,
      },
      GraduationRequirements: {
        programName: program.program_degree_name,
        awardedDegreeName: program.degree_awarded,
        graduationRequirements: program.graduations_detail?.length
          ? program.graduations_detail?.map((req) => req.title)
          : [],
        description: ``,
      },
      RecordsOfStudentWorkOrTranscripts: {
        //-----------------------------------------------------//
        transcriptsFiles: [],
        attachments: [],
        description: ``,
      },
      Analysis: {
        feedback: Analysis,
        description: ``,
      },
    };
  };

  const deriveCriterion2 = (program) => {
    return {
      MissionStatement: {
        institutionMissionStatement: program?.university_detail?.[0].mission,
        collegeMissionStatement: program?.college_detail?.[0].mission,
        programMissionStatement: program?.mission,
      },
      ProgramEducationalObjectives: {
        //-----------------------------------------------------//
        PEOs: program.peos_details?.length
          ? program.peos_details.map((peo) => `${peo.code}:  ${peo.name}`)
          : [],
        publicDisclosure: program?.publicDisclosure ?? "university website",
        programName: program?.program_degree_name,
        description: ``,
      },
      ConsistencyOfTheProgramEducationalObjectivesWithTheMissionOfTheInstitution:
        {
          description: ``,
        },
      ProgramConstituencies: {
        //-----------------------------------------------------//
        description: ``,
        programName: program?.program_degree_name,
      },
      ProcessForReviewOfTheProgramEducationalObjectives: {
        description: ``,
        // add recent changes to PEOs
      },
      Analysis: {
        feedback: Analysis,
        description: ``,
      },
    };
  };

  const deriveCriterion3 = (program) => {
    return {
      StudentOutcomes: {
        //-----------------------------------------------------//
        PLOs: program?.plos_details?.length
          ? program.plos_details.map((plo) => `${plo.code}:  ${plo.name}`)
          : [],
        // each PLO should have an array of appropriate General Criteria Student Outcomes, if the  PLO does not address any General Criteria, the list should be empty
        programName: program?.program_degree_name,
        publicDisclosure: program?.publicDisclosure ?? "university website",
        description: ``,
      },
      RelationshipOfStudentOutcomesToProgramEducationalObjectives: {
        //a list of the PEOs codes
        //-----------------------------------------------------//
        PEOs: program.peos_details?.length
          ? program.peos_details.map((peo) => peo.code)
          : [],
        //the mapping array should follow the following format:
        PLOs_PEOs_mapping: program.peosplos_mapping_details?.length
          ? program.peosplos_mapping_details.map((plo) => {
              let res = {};
              let obj = {};
              for (let peo of program.peos_details) {
                plo.weight_plo?.map((p) => p?.peo?.id)?.includes(peo.id)
                  ? (obj[peo.code] = true)
                  : (obj[peo.code] = false);
              }
              res = { PLO: plo.name, ...obj };
              return res;
            })
          : [],
        PLOs_Indicators_mapping: program.peosplos_mapping_details?.length
          ? program.peosplos_mapping_details.map((plo) => {
              const indicators = plo.plo_indicators?.map((ind) => ind.name);
              return { PLO: plo.name, indicators };
            })
          : [],
        description: ``,
      },
      Analysis: {
        feedback: Analysis,
        description: ``,
      },
    };
  };

  const deriveCriterion4 = (program) => {
    return {
      StudentOutcomes: {
        // if assessment processes are the assessment methods
        assessmentMethods: [
          { assessmentMethod: "oral-exam", description: "" },
          { assessmentMethod: "focus-group", description: "" },
        ],
        assessmentFrequency: "",
        PLOs_courses_mapping: program.peosplos_mapping_details?.length
          ? program.peosplos_mapping_details.map((plo) => {
              let supportedCourses = new Set();
              // Collect all supported courses for this PLO
              for (let ind of plo.plo_indicators) {
                ind.weight_clos.forEach((clo) => {
                  if (clo.clo?.course?.id) {
                    supportedCourses.add(clo.clo.course.id);
                  }
                });
              }
              // Create the mapping object
              let courseMapping = {};
              for (let course of program.courses) {
                courseMapping[course.code] = supportedCourses.has(course.id);
              }
              return { PLO: plo.name, ...courseMapping };
            })
          : [],
        // the courses array should include only the course codes [1010, 1015, 1011]
        courses: program.courses?.map((course) => course.code) || [],
        // each PLO object should include the PLO's id code, name , target (expected level of attainment), the results of its evaluation
        PLOs: program.peosplos_mapping_details?.map((plo) => {
          return {
            id: plo.id,
            code: plo.code,
            name: plo.name,
            target: plo.target,
            score: plo.score,
            evaluationTable: plo.plo_indicators?.map((ind) => {
              return {
                id: ind.id,
                performanceIndicator: ind.name,
                EducationalStrategies: [
                  ...new Set(
                    ind.weight_clos?.map((clo) => clo.clo.course.code)
                  ),
                ],
                MethodsOfAssessment: [
                  ...new Set(
                    ind.weight_clos
                      ?.map((clo) =>
                        clo.clo.planned_assessment_methods?.map(
                          (am) => am.assessment_name
                        )
                      )
                      .flat()
                  ),
                ],
                WhereSummativeDataAreCollected: [
                  ...new Set(
                    ind.weight_clos
                      ?.map((clo) =>
                        clo.clo.assessment_methods?.map((am) => am.name)
                      )
                      .flat()
                  ),
                ],
                LengthOfAssessmentCycle: Math.max(
                  ...[
                    ...new Set(
                      ind.weight_clos
                        ?.map((clo) =>
                          clo.clo.planned_assessment_methods?.map(
                            (am) => am.length_assessment_cycle
                          )
                        )
                        .flat()
                    ),
                  ]
                ),
                YearSemesterOfDataCollection: "",
                TargetForPerformance: ind.target,
              };
            }),
            barChart: [
              {
                name: "Problem statement shows understanding of the problem",
                target: 90,
                2015: 80,
                2018: 85,
                2021: 90,
              },
              {
                name: "Problem solution is appropriate and within reasonable constraints",
                target: 90,
                2015: 80,
                2018: 85,
                2021: 90,
              },
            ],
            evaluationResults: `For summative assessment (end of program), the decision was made to
              focus on the direct assessment for all indicators. Summative data
              for Indicators #1 were collected in the Engineering Design I course
              (EGR 4090) where students are asked to develop their statement of
              the problem and project planning documentation. For Indicator #2 the
              assessment was completed in the second semester design course (EGR
              4092) as a part of the final assessment of the course. For Indicator
              #3 faculty created an examination administered in EGR 3030 which
              required students to apply mathematical principles to model
              equations to achieve solutions. For Indicator #4 the assessment was
              completed in the second semester design course (EGR 4092) as a part
              of the final assessment of student performance on the senior project
              report using a rubric for the indicator.`,
          };
        }),
        description: ``,
      },
      ContinuousImprovement: {
        description: ``,
      },
      AdditionalInformation: {
        description: ``,
      },
      Analysis: {
        feedback: Analysis,
        description: ``,
      },
    };
  };

  const deriveCriterion5 = (program) => {
    return {
      ProgramCurriculum: {
        //If there is more than one curricular path or option for a program, a separate programCurriculumTable should be provided for each path or option.
        programCurriculumTable: program.courses?.map((course) => {
          let courseTitle = `${course.department_details?.[0]?.name} ${course.code} - ${course.title} `;
          let topicsCredits = course?.course_content_details?.reduce(
            (acc, topic) => {
              acc[topic.name] = topic.topic_hours;
              return acc;
            },
            {}
          );
          return {
            id: course.id,
            title: courseTitle,
            prerequisites: course.prerequisites_ids,
            corequisites: course.co_requisites_ids,
            // mathBasicSciencesCredits: "10",
            // engineeringTopicsCredits: "3",
            // otherCredits: "5",
            ...topicsCredits,
            credits: "14",
            type:
              course.category === "Required"
                ? "R"
                : course.category === "Elective"
                ? "E"
                : "SE",
            courseLastTwoTerms: [],
            maximumSectionEnrollment: "...",
            yearTerm: "",
          };
        }),
        subjects: [
          ...new Set(
            program.courses
              ?.map((course) =>
                course.course_content_details?.map((topic) => topic.name)
              )
              .flat()
              .filter(Boolean)
          ),
        ],
        description: ``,
      },
      CourseSyllabi: {},
      Analysis: {
        feedback: Analysis,
        description: ``,
      },
    };
  };

  const deriveCriterion6 = (program) => {
    return {
      FacultyQualifications: {
        description: ``,
        FacultyQualificationsTable: program.courses
          ?.map((course) => {
            return course?.instructors?.map((instructor) => {
              let registrations = instructor.registrations?.map(
                (registration) =>
                  `${registration.code} (${registration.organization})`
              );
              let certificationtions = instructor.registrations?.map(
                (registration) =>
                  `${registration.code} ${registration.issued_by}`
              );
              let registrationsAndCertifications =
                registrations?.concat(certificationtions)?.join(", ") || "None";
              let academiaExperienceYears =
                instructor.teaching_and_academic_experience
                  ?.map((exp) => {
                    return calculateYearsBetweenExperienceDates(
                      exp.start_date,
                      exp.end_date,
                      exp.until_now
                    );
                  })
                  .reduce((sum, years) => sum + years, 0) || 0;
              let nonAcademiaExperienceYears =
                instructor.professionalExperience
                  ?.map((exp) => {
                    return calculateYearsBetweenExperienceDates(
                      exp.start_date,
                      exp.end_date,
                      exp.until_now
                    );
                  })
                  .reduce((sum, years) => sum + years, 0) || 0;
              let insideInstitutionExperience =
                instructor.teaching_and_academic_experience.find(
                  (exp) =>
                    exp.until_now &&
                    exp.institution === program.university_detail?.[0]?.name
                );
              let instructorObj = {
                id: instructor.id,
                name: `${instructor.first_name} ${instructor.last_name}`,
                degree: `${instructor.diploma} ${instructor.diploma_date}`,
                rank: instructor.rank,
                AcademicAppointmentType:
                  instructor.type_of_academic_appointment,
                employmentStatus: instructor.full_time ? "FT" : "PT",
                nonAcademiaExperienceYears: nonAcademiaExperienceYears,
                academiaExperienceYears: academiaExperienceYears,
                insideInstitutionExperienceYears:
                  calculateYearsBetweenExperienceDates(
                    insideInstitutionExperience?.start_date,
                    insideInstitutionExperience?.end_date,
                    true
                  ),
                ProfessionalRegistrationCertification:
                  registrationsAndCertifications,
                ProfessionalOrganizationsActivityLevel: "...",
                ProfessionalDevelopmentActivityLevel: "...",
                industryConsultingORSummerWorkActivityLevel: "...",
              };
              return instructorObj;
            });
          })
          .flat(),
      },
      FacultyWorkload: {
        FacultyWorkloadTable: program.courses
          ?.map((course) => {
            return course?.instructors?.map((instructor) => {
              return {
                id: instructor.id,
                name: `${instructor.first_name} ${instructor.last_name}`,
                employmentStatus: instructor.full_time ? "FT" : "PT",
                // classes For the academic year for which the self-study is being prepared.
                classes: instructor.courses_taught?.map(
                  (course) =>
                    `${course.course__code} (${course.course__credit_hours}) ${course.course__semester__name}/ ${course.course__semester__academicYear__name}`
                ),
                teachingActivitiesPercentage: "...%",
                researchOrScholarshipActivitiesPercentage: "...%",
                otherActivitiesPercentage: "...%",
                timePercentageDevotedToTheProgram: "...%",
              };
            });
          })
          .flat(),
        description: ``,
      },
      FacultySize: {
        description: ``,
      },
      ProfessionalDevelopment: {
        description: ``,
      },
      AuthorityAndResponsibilityOfFaculty: {
        description: ``,
      },
      Analysis: {
        feedback: Analysis,
        description: ``,
      },
    };
  };

  const deriveCriterion7 = (program) => {
    return {
      OfficesClassroomsLaboratories: {
        officesClassroomsLaboratoriesFacilities: program.courses
          ?.map((course) =>
            course.facilities_required?.filter(
              (facility) =>
                facility.item__name &&
                (facility.item__name.toLowerCase().includes("classrooms") ||
                  facility.item__name.toLowerCase().includes("laboratories") ||
                  facility.item__name.toLowerCase().includes("offices"))
            )
          )
          ?.flat()
          .filter(Boolean),
        description: ``,
      },
      ComputingResources: {
        computingResources: program.courses
          ?.map((course) =>
            course.facilities_required?.filter(
              (facility) => facility.item__computing_resource
            )
          )
          ?.flat(),
        description: ``,
      },
      Guidance: {
        description: ``,
      },
      MaintenanceAndUpgradingOfFacilities: {
        description: ``,
      },
      LibraryServices: {
        libraryServices: program.courses
          ?.map((course) =>
            course.learning_resources?.filter((resource) =>
              resource.resource_type__name?.toLowerCase()?.includes("library")
            )
          )
          ?.flat()
          .filter(Boolean),
        description: ``,
      },
      OverallCommentsOnFacilities: {
        description: ``,
      },
      Analysis: {
        feedback: Analysis,
        description: ``,
      },
    };
  };

  const deriveCriterion8 = (program) => {
    return {
      Leadership: {
        description: ``,
      },
      ProgramBudgetAndFinancialSupport: {
        description: ``,
      },
      Staffing: {
        description: ``,
      },
      FacultyHiringAndRetention: {
        description: ``,
      },
      SupportOfFacultyProfessionalDevelopment: {
        description: ``,
      },
      Analysis: {
        feedback: Analysis,
        description: ``,
      },
    };
  };

  const generateABETReport = (program) => {
    return {
      programId: program.id,
      programName: program.program_degree_name,
      institutionName: program.university_detail[0]?.name,
      location: program.accredited_campus_location?.location,
      BACKGROUNDINFORMATION: deriveBackgroundInformation(program),
      CRITERION1_STUDENTS: deriveCriterion1(program),
      CRITERION2_PROGRAMEDUCATIONALOBJECTIVES: deriveCriterion2(program),
      CRITERION3_STUDENTOUTCOMES: deriveCriterion3(program),
      CRITERION4_CONTINUOUSIMPROVEMENT: deriveCriterion4(program),
      CRITERION5_CURRICULUM: deriveCriterion5(program),
      CRITERION6_FACULTY: deriveCriterion6(program),
      CRITERION7_FACILITIES: deriveCriterion7(program),
      CRITERION8_INSTITUTIONALSUPPORT: deriveCriterion8(program),
    };
  };

  //////////////////////////////////////////////////////////////////////
  const { ProgramID } = props;

  const getReportPath = (type) => {
    switch (type) {
      case "Annual Program Report":
        return "annualProgram";
      case "Self Study Report":
        return "SSR";
      default:
        return "";
    }
  };

  const handleSend = async () => {
    if (accreditationBody === "ABET") {
      if (ProgramID) {
        // Call Program API
        await fetchProgram(ProgramID);
        // Generate ABET Report
        if (program) {
          const report = generateABETReport(program);
          if (version == null || version === "") {
            setnumberState("error");
          } else {
            // Create ABET Report
            try {
              const originalPromiseResult = await dispatch(
                addReport({
                  report_body: JSON.stringify(report),
                  body: "ABET",
                  type,
                  resource: "program",
                  resource_id: ProgramID,
                  version,
                  assign_to: program?.coordinators_detail?.[0]?.id,
                })
              ).unwrap();
              // handle result here
              handleClose();
              openNotificationWithIcon(
                "success",
                "ABET Report successfully added"
              );
              history.push(`/citrine/reports/ABET/${getReportPath(type)}`);
            } catch (rejectedValueOrSerializedError) {
              // handle error here
              console.log(
                "-----------rejectedValueOrSerializedError--------------",
                rejectedValueOrSerializedError
              );
              handleClose();
              setHandledError(rejectedValueOrSerializedError);
              handleOpenErrorModal();
            }
          }
        }
      }
    } else {
      const resultAction = await dispatch(
        addNewAnnualProgramReport({ program_id: ProgramID })
      );
      if (addNewAnnualProgramReport.fulfilled.match(resultAction)) {
        openNotificationWithIcon("success", ` successfully Added`);
        history.push(`/citrine/reports/${type}/annualProgram`);
        dispatch(fetchAnnualProgramReports());
        handleClose();
      } else {
        if (resultAction.payload) {
          setHandledError(resultAction.payload);
          handleOpenErrorModal();
          handleClose();
        } else {
          setHandledError(resultAction.error);
          handleOpenErrorModal();
          handleClose();
        }
      }
    }
  };

  return (
    <div>
      <MenuItem
        onClick={(event) => {
          handleClickOpen(event);
        }}
      >
        <h6>{t("Create Program Report")}</h6>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          {t("Create Program Report")}
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <FormLabel
                className={
                  classes.labelHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox
                }
              >
                Type
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <RadioGroup
                aria-label="Type"
                name="Type"
                value={type}
                onChange={handleChangeType}
              >
                {reportTypes.map((reportType) => (
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value={reportType}
                          name={reportType}
                          aria-label={reportType}
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={reportType}
                    />
                  </div>
                ))}
              </RadioGroup>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={4}>
              <FormLabel
                className={
                  classes.labelHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox
                }
              >
                Accreditation body
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <RadioGroup
                aria-label="Accreditation Body"
                name="Accreditation Body"
                value={accreditationBody}
                onChange={handleChangeAccreditationBody}
              >
                {reportAccreditationBodies.map((accreditationBody) => (
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value={accreditationBody}
                          name={accreditationBody}
                          aria-label={accreditationBody}
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={accreditationBody}
                    />
                  </div>
                ))}
              </RadioGroup>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={4}>
              <FormLabel className={validationClasses.labelHorizontal}>
                Version
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                success={numberState === "success"}
                error={numberState === "error"}
                id="version"
                // formControlProps={{
                //   fullWidth: true
                // }}
                inputProps={{
                  onChange: (event) => {
                    if (verifyNumber(event.target.value)) {
                      setnumberState("success");
                    } else {
                      setnumberState("error");
                    }
                    setVersion(event.target.value);
                  },
                  type: "number",
                  endAdornment:
                    numberState === "error" ? (
                      <InputAdornment position="end">
                        <Close className={validationClasses.danger} />
                      </InputAdornment>
                    ) : undefined,
                }}
              />
            </GridItem>
          </GridContainer>

          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
              style={{ paddingRight: "30px" }}
              color="danger"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                handleSend();
              }}
              color="info"
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
